import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import axios from 'axios';
import AdminNavbar from '../components/AdminNavbar';
import '../styles/AdminDashboard.css';
import swal from 'sweetalert'
import {useAuth} from "../security/Components/AuthProvider";
import { useTranslation } from 'react-i18next';

function AdminDashboard() {
  const [showAddAdminForm, setShowAddAdminForm] = useState(false);
  const [showAddEmployeeForm, setShowAddEmployeeForm] = useState(false);
  const [numberOfAdmins, setNumberOfAdmins] = useState(false)
  const [numberOfEmployees, setNumberOfEmployees] = useState(false)
  const [numberOfClients, setNumberOfClients] = useState(false)
  const [adminEmail, setAdminEmail] = useState(false)

  const auth = useAuth();
  const { t } = useTranslation();

  useEffect(() => {
    // @ts-ignore
    if (!auth.isAuthenticated) {
      window.location.href = 'http://www.coupetonarbre.live/';
      
    }
    getNumberOfAdmins()
    getNumberOfEmployees()
    getNumberOfClients()
    getInfo()

  }, []);

  const getInfo = async () => {
    axios.get("https://api.coupetonarbre.live/api/v1/cta/security/user-info", {
        headers: {
          // @ts-ignore
          'X-XSRF-TOKEN': auth.getXsrfToken(),
        },
      })
        .then(r => {
            if (r.status === 200) {
                setAdminEmail(r.data.email)

            }
        })
        .catch(e => {
        })
    }

  const addAdmin = async (event) => {
    event.preventDefault();
    // @ts-ignore
    await axios.post("https://api.coupetonarbre.live/api/v1/cta/auth0/manage/add-admin", {
        email: event.target[0].value,
        password: event.target[1].value
    }, {
        headers: {
            // @ts-ignore
            'X-XSRF-TOKEN': auth.getXsrfToken()                                                                                                                                 
        }
    })
        .then(r => {
            if (r.status === 201) {

                setShowAddAdminForm(false);
                swal(t('adminDashboard.successMessageAdmin.title'), t('adminDashboard.successMessageAdmin.body'), "success")
                    .then(() => {
                        window.location.href = 'http://www.coupetonarbre.live/Dashboard';
                    })
            }
        })
        .catch(e => {
            swal("Error!", e.response.data.message, "error")
                .then(() => {
                    // @ts-ignore
                    auth.authError(e.response.status)
                })
        })
    }


    const getNumberOfAdmins =  () => {
     axios.get('https://api.coupetonarbre.live/api/v1/cta/auth0/manage/get-total-admins', {
        headers: {
          // @ts-ignore
          'X-XSRF-TOKEN': auth.getXsrfToken(),
        },
      })
      .then((r) => {
        if (r.status === 200) {

                setNumberOfAdmins(r.data)
              }
            })
      .catch((e) => {
        console.log(e);
        
      });
  };

  const getNumberOfEmployees =  () => {
     axios.get('https://api.coupetonarbre.live/api/v1/cta/auth0/manage/get-total-employees', {
        headers: {
          // @ts-ignore
          'X-XSRF-TOKEN': auth.getXsrfToken(),
        },
      })
      .then((r) => {
        if (r.status === 200) {

                setNumberOfEmployees(r.data)
              }
            })
      .catch((e) => {
        console.log(e);
        
      });
  };

  const getNumberOfClients =  () => {
     axios.get('https://api.coupetonarbre.live/api/v1/cta/auth0/manage/get-total-clients', {
        headers: {
          // @ts-ignore
          'X-XSRF-TOKEN': auth.getXsrfToken(),
        },
      })
      .then((r) => {
        if (r.status === 200) {
                setNumberOfClients(r.data)
              }
            })
      .catch((e) => {
        console.log(e);
        
      });
  };


const addEmployee = async (event) => {
    event.preventDefault();
    // @ts-ignore
    await axios.post("https://api.coupetonarbre.live/api/v1/cta/auth0/manage/add-employee", {
        email: event.target[0].value,
        password: event.target[1].value
    }, {
        headers: {
            // @ts-ignore
            'X-XSRF-TOKEN': auth.getXsrfToken()                                                                                                                                 
        }
    })
        .then(r => {
            if (r.status === 201) {

                setShowAddEmployeeForm(false);
                swal(t('adminDashboard.successMessageEmployee.title'), t('adminDashboard.successMessageEmployee.title'), "success")
                    .then(() => {
                        window.location.href = 'http://www.coupetonarbre.live/Dashboard';

                    })
            }
        })
        .catch(e => {
            swal("Error!", e.response.data.message, "error")
                .then(() => {
                    // @ts-ignore
                    auth.authError(e.response.status)
                })
        })
}
 

  return (
    <div className='content'>
    <Helmet>
      <title>{t('adminDashboard.pageTitle')}</title>
    </Helmet>

    <div id='nav-container'>
      <AdminNavbar />
    </div>

    <h1> {t('adminDashboard.welcome')} {adminEmail}</h1>
        <div className='container'>

        <div className='section'>

        <div className='center'>                           
            <h2>{t('adminDashboard.createAdmin')}</h2>
        </div>    
        
        <div className="add-user-button">
          <button onClick={() => setShowAddAdminForm(true)}>{t('adminDashboard.addAdmin')}</button>
        </div>   

        </div>  

        <div className='section'>

         <div className='center'>                           
            <h2>{t('adminDashboard.createEmployee')}</h2>
        </div>     

        <div className="add-user-button">
          <button onClick={() => setShowAddEmployeeForm(true)}>{t('adminDashboard.addEmployee')}</button>
        </div>   

        </div>

        </div>        

        <div className='container'>
         <div className='section'>
         <div className='center'>                           
            <h2>{t('adminDashboard.noAdmin')}</h2>
        </div>     
        <div>
          <h1>{numberOfAdmins}</h1>
        </div> 

        </div>
        <div className='section'>
         <div className='center'>                           
            <h2>{t('adminDashboard.noEmployee')}</h2>
        </div>     
        <div>
          <h1>{numberOfEmployees}</h1>
        </div> 

        </div>

        <div className='section'>
         <div className='center'>                           
            <h2>{t('adminDashboard.noClient')}</h2>
        </div>     
        <div>
          <h1>{numberOfClients}</h1>
        </div> 
        </div>
        </div>

                  

        {showAddAdminForm && (
        <div className="popup-background">
          <div className="popup-container">
            <h1>{t('adminDashboard.addAdminForm.header')}</h1>

            <form id={"add-user-form"} onSubmit={addAdmin} style={{

            }}>

        <div>

        <div >
            <label htmlFor="email" className="form-label">{t('adminDashboard.addAdminForm.email')}</label>
                <input className="form-control mb-2" type="text" placeholder={t('adminDashboard.addAdminForm.emailPlace')}/>
        </div>
        <div >
            <label htmlFor="password" className="form-label">{t('adminDashboard.addAdminForm.pass')}</label>
                <input className="form-control mb-2" type="password"
                placeholder={t('adminDashboard.addAdminForm.passPlace')}/>
        </div>
        <div className="add-user-button">
            <button>{t('adminDashboard.addAdminForm.addButton')}</button>
            <button onClick={() => setShowAddAdminForm(false)}>{t('adminDashboard.addAdminForm.cancelButton')}</button>

        </div>
        </div>
        </form>
          </div>
        </div>
      )}


        {showAddEmployeeForm && (
        <div className="popup-background">
          <div className="popup-container">
            <h1>{t('adminDashboard.addEmployeeForm.header')}</h1>

            <form id={"add-user-form"} onSubmit={addEmployee} style={{

            }}>
        <div >

        <div>
            <label htmlFor="email" className="form-label">{t('adminDashboard.addEmployeeForm.email')}</label>
                <input className="form-control mb-2" type="text" placeholder={t('adminDashboard.addEmployeeForm.emailPlace')}/>
        </div>
        <div>
            <label htmlFor="password" className="form-label">{t('adminDashboard.addEmployeeForm.pass')}</label>
                <input className="form-control mb-2" type="password"
                placeholder={t('adminDashboard.addEmployeeForm.passPlace')}/>
        </div>
        <div className="add-user-button">
            <button>{t('adminDashboard.addEmployeeForm.addButton')}</button>
            <button onClick={() => setShowAddEmployeeForm(false)}>{t('adminDashboard.addEmployeeForm.cancelButton')}</button>

        </div>
        </div>
        </form>
          </div>
        </div>
      )}    

<br></br><br></br>

    </div>
  );
}

export default AdminDashboard;
