import React, { useState, useEffect } from 'react';
import Helmet from 'react-helmet';
import axios from 'axios';
import DatePicker from 'react-datepicker';
import { useNavigate } from 'react-router-dom';

import 'react-datepicker/dist/react-datepicker.css';
import TimePicker from 'rc-time-picker';
import 'rc-time-picker/assets/index.css';
import moment from 'moment';
import EmployeeNavbar from '../components/EmployeeNavbar';
import { useParams } from 'react-router-dom';
import '../styles/CreateQuoteRequest.css';
import { useAuth } from '../security/Components/AuthProvider';
import { useTranslation } from 'react-i18next';

const UpdateAppointmentEmployee = () => {
  const { t, i18n } = useTranslation();
    const format = 'h:mm A';
    const [existingAppointmentData, setExistingAppointmentData] = useState(null);
    const { appointmentId } = useParams();

    const auth = useAuth();
    const [formData, setFormData] = useState({
        description: '',
        service: '',
        status: '',
        price: '',
        date: new Date(),
        time: new Date(),
    });

    const statusOptionMappingEN = {
      'Completed' : 'COMPLETED',
      'Postponed' : 'POSTPONED',
      'Cancelled' : 'CANCELLED',
      'Upcoming' : 'UPCOMING',
      'Within 24 Hours' : 'WITHIN_24_HOURS',
      'Pending' : 'PENDING',
      'Within One Week' : 'WITHIN_ONE_WEEK'
    };

    const statusOptionMappingFR = {
      'Complété' : 'COMPLETED',
      'Reporté' : 'POSTPONED',
      'Annulé' : 'CANCELLED',
      'À venir' : 'UPCOMING',
      'Dans les prochaines 24 heures' : 'WITHIN_24_HOURS',
      'En attente' : 'PENDING',
      'En moins d\'une semaine' : 'WITHIN_ONE_WEEK'
    };

    useEffect(() => {
        // @ts-ignore
        if (!auth.isAuthenticated) {
          window.location.href = 'http://www.coupetonarbre.live/';
        }
    }, []);

    useEffect(() => {
        axios.get(`https://api.coupetonarbre.live/appointments/${appointmentId}`, {
          headers: {
            'X-XSRF-TOKEN': auth.getXsrfToken(),
          },
        })
          .then(response => {
            const appointmentData = response.data;
            setExistingAppointmentData(appointmentData)
    
            setFormData({
              price : appointmentData.price,
              description: appointmentData.description,
              service: appointmentData.service,
              status: appointmentData.status,
              date: new Date(appointmentData.date),
              time: moment(appointmentData.time, format).format(format), 
            });
          })
          .catch(error => {
            console.error('Error fetching existing appointment:', error);
          });
    }, [appointmentId, auth]);

    const handleInputChange = (name, value) => {
        setFormData({
          ...formData,
          [name]: value,
        });
    };

    const handleSubmit = (event) => {
        event.preventDefault();
    
        const requestBody = {
          'clientId' : existingAppointmentData.clientId,
          'clientFirstName' : existingAppointmentData.clientFirstName,
          'clientLastName' : existingAppointmentData.clientLastName,
          'time' : existingAppointmentData.time,
          'date' : existingAppointmentData.date,
          'description' : existingAppointmentData.description,
          'address' : existingAppointmentData.address,
          'service' : existingAppointmentData.service,
          'employeeId' :existingAppointmentData.employeeId,
          'employeeFirstName' : existingAppointmentData.employeeFirstName,
          'employeeLastName' : existingAppointmentData.employeeLastName,
          ...formData,
        };
    
        axios.put(`https://api.coupetonarbre.live/appointments/employee/${appointmentId}`, requestBody, {
          headers: {
            'X-XSRF-TOKEN': auth.getXsrfToken()
          }
        })
        .then(response => {
          console.log(response.data);
          window.location.href = 'http://www.coupetonarbre.live/employee/appointments';
        })
        .catch(error => {
          console.error('Error during request:', error);
        });
    };

    const renderStatusOptions = () => {
      const currentLanguage = i18n.language;
      let statusOptions;
      let statusMapping;
      if (currentLanguage === 'fr') {
        statusOptions = Object.keys(statusOptionMappingFR);
        statusMapping = statusOptionMappingFR;
      } else {
        statusOptions = Object.keys(statusOptionMappingEN);
        statusMapping = statusOptionMappingEN;
      }

        return statusOptions.map(option => (
        <option key={option} value={statusMapping[option]}>
          {option}
        </option>
        ));
    };

    return (
        <div className='content'>
          <Helmet>
            <title>{t('updateAppointmentEmployee.pageTitle')}</title>
          </Helmet>
          <div id='nav-container'>
            <EmployeeNavbar />
    
            <div className='text-align center'>
              <h1 className='heading'>{t('updateAppointmentEmployee.header')}</h1>
            </div>
    
            <div>
              <form onSubmit={handleSubmit} className='form-styles'>
    
               
    
                <label className='form-label'>{t('updateAppointmentEmployee.priceLabel')}</label>
                <input type="text" name="price" className='form-input' value={formData.price} onChange={(e) => handleInputChange('price', e.target.value)} required />
    
                
            <label className='form-label'>{t('updateAppointmentEmployee.statusLabel')}</label>
                <select name="status" className='form-select' value={formData.status} onChange={(e) => handleInputChange('status', e.target.value)} required>
                  <option value="" disabled>{t('updateAppointmentEmployee.selectStatus')}</option>
                  {renderStatusOptions()}
                </select>
                <button className='form-button' type="submit">{t('updateAppointmentEmployee.updateButton')}</button>
              </form>
            </div>
          </div>
        </div>
    );
}

export default UpdateAppointmentEmployee