import { BrowserRouter, Route, Routes } from 'react-router-dom'

import HomePage from './pages/HomePage'
import AccountsAdmin from './pages/AccountsAdmin'
import AppointmentsAdmin from './pages/AppointmentsAdmin'
import AppointmentsClient from './pages/AppointmentsClient'
import QuotesAdmin from './pages/QuotesAdmin'
import FeedbackAdmin from './pages/FeedbackAdmin'
import FeedbackClient from './pages/FeedbackClient'
import Profile from './pages/Profile'
import UpdateClientAdmin from './pages/UpdateClientAdmin'
import UpdateProfile from './pages/UpdateProfile'
import CreateQuoteRequestAdmin from './pages/CreateQuoteRequestAdmin'
import CreateAppointmentAdmin from './pages/CreateAppointmentAdmin'
import CreateAppointmentClient from './pages/CreateAppointmentClient'
import CreateQuoteRequestClient from './pages/CreateQuoteRequestClient'
import AdminDashboard from './pages/AdminDashboard'
import QuotesClient from './pages/QuotesClient'
import UpdateQuoteRequestClient from './pages/UpdateQuoteRequestClient'
import UpdateQuoteRequestAdmin from './pages/UpdateQuoteRequestAdmin'
import { AuthProvider } from './security/Components/AuthProvider'
import VerifyRedirect from './security/verifyRedirect'
import CreateFeedbackClient from './pages/CreateFeedbackClient'

import QuotesEmployee from './pages/QuotesEmployee'
import AppointmentsEmployee from './pages/AppointmentsEmployee'
import EmployeeProfile from './pages/EmployeeProfile'
import UpdateQuoteRequestEmployee from './pages/UpdateQuoteRequestEmployee'
import UpdateAppointmentAdmin from './pages/UpdateAppointmentAdmin'
import UpdateAppointmentClient from './pages/UpdateAppointmentClient'
import UpdateAppointmentEmployee from './pages/UpdateAppointmentEmployee'

import InvoicesAdmin from './pages/InvoicesAdmin'
import Footer from './components/Footer'

function App() {
  return (
    <div className='App'>
      <AuthProvider>
        <BrowserRouter>
          <div className='main-container'>
            <div className='container-for-footer'>
              <Routes>
                <Route path='/' element={<HomePage />} />
                <Route path='/admin/appointments' element={<AppointmentsAdmin />} />
                <Route path='/admin/quotes' element={<QuotesAdmin />} />
                <Route path='/admin/accounts' element={<AccountsAdmin />} />
                <Route path='/admin/feedback' element={<FeedbackAdmin />} />
                <Route path='/profile' element={<Profile />} />
                <Route path='/employee/profile' element={<EmployeeProfile />} />

                <Route path='/profile/update' element={<UpdateProfile />} />

                <Route path='/verify' element={<VerifyRedirect />} />

                <Route path='/admin/updateClientAdmin/:clientId' element={<UpdateClientAdmin />} />

                <Route path='/admin/quoteRequests/new' element={<CreateQuoteRequestAdmin />} />
                <Route path='/client/quoteRequests/new' element={<CreateQuoteRequestClient />} />
                <Route path='/admin/quoteRequests' element={<QuotesAdmin />} />
                <Route path='/client/quoteRequests' element={<QuotesClient />} />
                <Route path='/employee/quoteRequests' element={<QuotesEmployee />} />

                <Route path='/admin/appointments' element={<AppointmentsAdmin />} />
                <Route path='/client/appointments' element={<AppointmentsClient />} />
                <Route path='/employee/appointments' element={<AppointmentsEmployee />} />

                <Route path='/admin/feedbacks' element={<FeedbackAdmin />} />
                <Route path='/client/feedbacks' element={<FeedbackClient />} />
                <Route path='/client/feedbacks/new' element={<CreateFeedbackClient />} />

                <Route path='/Dashboard' element={<AdminDashboard />} />

                <Route path='/admin/quotes/updateQuoteRequest' element={<UpdateQuoteRequestAdmin />} />
                <Route path='/client/quotes/updateQuoteRequest' element={<UpdateQuoteRequestClient />} />
                <Route path='/admin/quotes/updateQuoteRequest/:quoteRequestId' element={<UpdateQuoteRequestAdmin />} />
                <Route path='/client/quotes/updateQuoteRequest/:quoteRequestId' element={<UpdateQuoteRequestClient />} />
                <Route path='/employee/quotes/updateQuoteRequest/:quoteRequestId' element={<UpdateQuoteRequestEmployee />} />

                <Route path='/admin/appointments/new' element={<CreateAppointmentAdmin />} />
                <Route path='/client/appointments/new' element={<CreateAppointmentClient />} />
                <Route path='/admin/appointments/updateAppointment/:appointmentId' element={<UpdateAppointmentAdmin />} />
                <Route path='/client/appointments/updateAppointment/:appointmentId' element={<UpdateAppointmentClient />} />
                <Route path='/employee/appointments/updateAppointment/:appointmentId' element={<UpdateAppointmentEmployee />} />

                <Route path='/admin/invoices' element={<InvoicesAdmin />} />
              </Routes>
            </div>
            <Footer />
          </div>
        </BrowserRouter>
      </AuthProvider>
    </div>
  )
}

export default App
