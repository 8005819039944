import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import axios from 'axios';
import AdminNavbar from '../components/AdminNavbar';
import '../styles/AccountsAdmin.css';

import {useAuth} from "../security/Components/AuthProvider";
import { useTranslation } from 'react-i18next';

function AccountsAdmin() {
  const [clients, setClients] = useState([]);
  const [selectedClientId, setSelectedClientId] = useState(null);
  const [selectedClientDetails, setSelectedClientDetails] = useState(null);
  const [sortOrder, setSortOrder] = useState('asc');
  const [sortedField, setSortedField] = useState('firstName');
  const [showAddClientForm, setShowAddClientForm] = useState(false);
  const [currentPage, setCurrentPage] = useState(1); 
  const [clientPerPage] = useState(5); 
  const [newClient, setNewClient] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    address: '',
  });

  const auth = useAuth();
  const { t } = useTranslation();

  useEffect(() => {
    // @ts-ignore
    if (!auth.isAuthenticated) {
      window.location.href = 'http://www.coupetonarbre.live/';
    }
  }, []);


  const getAllAccounts = async () => {
    axios.get("https://api.coupetonarbre.live/users/clients", {
        headers: {
            // @ts-ignore
            'X-XSRF-TOKEN': auth.getXsrfToken()
        }
    })
        .then(r => {
            // if (r.status === 200) {
                console.log("hello")
                setClients(r.data)

            // }
        })
        .catch(e => {
            // @ts-ignore
            // auth.authError(e.response.status)
        })
}

  useEffect(() => {
    getAllAccounts().then(r => {
      console.log("Hellop")
  })
  }, []);

  const handleAddClient = () => {
    axios.post('https://api.coupetonarbre.live/users/clients', newClient)
      .then(response => {
        setClients([...clients, response.data]);
        setNewClient({ firstName: '', lastName: '', email: '', phoneNumber: '', address: '' });
        setShowAddClientForm(false);
      })
      .catch(error => {
        console.error('Error adding client:', error);
      });
  };

  const handleFormChange = (event) => {
    let value = event.target.value;
  
    if (event.target.name === 'phoneNumber') {
      value = value.replace(/\D/g, '');
  
      const match = value.match(/^(\d{3})(\d{3})(\d{4})$/);
      if (match) {
        value = `(${match[1]}) ${match[2]}-${match[3]}`;
      }
    }
  
    setNewClient({ ...newClient, [event.target.name]: value });
  };
  

  const handleDelete = (clientId) => {
    if (typeof clientId === 'undefined') {
      console.error('Client ID is undefined. Cannot delete.');
      return;
    }

    const isConfirmed = window.confirm('Are you sure you want to delete this client?');

    if (!isConfirmed) {
      return;
    }

    console.log('Deleting client with id:', clientId);

    axios.delete(`https://api.coupetonarbre.live/users/clients/${clientId}`)
      .then(response => {
        const updatedClientsList = clients.filter(client => client.clientId !== clientId);

        setClients(updatedClientsList);
          const totalPages = Math.ceil(updatedClientsList.length / clientPerPage);
          const newCurrentPage = Math.min(currentPage, totalPages);
          setCurrentPage(newCurrentPage);
      })
      .catch(error => {
        console.error('Error:', error);
      });
  };

  useEffect(() => {
    if (selectedClientId !== null) {
      axios.get(`https://api.coupetonarbre.live/users/clients/${selectedClientId}`)
        .then(response => {
          setSelectedClientDetails(response.data);
 
        })
        .catch(error => {
          console.error('Error:', error);
        });
    }
  }, [selectedClientId]);

  const handleClientClick = (clientId) => {
    setSelectedClientId((prevClientId) => (prevClientId === clientId ? null : clientId));
  };

  const handleFieldClick = (field) => {
    setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    setSortedField(field);
    setSelectedClientId(null);
  };

  const sortedClients = [...clients].sort((a, b) => {
    const fieldValueA = a[sortedField];
    const fieldValueB = b[sortedField];

    if (fieldValueA < fieldValueB) {
      return sortOrder === 'asc' ? -1 : 1;
    }
    if (fieldValueA > fieldValueB) {
      return sortOrder === 'asc' ? 1 : -1;
    }
    return 0;
  });

  const handleUpdate = (clientId) => {

    window.location.href = `/admin/updateClientAdmin/${clientId}`;

  };

  const closeDetails = () => {
    setSelectedClientId(null);
  };
  const handlePaginationClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const renderPaginationButtons = () => {
    const totalPages = Math.ceil(clients.length / clientPerPage);

    const paginationButtons = [];
    for (let i = 1; i <= totalPages; i++) {
      paginationButtons.push(
        <button
          key={i}
          className={`page-link ${currentPage === i ? 'active' : ''}`}
          onClick={() => handlePaginationClick(i)}
        >
          {i}
        </button>
      );
    }
    return paginationButtons;
  };

  // Logic for pagination
  const indexOfLastclient = currentPage * clientPerPage;
  const indexOfFirstclient = indexOfLastclient - clientPerPage;
  const currentclients = clients.slice(indexOfFirstclient, indexOfLastclient);

  return (
    <div className='content'>
      <Helmet>
        <title>{t('accountsAdmin.pageTitle')}</title>
      </Helmet>
      <div id='nav-container'>
        <AdminNavbar />
      </div>

      <div className="header-container">
        <h1>{t('accountsAdmin.header')}</h1>
        <div className="add-client-button">
          <button onClick={() => setShowAddClientForm(true)}>{t('accountsAdmin.addClient')}</button>
        </div>


      {showAddClientForm && (
        <div className="popup-background">
          <div className="popup-container">
            <h1>{t('accountsAdmin.addClientPopup.header')}</h1>
            <br></br><br></br><br></br>
            <input name="firstName" value={newClient.firstName} onChange={handleFormChange} placeholder={t('accountsAdmin.addClientPopup.firstNamePlace')} />
            <input name="lastName" value={newClient.lastName} onChange={handleFormChange} placeholder={t('accountsAdmin.addClientPopup.lastNamePlace')} />
            <input name="email" value={newClient.email} onChange={handleFormChange} placeholder={t('accountsAdmin.addClientPopup.emailPlace')} />
            <input name="phoneNumber" value={newClient.phoneNumber} onChange={handleFormChange} placeholder={t('accountsAdmin.addClientPopup.phoneNumberPlace')} />
            <input name="address" value={newClient.address} onChange={handleFormChange} placeholder={t('accountsAdmin.addClientPopup.addressPlace')} />
            <button onClick={handleAddClient}>{t('accountsAdmin.addClientPopup.submit')}</button>
            <button onClick={() => setShowAddClientForm(false)}>{t('accountsAdmin.addClientPopup.cancel')}</button>
          </div>
        </div>
      )}

        <table>
          <thead>
            <tr>
              <th className='sortable' onClick={() => handleFieldClick('firstName')}>
              {t('accountsAdmin.clientTable.firstNameHeader')} {sortedField === 'firstName' && (sortOrder === 'asc' ? '▲' : '▼')}
              </th>
              <th className='sortable' onClick={() => handleFieldClick('lastName')}>
              {t('accountsAdmin.clientTable.lastNameHeader')} {sortedField === 'lastName' && (sortOrder === 'asc' ? '▲' : '▼')}
              </th>
              <th className='sortable' onClick={() => handleFieldClick('email')}>
              {t('accountsAdmin.clientTable.emailHeader')} {sortedField === 'email' && (sortOrder === 'asc' ? '▲' : '▼')}
              </th>
              <th>{t('accountsAdmin.clientTable.phoneHeader')}</th>
              <th>{t('accountsAdmin.clientTable.addressHeader')}</th>
              <th>{t('accountsAdmin.clientTable.actionHeader')}</th>
            </tr>
          </thead>
          <tbody>
            {currentclients.map((client) => (
              <React.Fragment key={client.clientId}>
                <tr onClick={() => handleClientClick(client.clientId)}>
                  <td>{client.firstName}</td>
                  <td>{client.lastName}</td>
                  <td>{client.email}</td>
                  <td>{client.phoneNumber}</td>
                  <td>{client.address}</td>
                  <td>
                    <button className='buttons delete-button' onClick={() => handleDelete(client.clientId)}>{t('accountsAdmin.clientTable.deleteButton')}</button>
                    <button className='buttons edit-button' onClick={() => handleUpdate(client.clientId)}>{t('accountsAdmin.clientTable.editButton')}</button>
                  </td>
                </tr>
                {selectedClientId === client.clientId && selectedClientDetails && (
                  <tr>
                    <td colSpan="6">
                      <div className="additional-details">
                        <h2>{selectedClientDetails.firstName} {selectedClientDetails.lastName}{t('accountsAdmin.clientDetails.header')}</h2>
                        <p>{t('accountsAdmin.clientDetails.id')}{selectedClientDetails.clientId}</p>
                        <p>{t('accountsAdmin.clientDetails.name')}{selectedClientDetails.firstName} {selectedClientDetails.lastName}</p>
                        <p>{t('accountsAdmin.clientDetails.email')}{selectedClientDetails.email}</p>
                        <p>{t('accountsAdmin.clientDetails.phone')}{selectedClientDetails.phoneNumber}</p>
                        <p>{t('accountsAdmin.clientDetails.address')}{selectedClientDetails.address}</p>
                        <button className='buttons close-button' onClick={closeDetails}>{t('accountsAdmin.clientDetails.close')}</button>
                      </div>
                    </td>
                  </tr>
                )}
              </React.Fragment>
            ))}
          </tbody>
        </table>
        <div>
          <div className="pagination">
            {renderPaginationButtons()}
          </div>
          </div>
      </div>
    </div>
  );
}

export default AccountsAdmin;
