import { Helmet } from 'react-helmet';
import ClientNavbar from '../components/ClientNavbar';
import axios from 'axios';
import { useAuth } from '../security/Components/AuthProvider';
import Cookies from 'js-cookie';
import React, { useEffect, useState } from 'react';
import swal from 'sweetalert';
import '../styles/Profile.css'; 
import { useTranslation } from 'react-i18next';

function Profile() {
  const auth = useAuth();
  const { t } = useTranslation();
  const [hasProfile, setHasProfile] = useState(false);

  useEffect(() => {
    // @ts-ignore
    if (!auth.isAuthenticated) {
      window.location.href = 'http://www.coupetonarbre.live/';
    }

    checkIfProfileExists().then((r) => {
      console.log(r);
    });
  }, []);

  const checkIfProfileExists = async () => {
    await axios
      .get('https://api.coupetonarbre.live/users/client?simpleCheck=true', {
        headers: {
          // @ts-ignore
          'X-XSRF-TOKEN': auth.getXsrfToken(),
        },
      })
      .then((r) => {
        console.log(r.status);
        if (r.status === 200) {
          setHasProfile(true);
          console.log('Does he have a profile? ' + hasProfile);
          axios
            .get('https://api.coupetonarbre.live/users/client', {
              headers: {
                // @ts-ignore
                'X-XSRF-TOKEN': auth.getXsrfToken(),
              },
            })
            .then((r) => {
              if (r.status === 200) {
                console.log(r.data);
                document.getElementById('firstNameText').textContent = r.data.firstName;
                document.getElementById('lastNameText').textContent = r.data.lastName;
                document.getElementById('emailText').textContent = r.data.email;
                document.getElementById('phoneNumberText').textContent = r.data.phoneNumber;
                document.getElementById('addressText').textContent = r.data.address;
              }
            })
            .catch((e) => {
              console.log(e);
            });
        } else {
          setHasProfile(false);
          
        }
      })
      .catch((e) => {
        console.log(e);
        setHasProfile(false);
        
      });
  };

  const validateEmail = (email) => {
    const pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return pattern.test(email);
  };

  const validatePhoneNumber = (phoneNumber) => {
    const pattern = /^\+?1?\s*\(?(\d{3})\)?[\s.-]?\s*(\d{3})[\s.-]?\s*(\d{4})$/;
    return pattern.test(phoneNumber);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    const userEmailFromCookie = document.cookie
      .split('; ')
      .find(row => row.startsWith('email'))
      ?.split('=')[1];

    const data = new FormData(event.currentTarget);

    const email = data.get('email');
    let phoneNumber = data.get('phoneNumber');

    phoneNumber = phoneNumber.replace(/(\d{3})\D*(\d{3})\D*(\d{4})/, '($1) $2-$3');

    if (!validateEmail(email) && !validatePhoneNumber(phoneNumber)) {
      swal(t('clientProfile.profileCreation.errorBothHeader'), t('clientProfile.profileCreation.errorBothBody'), 'error')
      return;
    } else if (!validatePhoneNumber(phoneNumber)) {
      swal(t('clientProfile.profileCreation.errorPhoneHeader'), t('clientProfile.profileCreation.errorPhoneBody'), 'error');
      return;
    } else if (!validateEmail(email)) {
      swal(t('clientProfile.profileCreation.errorEmailHeader'), t('clientProfile.profileCreation.errorEmailBody'), 'error');
      return;
    }

    const body = {
      firstName: data.get('firstName'),
      lastName: data.get('lastName'),
      email: userEmailFromCookie,
      phoneNumber: data.get('phoneNumber'),
      address: data.get('address'),
    };

    axios
      .post('https://api.coupetonarbre.live/users/client', body, {
        headers: {
          // @ts-ignore
          'X-XSRF-TOKEN': auth.getXsrfToken(),
        },
      })
      .then((r) => {
        if (r.status === 201) {
          console.log(r.data);
          Cookies.set('email', r.data.email);
          swal(t('clientProfile.profileSuccessMessage'), '', 'success');
          setHasProfile(true);
          setTimeout(() => {
            window.location.href = 'http://www.coupetonarbre.live/profile';
          }, 2000);
        }
      })
      .catch((e) => {
        let errors = '';

        if (e.response.data.errors !== undefined) {
          // @ts-ignore
          e.response.data.errors.forEach((error) => {
            errors += error.defaultMessage + '\n';
          });
        }

        swal(t('clientProfile.profileErrorMessage.title'), t('clientProfile.profileErrorMessage.body') + errors, 'error').then((r) => {
          // @ts-ignore
          auth.authError(e.response.status);
        });
      });
  };

  const handleUpdateButtonClick = () => {
    window.location.href = `/profile/update`;

  }

  const renderFormFields = () => {
    if (hasProfile) {
      return (
        <>
          <div>
            <h1> {t('clientProfile.header')} </h1>
          </div>
          <div className="profile-client-form">
            <div className="row w-75 m-auto">
              <label>
              {t('clientProfile.firstName')} <span id="firstNameText" className="info-text"></span>
              </label>
            </div>
            <div className="row w-75 m-auto">
              <label>
              {t('clientProfile.lastName')} <span id="lastNameText" className="info-text"></span>
              </label>
            </div>
            <div className="row w-75 m-auto">
              <label>
              {t('clientProfile.email')} <span id="emailText" className="info-text"></span>
              </label>
            </div>
            <div className="row w-75 m-auto">
              <label>
              {t('clientProfile.phone')} <span id="phoneNumberText" className="info-text"></span>
              </label>
            </div>
            <div className="row w-75 m-auto">
              <label>
              {t('clientProfile.address')} <span id="addressText" className="info-text"></span>
              </label>
            </div>
            <div>
            <button className="update-btn" type="button" onClick={handleUpdateButtonClick}>
            {t('clientProfile.updateButton')}
            </button>

            </div>
          </div>
        </>
      );
    } else {
      return (
        <>
          <div className='title-info'>
            <h1>{t('clientProfile.profileCreation.header')}</h1>
            <p>{t('clientProfile.profileCreation.followUp')}</p>
          </div>
          <br></br>
          <div className="profile-create-form">
            <div className="row w-75 m-auto">
              <label>
              {t('clientProfile.profileCreation.firstName')}
                <input
                  onKeyDown={(e) => {
                    e.key === 'Enter' && e.preventDefault();
                  }}
                  type="text"
                  name="firstName"
                  className="form-control mb-2 input-field"
                  placeholder={t('clientProfile.profileCreation.firstNamePlace')}
                  id="firstName"
                />
              </label>
            </div>
            <div className="row w-75 m-auto">
              <label>
              {t('clientProfile.profileCreation.lastName')}
                <input
                  onKeyDown={(e) => {
                    e.key === 'Enter' && e.preventDefault();
                  }}
                  type="text"
                  name="lastName"
                  className="form-control mb-2 input-field"
                  placeholder={t('clientProfile.profileCreation.lastNamePlace')}
                  id="lastName"
                />
              </label>
            </div>
            <div className="row w-75 m-auto">
              <label>
              {t('clientProfile.profileCreation.email')}
                <input
                  onKeyDown={(e) => {
                    e.key === 'Enter' && e.preventDefault();
                  }}
                  type="text"
                  name="email"
                  className="form-control mb-2 input-field"
                  placeholder={t('clientProfile.profileCreation.emailPlace')}
                  id="email"
                  required={true}
                />
              </label>
            </div>
            <div className="row w-75 m-auto">
              <label>
              {t('clientProfile.profileCreation.phone')}
                <input
                  onKeyDown={(e) => {
                    e.key === 'Enter' && e.preventDefault();
                  }}
                  type="text"
                  name="phoneNumber"
                  className="form-control mb-2 input-field"
                  placeholder={t('clientProfile.profileCreation.phonePlace')}
                  id="phoneNumber"
                />
              </label>
            </div>
            <div className="row w-75 m-auto">
              <label>
              {t('clientProfile.profileCreation.address')}
                <input
                  onKeyDown={(e) => {
                    e.key === 'Enter' && e.preventDefault();
                  }}
                  type="text"
                  name="address"
                  className="form-control mb-2 input-field"
                  placeholder={t('clientProfile.profileCreation.addressPlace')}
                  id="address"
                  required={true}
                />
              </label>
            </div>
            <div className="row w-25 m-auto" style={{ margin: 'auto', width: '50%', marginTop: '20px', marginBottom: '120px' , marginLeft: '190px'}}>
              <button className="save-btn" type="submit">
              {t('clientProfile.profileCreation.submit')}
              </button>
            </div>
          </div>
        </>
      );
    }
  };

  return (
    <div className='content'>
      <Helmet>
        <title>{t('clientProfile.pageTitle')}</title>
      </Helmet>
      <div id="nav-container">
        <ClientNavbar />
        <div>
          <form onSubmit={handleSubmit}>
            {renderFormFields()}
          </form>
        </div>
      </div>
    </div>
  );
}

export default Profile;
