import React, { useState, useEffect } from 'react';
import Helmet from 'react-helmet';
import axios from 'axios';
import ClientNavbar from '../components/ClientNavbar'
import '../styles/AppointmentsAdmin.css';
import moment from 'moment'; 
import { useTranslation } from 'react-i18next';

import { useAuth } from "../security/Components/AuthProvider";

function AppointmentsClient() {
  const { t, i18n } = useTranslation();
  const [appointmentList, setAppointmentList] = useState([]);
  const [appointmentId, setAppointmentId] = useState(null)
  const [appointmentDetails, setAppointmentDetails] = useState(null)
  const [profile, setProfile] = useState(null);

  const [currentPage, setCurrentPage] = useState(1);
  const [appointmentPerPage] = useState(5);
  const auth = useAuth()
  const format = 'h:mm a';

  const clarityServiceMapping = {
    'HedgeTrimming': 'Hedge Trimming',
    'TreeTrimming': 'Tree Trimming',
    'TreeBranchRemoval': 'Tree Branch Removal',
    'SmallTreeRemoval': 'Small Tree Removal',
    'HedgeTrimmingAndTreeTrimming': 'Hedge Trimming and Tree Trimming',
    'HedgeTrimmingAndTreeBranchRemoval': 'Hedge Trimming and Tree Branch Removal',
    'TreeTrimmingAndTreeBranchRemoval': 'Tree Trimming and Tree Branch Removal',
    'HedgeTrimmingAndTreeTrimmingAndTreeBranchRemoval': 'Hedge Trimming and Tree Trimming and Tree Branch Removal',
    'HedgeTrimmingAndSmallTreeRemoval': 'Hedge Trimming and Small Tree Removal',
    'TreeTrimmingAndSmallTreeRemoval': 'Tree Trimming and Small Tree Removal',
    'HedgeTrimmingAndTreeTrimmingAndSmallTreeRemoval': 'Hedge Trimming and Tree Trimming and Small Tree Removal',
    'HedgeTrimmingAndTreeBranchRemovalAndSmallTreeRemoval': 'Hedge Trimming and Tree Branch Removal and Small Tree Removal',
    'TreeTrimmingAndTreeBranchRemovalAndSmallTreeRemoval': 'Tree Trimming and Tree Branch Removal and Small Tree Removal',
    'HedgeTrimmingAndTreeTrimmingAndTreeBranchRemovalAndSmallTreeRemoval': 'Hedge Trimming and Tree Trimming and Tree Branch Removal and Small Tree Removal'
};

  useEffect(() => {
    axios.get('https://api.coupetonarbre.live/users/client', {
        headers: {
            // @ts-ignore
            'X-XSRF-TOKEN': auth.getXsrfToken(),
          },
        })
        .then((r) => {
          if (r.status === 200) {
            setProfile(r.data);
          }
        })
        .catch((e) => {
          console.log(e);
           });
  },[])


const handleUpdate = (appointmentId) => {
  window.location.href = `/client/appointments/updateAppointment/${appointmentId}`
}

const getAllAppointments = async () => {
    axios.get("https://api.coupetonarbre.live/appointments/client", {
        headers: {
          'X-XSRF-TOKEN': auth.getXsrfToken()
        }
    })
    .then((response) => {
      setAppointmentList(response.data)
    })
    .catch(error => {
      console.log('Error getting appointments', error);
    })
}

useEffect(() => {
    getAllAppointments();
}, [])

useEffect(() => {
  if (appointmentId !== null) {
    axios.get(`https://api.coupetonarbre.live/appointments/${appointmentId}/client`, {
      headers: {
        'X-XSRF-TOKEN': auth.getXsrfToken()
      }
    })
    .then(response => {
      setAppointmentDetails(response.data)
    })
    .catch(error => {
      console.error('Error fetching appointment details:', error)
    })
  }
}, [appointmentId])

  const formatDate = (dateString) => {
    const lang = i18n.language;

    const date = new Date(dateString);

    if (lang === 'fr') {
      const day = date.getDate();
      const month = date.getMonth() + 1;
      const year = date.getFullYear();

      return `${day < 10 ? '0' + day : day}/${month < 10 ? '0' + month : month}/${year}`;
    } else {
      const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
      return date.toLocaleDateString(undefined, options);
    }
  };

  const formatStatus = (status) => {
    return status
      .replace(/_/g, ' ')
      .replace(/[^a-zA-Z0-9 ]/g, '')
      .toLowerCase()
      .replace(/\b\w/g, (match) => match.toUpperCase());
  };

  const formatService = (service) => {
    return service.replace(/([a-z])([A-Z])/g, '$1 $2');
  };

  const getStatusStyle = (status) => {
    switch (status.toLowerCase()) {
      case 'completed':
        return { backgroundColor: 'green' };
      case 'postponed':
        return { backgroundColor: 'yellow' };
      case 'cancelled':
        return { backgroundColor: 'lightcoral' };
      case 'upcoming':
        return { backgroundColor: '#eef0f2' };
      case 'within_24_hours':
        return { backgroundColor: '#d2e3eb' };
      case 'within_one_week':
        return { backgroundColor: '#b1c8d2' };
      default:
        return {};
    }
  };

  const handleAddAppointmet = () =>{
    window.location.href = "http://www.coupetonarbre.live/client/appointments/new"
  }

  const handleAppointmentClick = (id) => {
    if (appointmentId === id) {
      handleCloseDetails()
    } else {
      setAppointmentId(id)
      axios.get(`https://api.coupetonarbre.live/appointments/${id}/client`, {
        headers: {
          'X-XSRF-TOKEN': auth.getXsrfToken()
        }
      })
      .then(response => {
        setAppointmentDetails(response.data)
      })
      .catch(error => {
        console.error('Error fetching appointment details:', error);
      });
    }
  }

  const handleCloseDetails= () => {
    setAppointmentId(null)
    setAppointmentDetails(null)
  }

  const getServiceTranslation = (service) => {
    return t(`appointmentsClient.table.serviceEnum.${service}`);
  };

  const getStatusTranslation = (status) => {
    return t(`appointmentsClient.table.statusEnum.${status}`);
  };

  const handleCancel = async (appointmentId) => {

      // Current day
      const currentDay = moment(new Date()).dayOfYear();
      // Current year
      const currentyear = moment(new Date()).year();
  
      // Appointment day and year
      const appointmentDay = moment(appointmentDetails.date).dayOfYear();
      const appointmentYear = moment(appointmentDetails.date).year();
  
      // Check if the appointment is on the day of or the next day
      if (currentyear === appointmentYear && (appointmentDay === currentDay + 1 || appointmentDay === currentDay)) {
        alert(t('appointmentsClient.cancelAlert'));
        return;
      }
  
      const isConfirmed = window.confirm(t('appointmentsClient.cancelConfirmation'));
  
      if (!isConfirmed) {
        return;
      }

      const email = {
        recipient: profile.email,
        subject: "Appointment Cancelled",
        message: `Hello, ${appointmentDetails.clientFirstName}\n\nYour appointment:\n\nDescription: ${appointmentDetails.description}\nService: ${clarityServiceMapping[appointmentDetails.service]}\n\nDate: ${moment(appointmentDetails.date).format('MMMM DD, YYYY')} at ${moment(appointmentDetails.time, format).format(format)}.\n\nHas been cancelled, thank you for your understanding.`,
      };
    
      axios.post('https://api.coupetonarbre.live/email', email, {
        headers: {
          'X-XSRF-TOKEN': auth.getXsrfToken(),
        },
      })
        .then(response => {
          console.log('Email notification sent:', response.data);
        })
        .catch(error => {
          console.error('Error sending email notification:', error);
        });
  
      // Make a delete request to cancel the appointment
      await axios.delete(`https://api.coupetonarbre.live/appointments/${appointmentId}`, {
        headers: {
          'X-XSRF-TOKEN': auth.getXsrfToken()
        }
      });
  
      const updatedAppointmentList = appointmentList.filter(appointment => appointment.appointmentId !== appointmentId);
      // Calculate the new current page after deletion
      setAppointmentList(updatedAppointmentList);
      const totalPages = Math.ceil(updatedAppointmentList.length / appointmentPerPage);
      const newCurrentPage = Math.min(currentPage, totalPages);
      setCurrentPage(newCurrentPage);

  };

  const formatTime = (timeString) => {
    const lang = i18n.language;

    const timeRegex = /(\d{1,2}):(\d{2})\s*(AM|PM)?/i;

    const match = timeString.match(timeRegex);
  
    if (!match) return timeString;
  
    let hour = parseInt(match[1], 10);
    const minute = match[2];
  
    if (lang === 'fr') {
      if (match[3] && match[3].toUpperCase() === 'PM') {
        hour = (hour % 12) + 12;
      } else if (match[3] && match[3].toUpperCase() === 'AM' && hour === 12) {
        hour = 0;
      }

      return `${hour < 10 ? '0' + hour : hour}:${minute}`;
    } else {
      const period = match[3] ? match[3].toUpperCase() : '';
      return `${hour % 12 || 12}:${minute} ${period}`;
    }
  };
  const handlePaginationClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const renderPaginationButtons = () => {
    const totalPages = Math.ceil(appointmentList.length / appointmentPerPage);

    const paginationButtons = [];
    for (let i = 1; i <= totalPages; i++) {
      paginationButtons.push(
        <button
          key={i}
          className={`page-link ${currentPage === i ? 'active' : ''}`}
          onClick={() => handlePaginationClick(i)}
        >
          {i}
        </button>
      );
    }
    return paginationButtons;
  };

  // Logic for pagination
  const indexOfLastappointment = currentPage * appointmentPerPage;
  const indexOfFirstappointment = indexOfLastappointment - appointmentPerPage;
  const currentAppointments = appointmentList.slice(indexOfFirstappointment, indexOfLastappointment);


  const handleViewInvoice = async (appointmentId) => {
    try {
      const response = await axios.get(`https://api.coupetonarbre.live/appointments/${appointmentId}/pdf`, {
        responseType: 'blob',
        headers: {
          'X-XSRF-TOKEN': auth.getXsrfToken(),
        },
      });

      const file = new Blob([response.data], { type: 'application/pdf' });

      const fileURL = URL.createObjectURL(file);

      window.open(fileURL);

    } catch (error) {
      console.error('Error fetching invoice PDF:', error);
      alert('Error fetching invoice PDF');
    }
  };

  return (
    <div className='content'>
        <Helmet>
            <title>{t('appointmentsClient.pageTitle')}</title>
        </Helmet>
        <div id='nav-container'>
            <ClientNavbar/>
        </div>
        <div className='header-container'>
            <h1>{t('appointmentsClient.header')}</h1>
        </div>
        <div className="add-appointment-button">
          <button className='button' type="button" onClick={handleAddAppointmet}>{t('appointmentsClient.createButton')}</button>
      </div>
        <div>
        <table>
          <thead>
              <tr>
                <th>{t('appointmentsClient.table.firstName')}</th>
                <th>{t('appointmentsClient.table.lastName')}</th>
                <th>{t('appointmentsClient.table.time')}</th>
                <th>{t('appointmentsClient.table.date')}</th>
                <th>{t('appointmentsClient.table.price')}</th>
                <th>{t('appointmentsClient.table.service')}</th>
                <th>{t('appointmentsClient.table.status')}</th>
              </tr>
            </thead>
            <tbody>
            {currentAppointments.map((appointment) => (
                <React.Fragment key={appointment.appointmentId}>
                <tr onClick={() => handleAppointmentClick(appointment.appointmentId)}>
                  <td>{appointment.clientFirstName}</td>
                  <td>{appointment.clientLastName}</td>
                  <td>{formatTime(appointment.time)}</td>
                  <td>{formatDate(appointment.date)}</td>
                  <td>{appointment.price ? `$${appointment.price}` : 'TBD'}</td>
                  <td>{getServiceTranslation(appointment.service)}</td>
                  <td style={getStatusStyle(appointment.status)}>{getStatusTranslation(appointment.status)}</td>
                  <td>
                    <button className='buttons edit-button' onClick={() => handleUpdate(appointment.appointmentId)}>{t('appointmentsClient.editButton')}</button>
                  </td>
                </tr>
                {appointmentId === appointment.appointmentId && appointmentDetails && (
                  <tr>
                    <td colSpan="7">
                      <div className='additional-details'>
                        <p><strong>{t('appointmentsClient.table.details.appointmentID')}</strong> {appointmentDetails.appointmentId}</p>
                        <p><strong>{t('appointmentsClient.table.details.quoteID')}</strong> {appointmentDetails.quoteRequestId}</p>
                        <p><strong>{t('appointmentsClient.table.details.clientID')}</strong> {appointmentDetails.clientId}</p>
                        <p><strong>{t('appointmentsClient.table.details.clientName')}</strong> {appointmentDetails.clientFirstName} {appointmentDetails.clientLastName}</p>
                        <p><strong>{t('appointmentsClient.table.details.time')}</strong> {appointmentDetails.time}</p>
                        <p><strong>{t('appointmentsClient.table.details.date')}</strong> {formatDate(appointmentDetails.date)}</p>
                        <p><strong>{t('appointmentsClient.table.details.price')}</strong> ${appointmentDetails.price}</p>
                        <p><strong>{t('appointmentsClient.table.details.description')}</strong> {appointmentDetails.description}</p>
                        <p><strong>{t('appointmentsClient.table.details.address')}</strong> {appointmentDetails.address}</p>
                        <p><strong>{t('appointmentsClient.table.details.service')}</strong> {getServiceTranslation(appointmentDetails.service)}</p>
                        <p><strong>{t('appointmentsClient.table.details.status')}</strong> {getStatusTranslation(appointmentDetails.status)}</p>
                        <button className="buttons close-button" onClick={handleCloseDetails}>{t('appointmentsClient.table.details.closeButton')}</button>
                        <button className='buttons delete-button' onClick={() => handleCancel(appointment.appointmentId)}>{t('appointmentsClient.table.details.cancelButton')}</button>
                        &nbsp;
                        <button className='buttons view-button' onClick={() => handleViewInvoice(appointment.appointmentId)}>
                            {t('appointmentsClient.viewButton')}
                        </button>
                      </div>
                    </td>
                  </tr>
                )}
                </React.Fragment>
              ))}
            </tbody>
        </table>
        <div>
          <div className="pagination">
            {renderPaginationButtons()}
          </div>
        </div>
      </div>
    </div>
  )
}
export default AppointmentsClient