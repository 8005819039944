import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: true,
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      en: {
        translation: {
          homeIntro: {
              title: 'Welcome to Coupe Ton Arbre',
              subtitle1: 'Your go-to landscaping experts and leading providers of tree care services in the Greater Montreal Area.',
              subtitle2: 'Interested in competitive pricing and excellent service?',
              signupButton: 'Sign Up Now!'
          },
          homeAbout: {
            title: 'Our Competitive Edge',
            subtitle1: 'Our Prices',
            subtitle2: 'Our Quality',
            subtitle3: 'Our Eco-Friendly Practices',
            text1: 'Choose Coupe Ton Arbre for cost-effective landscaping solutions. Our competitive rates guarantee premium hedge and tree trimming services tailored to your needs without compromising quality. Discover professional excellence at an unbeatable value.',
            text2: 'Hiring Coupe Ton Arbre ensures premium landscaping services. Our dedication to quality shines through in every hedge and tree trimming project we undertake. With meticulous attention to detail and expert craftsmanship, we transform your outdoor space into a beautifully trimmed sanctuary, setting your property apart.',
            text3: 'At Coupe Ton Arbre, we value eco-friendly landscaping. From trimming hedges to large branch removals, we ensure sustainability and eco-responsibility. By using electric tools, we create beautiful spaces while minimizing emissions and noise. Our team works carefully on your space to ensure a healthier ecosystem.'
          },
          homeServices: {
            title: 'Our Services Include',
            subtitle1: 'Hedge trimming',
            subtitle2: 'Tree pruning',
            subtitle3: 'Tree removal',
            subtitle4: 'Shrub and conifer trimming',
            note: "*All services include pickup and cleanup. Everything that is cut or trimmed is brought to an eco-center to be taken care of properly.",
          },
          footer: {
            sectionOne: {
              header: 'Website',
              home: 'Home',
              ourWork: 'Our Work',
              profile: 'My Profile'
            },
            sectionTwo: {
              header: 'Services We Offer',
              serviceOne: 'Hedge, Shrub & Cone Trimming',
              serviceTwo: 'Hedge & Tree Pruning'
            },
            sectionThree: {
              header: 'Contact',
              address: '7816 Champlain BLVD, Lasalle',
              phone: '(438) 504-6055'
            },
            sectionFour: {
              header: 'Hours',
              week: 'Week: 8 A.M. - 8 P.M.',
              saturday: 'Saturday: 9 A.M. - 6 P.M.',
              sunday: 'Sunday: 9 A.M. - 5 P.M.'
            },
            copyright: 'Coupe Ton Arbre. All rights reserved.'
          },
          adminNav: {
            home: 'Home',
            appointments: 'Appointments',
            quotes: 'Quotes',
            accounts: 'Accounts',
            feedback: 'Feedback',
            invoices: 'Invoices',
            dashboard: 'Dashboard',
            login: 'Login',
            logout: 'Logout'
          },
          clientNav: {
            home: 'Home',
            appointments: 'Appointments',
            quotes: 'Quotes',
            feedback: 'Feedback',
            profile: 'Profile',
            login: 'Login',
            logout: 'Logout'
          },
          employeeNav: {
            home: 'Home',
            appointments: 'Appointments',
            quotes: 'Quotes',
            profile: 'Profile',
            login: 'Login',
            logout: 'Logout'
          },
          mainNav: {
            home: 'Home',
            login: 'Login',
            logout: 'Logout',
            about: 'About',
            services: 'Services'
          },
          accountsAdmin: {
            pageTitle: 'Client Accounts - CTA',
            header: 'Clients',
            addClient: 'Add Client',
            addClientPopup: {
              header: 'Add a new client',
              firstNamePlace: 'First Name',
              lastNamePlace: 'Last Name',
              emailPlace: 'Email',
              phoneNumberPlace: 'Phone Number',
              addressPlace: 'Full Address',
              submit: 'Submit New Client',
              cancel: 'Cancel'
            },
            clientTable: {
              firstNameHeader: 'First Name',
              lastNameHeader: 'Last Name',
              emailHeader: 'Email',
              phoneHeader: 'Phone Number',
              addressHeader: 'Address',
              actionHeader: 'Actions',
              deleteButton: "Delete",
              editButton: 'Edit'
            },
            clientDetails: {
              header: `'s Details:`,
              id: 'Client ID: ',
              name: 'Name: ',
              email: 'Email: ',
              phone: 'Phone Number: ',
              address: 'Address: ',
              close: 'Close'
            }
          },
          adminDashboard: {
            pageTitle: 'Admin Dashboard - CTA',
            welcome: 'Welcome',
            createAdmin: 'Create Admin Account',
            addAdmin: 'Add Admin',
            createEmployee: 'Create Employee Account',
            addEmployee: 'Add Employee',
            noAdmin: 'Number of Admins',
            noEmployee: 'Number of Employees',
            noClient: 'Number of Clients',
            successMessageAdmin: {
              title: 'Admin created!',
              body: 'Admin has been created successfully!'
            },
            successMessageEmployee: {
              title: 'Employee created!',
              body: 'Employee has been created successfully!'
            },
            addAdminForm: {
              header: 'Add Admin',
              email: 'Email',
              emailPlace: 'Email',
              pass: 'Password',
              passPlace: 'Password',
              addButton: 'Add Admin',
              cancelButton: 'Cancel'
            },
            addEmployeeForm: {
              header: 'Add Employee',
              email: 'Email',
              emailPlace: 'Email',
              pass: 'Password',
              passPlace: 'Password',
              addButton: 'Add Employee',
              cancelButton: 'Cancel'
            }
          },
          clientProfile: {
            pageTitle: 'Profile - CTA',
            profileSuccessMessage: 'Profile Created Successfully',
            profileErrorMessage: {
              title: 'Error!',
              body: 'A or multiple errors present !\n'
            },
            header: 'Your Profile',
            firstName: 'First Name: ',
            lastName: 'Last Name: ',
            email: 'Email: ',
            phone: 'Phone: ',
            address: 'Address: ',
            updateButton: 'Update Your Information',
            profileCreation: {
              header: 'Hello and Welcome to CTA!',
              followUp: 'To get started, please fill out the following form with your information',
              firstName: 'First Name:',
              firstNamePlace: 'First Name',
              lastName: 'Last Name:',
              lastNamePlace: 'Last Name',
              email: 'Email:',
              emailPlace: 'Email',
              phone: 'Phone:',
              phonePlace: 'Phone',
              address: 'Address:',
              addressPlace: 'Address',
              submit: 'Submit',
              errorBothHeader: 'Invalid Email & Phone Number',
              errorBothBody: 'Please enter a valid email address and phone number',
              errorEmailHeader: 'Invalid Email',
              errorEmailBody: 'Please enter a valid email address',
              errorPhoneHeader: 'Invalid Phone Number',
              errorPhoneBody: 'Please enter a valid phone number'
            }
          },
          updateClientProfile: {
            pageTitle: 'Update Profile - CTA',
            header: 'Update Your Information',
            form: {
              firstName: 'First Name:',
              lastName: 'Last Name:',
              email: 'Email:',
              phone: 'Phone Number:',
              address: 'Address:',
              save: 'Save'
            }
          },
          employeeProfile: {
            pageTitle: 'Profile - CTA',
            header: 'Your Profile',
            firstName: 'First Name: ',
            lastName: 'Last Name: ',
            profileSuccessMessage: 'Profile Created Successfully',
            profileErrorMessage: {
              title: 'Error!',
              body: 'A or multiple errors present !\n'
            },
            profileCreation: {
              header: 'Hello and Welcome to CTA!',
              followUp: 'To get started, please fill out the following form with your information',
              firstName: 'First Name:',
              firstNamePlace: 'First Name',
              lastName: 'Last Name:',
              lastNamePlace: 'Last Name',
              submit: 'Submit'
            }
          },
          appointmentsAdmin: {
            cancelAlert: 'Cannot cancel an appointment the day before or the day of an appointment!',
            cancelConfirmation: 'Are you sure you want to cancel this appointment?',
            pageTitle: 'Appointments - CTA',
            header: 'Appointment Management',
            createButton: 'Create Appointment',
            table: {
              firstName: 'First Name',
              lastName: 'Last Name',
              time: 'Time',
              date: 'Date',
              price: 'Price',
              service: 'Service',
              status: 'Status',
              employeeAssigned: 'Employee Assigned',
              employeeAssignedBody: 'No Employee Assigned',
              editButton: 'Edit',
              details: {
                appointmentID: 'Appointment ID:',
                quoteID: 'Quote Request ID:',
                clientID: 'Client ID',
                clientName: 'Client Name',
                employeeAssigned: 'Employee Assigned:',
                time: 'Time:',
                date: 'Date:',
                price: 'Price:',
                description: 'Description:',
                address: 'Address',
                service: 'Service:',
                status: 'Status:',
                closeButton: 'Close',
                cancelButton: 'Cancel'
              },
              serviceEnum: {
                HedgeTrimming: 'Hedge Trimming',
                TreeTrimming: 'Tree Trimming',
                TreeBranchRemoval: 'Tree Branch Removal',
                SmallTreeRemoval: 'Small Tree Removal',
                HedgeTrimmingAndTreeTrimming: 'Hedge Trimming And Tree Trimming',
                HedgeTrimmingAndTreeBranchRemoval: 'Hedge Trimming And Tree Branch Removal',
                TreeTrimmingAndTreeBranchRemoval: 'Tree Trimming And Tree Branch Removal',
                HedgeTrimmingAndTreeTrimmingAndTreeBranchRemoval: 'Hedge Trimming And Tree Trimming And Tree Branch Removal',
                HedgeTrimmingAndSmallTreeRemoval: 'Hedge Trimming And Small Tree Removal',
                TreeTrimmingAndSmallTreeRemoval: 'Tree Trimming And Small Tree Removal',
                HedgeTrimmingAndTreeTrimmingAndSmallTreeRemoval: 'Hedge Trimming And Tree Trimming And Small Tree Removal',
                HedgeTrimmingAndTreeBranchRemovalAndSmallTreeRemoval: 'Hedge Trimming And Tree Branch Removal And Small Tree Removal',
                TreeTrimmingAndTreeBranchRemovalAndSmallTreeRemoval: 'Tree Trimming And Tree Branch Removal And Small Tree Removal',
                HedgeTrimmingAndTreeTrimmingAndTreeBranchRemovalAndSmallTreeRemoval: 'Hedge Trimming And Tree Trimming And Tree Branch Removal And Small Tree Removal'
              },
              statusEnum: {
                COMPLETED: 'Completed',
                POSTPONED: 'Postponed',
                CANCELLED: 'Cancelled',
                UPCOMING: 'Upcoming',
                WITHIN_24_HOURS: 'Within 24 Hours',
                PENDING: 'Pending',
                WITHIN_ONE_WEEK: 'Within One Week'
              }
            }
          },
          appointmentsClient: {
            cancelAlert: 'Cannot cancel an appointment the day before or the day of an appointment!',
            cancelConfirmation: 'Are you sure you want to cancel this appointment?',
            pageTitle: 'Appointments - Client',
            header: 'Your Appointments',
            createButton: 'Create Appointment',
            editButton: 'Edit',
            viewButton: 'View invoice',
            table: {
              firstName: 'First Name',
              lastName: 'Last Name',
              time: 'Time',
              date: 'Date',
              price: 'Price',
              service: 'Service',
              status: 'Status',
              details: {
                appointmentID: 'Appointment ID:',
                quoteID: 'Quote Request ID:',
                clientID: 'Client ID',
                clientName: 'Client Name',
                time: 'Time:',
                date: 'Date:',
                price: 'Price:',
                description: 'Description:',
                address: 'Address',
                service: 'Service:',
                status: 'Status:',
                closeButton: 'Close',
                cancelButton: 'Cancel'
              },
              serviceEnum: {
                HedgeTrimming: 'Hedge Trimming',
                TreeTrimming: 'Tree Trimming',
                TreeBranchRemoval: 'Tree Branch Removal',
                SmallTreeRemoval: 'Small Tree Removal',
                HedgeTrimmingAndTreeTrimming: 'Hedge Trimming And Tree Trimming',
                HedgeTrimmingAndTreeBranchRemoval: 'Hedge Trimming And Tree Branch Removal',
                TreeTrimmingAndTreeBranchRemoval: 'Tree Trimming And Tree Branch Removal',
                HedgeTrimmingAndTreeTrimmingAndTreeBranchRemoval: 'Hedge Trimming And Tree Trimming And Tree Branch Removal',
                HedgeTrimmingAndSmallTreeRemoval: 'Hedge Trimming And Small Tree Removal',
                TreeTrimmingAndSmallTreeRemoval: 'Tree Trimming And Small Tree Removal',
                HedgeTrimmingAndTreeTrimmingAndSmallTreeRemoval: 'Hedge Trimming And Tree Trimming And Small Tree Removal',
                HedgeTrimmingAndTreeBranchRemovalAndSmallTreeRemoval: 'Hedge Trimming And Tree Branch Removal And Small Tree Removal',
                TreeTrimmingAndTreeBranchRemovalAndSmallTreeRemoval: 'Tree Trimming And Tree Branch Removal And Small Tree Removal',
                HedgeTrimmingAndTreeTrimmingAndTreeBranchRemovalAndSmallTreeRemoval: 'Hedge Trimming And Tree Trimming And Tree Branch Removal And Small Tree Removal'
              },
              statusEnum: {
                COMPLETED: 'Completed',
                POSTPONED: 'Postponed',
                CANCELLED: 'Cancelled',
                UPCOMING: 'Upcoming',
                WITHIN_24_HOURS: 'Within 24 Hours',
                PENDING: 'Pending',
                WITHIN_ONE_WEEK: 'Within One Week'
              }
            }
          },
          appointmentsEmployee: {
            pageTitle: 'Appointments - Employee',
            header: 'Your Assigned Appointments',
            table: {
              firstName: 'First Name',
              lastName: 'Last Name',
              time: 'Time',
              date: 'Date',
              price: 'Price',
              service: 'Service',
              status: 'Status',
              serviceEnum: {
                HedgeTrimming: 'Hedge Trimming',
                TreeTrimming: 'Tree Trimming',
                TreeBranchRemoval: 'Tree Branch Removal',
                SmallTreeRemoval: 'Small Tree Removal',
                HedgeTrimmingAndTreeTrimming: 'Hedge Trimming And Tree Trimming',
                HedgeTrimmingAndTreeBranchRemoval: 'Hedge Trimming And Tree Branch Removal',
                TreeTrimmingAndTreeBranchRemoval: 'Tree Trimming And Tree Branch Removal',
                HedgeTrimmingAndTreeTrimmingAndTreeBranchRemoval: 'Hedge Trimming And Tree Trimming And Tree Branch Removal',
                HedgeTrimmingAndSmallTreeRemoval: 'Hedge Trimming And Small Tree Removal',
                TreeTrimmingAndSmallTreeRemoval: 'Tree Trimming And Small Tree Removal',
                HedgeTrimmingAndTreeTrimmingAndSmallTreeRemoval: 'Hedge Trimming And Tree Trimming And Small Tree Removal',
                HedgeTrimmingAndTreeBranchRemovalAndSmallTreeRemoval: 'Hedge Trimming And Tree Branch Removal And Small Tree Removal',
                TreeTrimmingAndTreeBranchRemovalAndSmallTreeRemoval: 'Tree Trimming And Tree Branch Removal And Small Tree Removal',
                HedgeTrimmingAndTreeTrimmingAndTreeBranchRemovalAndSmallTreeRemoval: 'Hedge Trimming And Tree Trimming And Tree Branch Removal And Small Tree Removal'
              },
              statusEnum: {
                COMPLETED: 'Completed',
                POSTPONED: 'Completed',
                CANCELLED: 'Cancelled',
                UPCOMING: 'Upcoming',
                WITHIN_24_HOURS: 'Within 24 Hours',
                PENDING: 'Pending',
                WITHIN_ONE_WEEK: 'Within One Week'
              }
            }
          },
          quotesAdmin: {
            pageTitle: 'Quote Requests - CTA',
            header: 'Quote Requests',
            addButton: 'Add Quote Request',
            deleteConfirmation: 'Are you sure you want to delete this quote request?',
            table: {
              firstName: 'First Name',
              lastName: 'Last Name',
              time: 'Time',
              date: 'Date',
              price: 'Price',
              service: 'Service',
              status: 'Status',
              employeeAssigned: 'Employee Assigned',
              employeeAssignedBody: 'No Employee Assigned',
              editButton: 'Edit',
              deleteButton: 'Delete',
              tbd: 'TBD',
              details: {
                quoteID: 'Quote Request ID:',
                clientID: 'Client ID',
                clientName: 'Client Name',
                employeeAssigned: 'Employee Assigned:',
                time: 'Time:',
                date: 'Date:',
                price: 'Price:',
                description: 'Description:',
                address: 'Address',
                service: 'Service:',
                status: 'Status:',
                closeButton: 'Close',
              }
            },
            serviceEnum: {
              HedgeTrimming: 'Hedge Trimming',
              TreeTrimming: 'Tree Trimming',
              TreeBranchRemoval: 'Tree Branch Removal',
              SmallTreeRemoval: 'Small Tree Removal',
              HedgeTrimmingAndTreeTrimming: 'Hedge Trimming And Tree Trimming',
              HedgeTrimmingAndTreeBranchRemoval: 'Hedge Trimming And Tree Branch Removal',
              TreeTrimmingAndTreeBranchRemoval: 'Tree Trimming And Tree Branch Removal',
              HedgeTrimmingAndTreeTrimmingAndTreeBranchRemoval: 'Hedge Trimming And Tree Trimming And Tree Branch Removal',
              HedgeTrimmingAndSmallTreeRemoval: 'Hedge Trimming And Small Tree Removal',
              TreeTrimmingAndSmallTreeRemoval: 'Tree Trimming And Small Tree Removal',
              HedgeTrimmingAndTreeTrimmingAndSmallTreeRemoval: 'Hedge Trimming And Tree Trimming And Small Tree Removal',
              HedgeTrimmingAndTreeBranchRemovalAndSmallTreeRemoval: 'Hedge Trimming And Tree Branch Removal And Small Tree Removal',
              TreeTrimmingAndTreeBranchRemovalAndSmallTreeRemoval: 'Tree Trimming And Tree Branch Removal And Small Tree Removal',
              HedgeTrimmingAndTreeTrimmingAndTreeBranchRemovalAndSmallTreeRemoval: 'Hedge Trimming And Tree Trimming And Tree Branch Removal And Small Tree Removal'
            },
            statusEnum: {
              QUOTE_SENT: 'Quote Sent',
              QUOTE_ACCEPTED: 'Quote Accepted',
              QUOTE_REFUSED: 'Quote Refused',
              PENDING: 'Pending'
            }
          },
          quotesClient: {
            pageTitle: 'Quote Requests - Client',
            header: 'Quote Requests',
            addButton: 'Add Quote Request',
            deleteConfirmation: 'Are you sure you want to delete this quote request?',
            table: {
              firstName: 'First Name',
              lastName: 'Last Name',
              time: 'Time',
              date: 'Date',
              price: 'Price',
              service: 'Service',
              status: 'Status',
              actions: 'Actions',
              editButton: 'Edit',
              deleteButton: 'Delete',
              tbd: 'TBD',
              details: {
                quoteID: 'Quote Request ID:',
                clientID: 'Client ID',
                clientName: 'Client Name',
                time: 'Time:',
                date: 'Date:',
                price: 'Price:',
                description: 'Description:',
                address: 'Address',
                service: 'Service:',
                status: 'Status:',
                closeButton: 'Close',
              }
            },
            serviceEnum: {
              HedgeTrimming: 'Hedge Trimming',
              TreeTrimming: 'Tree Trimming',
              TreeBranchRemoval: 'Tree Branch Removal',
              SmallTreeRemoval: 'Small Tree Removal',
              HedgeTrimmingAndTreeTrimming: 'Hedge Trimming And Tree Trimming',
              HedgeTrimmingAndTreeBranchRemoval: 'Hedge Trimming And Tree Branch Removal',
              TreeTrimmingAndTreeBranchRemoval: 'Tree Trimming And Tree Branch Removal',
              HedgeTrimmingAndTreeTrimmingAndTreeBranchRemoval: 'Hedge Trimming And Tree Trimming And Tree Branch Removal',
              HedgeTrimmingAndSmallTreeRemoval: 'Hedge Trimming And Small Tree Removal',
              TreeTrimmingAndSmallTreeRemoval: 'Tree Trimming And Small Tree Removal',
              HedgeTrimmingAndTreeTrimmingAndSmallTreeRemoval: 'Hedge Trimming And Tree Trimming And Small Tree Removal',
              HedgeTrimmingAndTreeBranchRemovalAndSmallTreeRemoval: 'Hedge Trimming And Tree Branch Removal And Small Tree Removal',
              TreeTrimmingAndTreeBranchRemovalAndSmallTreeRemoval: 'Tree Trimming And Tree Branch Removal And Small Tree Removal',
              HedgeTrimmingAndTreeTrimmingAndTreeBranchRemovalAndSmallTreeRemoval: 'Hedge Trimming And Tree Trimming And Tree Branch Removal And Small Tree Removal'
            },
            statusEnum: {
              QUOTE_SENT: 'Quote Sent',
              QUOTE_ACCEPTED: 'Quote Accepted',
              QUOTE_REFUSED: 'Quote Refused',
              PENDING: 'Pending'
            }
          },
          quotesEmployee: {
            pageTitle: 'Quote Requests - Employee',
            header: 'Quote Requests',
            table: {
              firstName: 'First Name',
              lastName: 'Last Name',
              time: 'Time',
              date: 'Date',
              address: 'Address',
              price: 'Price',
              service: 'Service',
              status: 'Status',
              actions: 'Actions',
              editButton: 'Edit',
              tbd: 'TBD',
            },
            serviceEnum: {
              HedgeTrimming: 'Hedge Trimming',
              TreeTrimming: 'Tree Trimming',
              TreeBranchRemoval: 'Tree Branch Removal',
              SmallTreeRemoval: 'Small Tree Removal',
              HedgeTrimmingAndTreeTrimming: 'Hedge Trimming And Tree Trimming',
              HedgeTrimmingAndTreeBranchRemoval: 'Hedge Trimming And Tree Branch Removal',
              TreeTrimmingAndTreeBranchRemoval: 'Tree Trimming And Tree Branch Removal',
              HedgeTrimmingAndTreeTrimmingAndTreeBranchRemoval: 'Hedge Trimming And Tree Trimming And Tree Branch Removal',
              HedgeTrimmingAndSmallTreeRemoval: 'Hedge Trimming And Small Tree Removal',
              TreeTrimmingAndSmallTreeRemoval: 'Tree Trimming And Small Tree Removal',
              HedgeTrimmingAndTreeTrimmingAndSmallTreeRemoval: 'Hedge Trimming And Tree Trimming And Small Tree Removal',
              HedgeTrimmingAndTreeBranchRemovalAndSmallTreeRemoval: 'Hedge Trimming And Tree Branch Removal And Small Tree Removal',
              TreeTrimmingAndTreeBranchRemovalAndSmallTreeRemoval: 'Tree Trimming And Tree Branch Removal And Small Tree Removal',
              HedgeTrimmingAndTreeTrimmingAndTreeBranchRemovalAndSmallTreeRemoval: 'Hedge Trimming And Tree Trimming And Tree Branch Removal And Small Tree Removal'
            },
            statusEnum: {
              QUOTE_SENT: 'Quote Sent',
              QUOTE_ACCEPTED: 'Quote Accepted',
              QUOTE_REFUSED: 'Quote Refused',
              PENDING: 'Pending'
            }
          },
          feedbackAdmin: {
            search:"Filter by Ratings:",
            deleteConfirmation: 'Are you sure you want to delete this feedback?',
            pageTitle: 'Feedbacks - CTA',
            header: 'Feedback List',
            table: {
              title: 'Title',
              firstName: 'First Name',
              lastName: 'Last Name',
              date: 'Date',
              ratings: 'Ratings',
              service: 'Service',
              actions: 'Actions',
              editButton: 'Edit',
              details: {
                feedbackID: 'Feedback ID:',
                appointmentID: 'Appointment ID:',
                clientID: 'Client ID:',
                clientName: 'Client Name',
                rating: 'Rating:',
                date: 'Date:',
                title: 'Title:',
                comments: 'Comments:',
                service: 'Service:',
                closeButton: 'Close',
                deleteButton: 'Delete Feedback'
              }
            },
            updateForm: {
              header: 'Update Feedback',
              title: 'Title',
              comments: 'Comments',
              service: 'Service',
              submitButton: 'Submit Update',
              cancelButton: 'Cancel'
            },
            serviceEnum: {
              HedgeTrimming: 'Hedge Trimming',
              TreeTrimming: 'Tree Trimming',
              TreeBranchRemoval: 'Tree Branch Removal',
              SmallTreeRemoval: 'Small Tree Removal',
              HedgeTrimmingAndTreeTrimming: 'Hedge Trimming And Tree Trimming',
              HedgeTrimmingAndTreeBranchRemoval: 'Hedge Trimming And Tree Branch Removal',
              TreeTrimmingAndTreeBranchRemoval: 'Tree Trimming And Tree Branch Removal',
              HedgeTrimmingAndTreeTrimmingAndTreeBranchRemoval: 'Hedge Trimming And Tree Trimming And Tree Branch Removal',
              HedgeTrimmingAndSmallTreeRemoval: 'Hedge Trimming And Small Tree Removal',
              TreeTrimmingAndSmallTreeRemoval: 'Tree Trimming And Small Tree Removal',
              HedgeTrimmingAndTreeTrimmingAndSmallTreeRemoval: 'Hedge Trimming And Tree Trimming And Small Tree Removal',
              HedgeTrimmingAndTreeBranchRemovalAndSmallTreeRemoval: 'Hedge Trimming And Tree Branch Removal And Small Tree Removal',
              TreeTrimmingAndTreeBranchRemovalAndSmallTreeRemoval: 'Tree Trimming And Tree Branch Removal And Small Tree Removal',
              HedgeTrimmingAndTreeTrimmingAndTreeBranchRemovalAndSmallTreeRemoval: 'Hedge Trimming And Tree Trimming And Tree Branch Removal And Small Tree Removal'
            },
          },
          feedbackClient: {
            deleteConfirmation: 'Are you sure you want to delete this feedback?',
            pageTitle: 'Feedbacks - Client',
            header: 'Feedback List',
            addButton: 'Leave Feedback',
            table: {
              title: 'Title',
              firstName: 'First Name',
              lastName: 'Last Name',
              date: 'Date',
              ratings: 'Ratings',
              service: 'Service',
              actions: 'Actions',
              details: {
                feedbackID: 'Feedback ID:',
                appointmentID: 'Appointment ID:',
                clientID: 'Client ID:',
                clientName: 'Client Name',
                rating: 'Rating:',
                date: 'Date:',
                title: 'Title:',
                comments: 'Comments:',
                service: 'Service:',
                closeButton: 'Close',
                deleteButton: 'Delete Feedback'
              }
            },
            serviceEnum: {
              HedgeTrimming: 'Hedge Trimming',
              TreeTrimming: 'Tree Trimming',
              TreeBranchRemoval: 'Tree Branch Removal',
              SmallTreeRemoval: 'Small Tree Removal',
              HedgeTrimmingAndTreeTrimming: 'Hedge Trimming And Tree Trimming',
              HedgeTrimmingAndTreeBranchRemoval: 'Hedge Trimming And Tree Branch Removal',
              TreeTrimmingAndTreeBranchRemoval: 'Tree Trimming And Tree Branch Removal',
              HedgeTrimmingAndTreeTrimmingAndTreeBranchRemoval: 'Hedge Trimming And Tree Trimming And Tree Branch Removal',
              HedgeTrimmingAndSmallTreeRemoval: 'Hedge Trimming And Small Tree Removal',
              TreeTrimmingAndSmallTreeRemoval: 'Tree Trimming And Small Tree Removal',
              HedgeTrimmingAndTreeTrimmingAndSmallTreeRemoval: 'Hedge Trimming And Tree Trimming And Small Tree Removal',
              HedgeTrimmingAndTreeBranchRemovalAndSmallTreeRemoval: 'Hedge Trimming And Tree Branch Removal And Small Tree Removal',
              TreeTrimmingAndTreeBranchRemovalAndSmallTreeRemoval: 'Tree Trimming And Tree Branch Removal And Small Tree Removal',
              HedgeTrimmingAndTreeTrimmingAndTreeBranchRemovalAndSmallTreeRemoval: 'Hedge Trimming And Tree Trimming And Tree Branch Removal And Small Tree Removal'
            },
          },
          createAppointmentAdmin: {
            pageTitle: 'New Appointment - CTA',
            header: 'Create Appointment',
            clientLabel: 'Select Client:',
            clientInputPlace: 'Filter Client By Name',
            selectClient: 'Select a Client',
            employeeLabel: 'Select Employee:',
            employeeInputPlace: 'Filter Employee By Name',
            selectEmployee: 'Select an Employee',
            quoteLabel: 'Select Approved Quote:',
            selectQuote: 'Select a Quote',
            dateLabel: 'Date:',
            timeLabel: 'Time:',
            submitButton: 'Submit',
            cancelButton: 'Cancel'
          },
          createAppointmentClient: {
            pageTitle: 'New Appointment - Client',
            header: 'Create Appointment',
            quoteLabel: 'Select Approved Quote:',
            selectQuote: 'Select a Quote',
            dateLabel: 'Date:',
            timeLabel: 'Time:',
            submitButton: 'Submit',
            cancelButton: 'Cancel'
          },
          createFeedbackClient: {
            pageTitle: 'New Feedback - Client',
            header: 'Create Feedback',
            appointmentLabel: 'Select Appointment:',
            selectAppointment: 'Select an Appointment',
            serviceLabel: 'Service:',
            selectService: 'Select a Service',
            titleLabel: 'Title',
            ratingLabel: 'Ratings:',
            commentLabel: 'Comment',
            submitButton: 'Submit',
            cancelButton: 'Cancel'
          },
          createQuoteAdmin: {
            pageTitle: 'New Quote Request - CTA',
            header: 'Create Quote Request',
            clientLabel: 'Select Client:',
            clientInputPlace: 'Filter Client By Name',
            selectClient: 'Select a Client',
            employeeLabel: 'Select Employee:',
            employeeInputPlace: 'Filter Employee By Name',
            selectEmployee: 'Select an Employee',
            descriptionLabel: 'Description:',
            serviceLabel: 'Service:',
            selectService: 'Select a Service',
            dateLabel: 'Date:',
            timeLabel: 'Time:',
            submitButton: 'Submit',
            cancelButton: 'Cancel'
          },
          createQuoteClient: {
            pageTitle: 'New Quote Request - CTA',
            header: 'Create Quote Request',
            descriptionLabel: 'Description:',
            serviceLabel: 'Service:',
            selectService: 'Select a Service',
            dateLabel: 'Date:',
            timeLabel: 'Time:',
            submitButton: 'Submit',
            cancelButton: 'Cancel'
          },
          updateAppointmentAdmin: {
            pageTitle: 'Update Appointment - CTA',
            header: 'Update Appointment for',
            employeeLabel: 'Select Employee:',
            employeeInputPlace: 'Filter Employee By Name',
            selectEmployee: 'No Assigned Employee',
            descriptionLabel: 'Description:',
            serviceLabel: 'Service:',
            selectService: 'Select a Service',
            dateLabel: 'Date:',
            timeLabel: 'Time:',
            statusLabel: 'Status:',
            selectStatus: 'Change Status',
            updateButton: 'Update',
            cancelButton: 'Cancel'
          },
          updateAppointmentClient: {
            pageTitle: 'Update Appointment - Client',
            header: 'Update Appointment',
            descriptionLabel: 'Description:',
            serviceLabel: 'Service:',
            selectService: 'Select a Service',
            dateLabel: 'Date:',
            timeLabel: 'Time:',
            updateButton: 'Update',
            cancelButton: 'Cancel'
          },
          updateAppointmentEmployee: {
            pageTitle: 'Update Appointment - CTA',
            header: 'Update Appointment',
            priceLabel: 'Price:',
            statusLabel: 'Status:',
            selectStatus: 'Change Status',
            updateButton: 'Update',
          },
          updateClientAdmin: {
            pageTitle: 'Update Client - CTA',
            header: 'Update Client',
            firstName: 'First Name:',
            lastName: 'Last Name:',
            email: 'Email:',
            phone: 'Phone Number',
            address: 'Address:',
            updateButton: 'Update Client'
          },
          updateQuoteAdmin: {
            pageTitle: 'Update Quote Request - CTA',
            header: 'Update Quote Request',
            employeeLabel: 'Select Employee:',
            employeeInputPlace: 'Filter Employee By Name',
            selectEmployee: 'No Assigned Employee',
            descriptionLabel: 'Description:',
            serviceLabel: 'Service:',
            selectService: 'Select a Service',
            dateLabel: 'Date:',
            timeLabel: 'Time:',
            statusLabel: 'Status:',
            selectStatus: 'Change Status',
            updateButton: 'Update',
            cancelButton: 'Cancel'
          },
          updateQuoteClient: {
            pageTitle: 'Update Quote Request - Client',
            header: 'Update Quote Request',
            descriptionLabel: 'Description:',
            serviceLabel: 'Service:',
            dateLabel: 'Date:',
            timeLabel: 'Time:',
            updateButton: 'Update',
            cancelButton: 'Cancel'
          },
          updateQuoteEmployee: {
            pageTitle: 'Update Quote Request - CTA',
            header: 'Update Quote Request',
            priceLabel: 'Price',
            statusLabel: 'Status:',
            selectStatus: 'Change Status',
            updateButton: 'Update',
          },
          invoicesAdmin: {
            pageTitle: 'Invoices - CTA',
            header: 'Invoice Management',
            deleteConfirmation: 'Are you sure you want to delete this invoice?',
            table: {
              firstName: 'First Name',
              lastName: 'Last Name',
              time: 'Time',
              date: 'Date',
              total: 'Total',
              service: 'Service',
              deleteButton: 'Delete'
            },
            detailsTable: {
              invoiceId: 'Invoice Id',
              appointmentId: 'Appointment Id',
              clientId: 'Client Id',
              clientName: 'Client name',
              time: 'Time',
              date: 'Date',
              subTotal: 'Subtotal',
              total: 'Total',
              description: 'Description',
              address: 'Address',
              service: 'Service'
            },
            serviceEnum: {
              HedgeTrimming: 'Hedge Trimming',
              TreeTrimming: 'Tree Trimming',
              TreeBranchRemoval: 'Tree Branch Removal',
              SmallTreeRemoval: 'Small Tree Removal',
              HedgeTrimmingAndTreeTrimming: 'Hedge Trimming And Tree Trimming',
              HedgeTrimmingAndTreeBranchRemoval: 'Hedge Trimming And Tree Branch Removal',
              TreeTrimmingAndTreeBranchRemoval: 'Tree Trimming And Tree Branch Removal',
              HedgeTrimmingAndTreeTrimmingAndTreeBranchRemoval: 'Hedge Trimming And Tree Trimming And Tree Branch Removal',
              HedgeTrimmingAndSmallTreeRemoval: 'Hedge Trimming And Small Tree Removal',
              TreeTrimmingAndSmallTreeRemoval: 'Tree Trimming And Small Tree Removal',
              HedgeTrimmingAndTreeTrimmingAndSmallTreeRemoval: 'Hedge Trimming And Tree Trimming And Small Tree Removal',
              HedgeTrimmingAndTreeBranchRemovalAndSmallTreeRemoval: 'Hedge Trimming And Tree Branch Removal And Small Tree Removal',
              TreeTrimmingAndTreeBranchRemovalAndSmallTreeRemoval: 'Tree Trimming And Tree Branch Removal And Small Tree Removal',
              HedgeTrimmingAndTreeTrimmingAndTreeBranchRemovalAndSmallTreeRemoval: 'Hedge Trimming And Tree Trimming And Tree Branch Removal And Small Tree Removal'
            },
            closeButton: 'Close',
            deleteButton: 'Delete',
            viewButton: 'View Invoice'
          }
        }
      },
      fr: {
        translation: {
          homeIntro: {
            title: 'Bienvenue à Coupe Ton Arbre',
            subtitle1: "Vos experts en aménagement paysager de confiance et principaux fournisseurs de services d'entretien des arbres dans la région du Grand Montréal.",
            subtitle2: 'Intéressé par des prix compétitifs et un service excellent?',
            signupButton: 'Créez un compte!'
          },
          homeAbout: {
            title: 'Notre avantage compétitif',
            subtitle1: 'Notre prix',
            subtitle2: 'Notre qualité',
            subtitle3: 'Nos méthodes éco-respectueuses',
            text1: "Choisissez Coupe Ton Arbre pour des solutions d'aménagement paysager rentables. Nos tarifs compétitifs garantissent des services de taille d'arbustes et d'arbres haut de gamme adaptés à vos besoins sans compromettre la qualité. Découvrez l'excellence professionnelle à une valeur imbattable.",
            text2: "Engager Coupe Ton Arbre garantit des services d'aménagement paysager haut de gamme. Notre dévouement à la qualité transparaît dans chaque projet de taille d'arbustes et d'arbres que nous entreprenons. Avec une attention méticuleuse aux détails et un savoir-faire d'expert, nous transformons votre espace extérieur en un sanctuaire magnifiquement taillé, distinguant ainsi votre propriété.",
            text3: "Chez Coupe Ton Arbre, nous accordons une grande importance à l'aménagement paysager respectueux de l'environnement. De la taille des haies à l'enlèvement des grosses branches, nous veillons à la durabilité et à l'éco-responsabilité. En utilisant des outils électriques, nous créons des espaces magnifiques tout en minimisant les émissions et le bruit. Notre équipe travaille soigneusement sur votre espace pour garantir un écosystème plus sain."
          },
          homeServices: {
            title: 'Nos services comprennent',
            subtitle1: 'La taille des haies',
            subtitle2: "L'élagage des arbres",
            subtitle3: "L'Abbattage et enlèvement d'arbre",
            subtitle4: 'La taille des arbustes et des conifères',
            note: "*Tous nos services incluent la collecte et le nettoyage. Tout ce qui est coupé ou taillé est transporté vers un centre écologique pour être pris en charge de manière appropriée.",
          },
          footer: {
            sectionOne: {
              header: 'Site Web',
              home: 'Accueil',
              ourWork: 'Notre Travail',
              profile: 'Mon Profil'
            },
            sectionTwo: {
              header: 'Services Offerts',
              serviceOne: 'Taille des Haies, Arbustes et Cônes',
              serviceTwo: `Élagage de Haies et D'Arbres`
            },
            sectionThree: {
              header: 'Contact',
              address: '7816 Champlain BLVD, Lasalle',
              phone: '(438) 504-6055'
            },
            sectionFour: {
              header: 'Heures',
              week: 'Semaine : 8h- 20h.',
              saturday: 'Samedi : 9h- 18h',
              sunday: 'Dimanche : 9h - 17h'
            },
            copyright: 'Coupe Ton Arbre. Tous droits réservés.'
          },
          adminNav: {
            home: 'Accueil',
            appointments: 'Rendez-vous',
            quotes: 'Devis',
            accounts: 'Comptes',
            feedback: 'Commentaires',
            invoices: 'Factures',
            dashboard: 'Panneau',
            login: 'Connexion',
            logout: 'Déconnexion'
          },
          clientNav: {
            home: 'Accueil',
            appointments: 'Rendez-vous',
            quotes: 'Devis',
            feedback: 'Commentaires',
            profile: 'Profil',
            login: 'Connexion',
            logout: 'Déconnexion'
          },
          employeeNav: {
            home: 'Accueil',
            appointments: 'Rendez-vous',
            quotes: 'Devis',
            profile: 'Profil',
            login: 'Connexion',
            logout: 'Déconnexion'
          },
          mainNav: {
            home: 'Accueil',
            login: 'Connexion',
            logout: 'Déconnexion',
            about: 'À propos',
            services: 'Nos Services'
          },
          accountsAdmin: {
            pageTitle: 'Comptes Clients - CTA',
            header: 'Clients',
            addClient: 'Ajout Client',
            addClientPopup: {
              header: 'Ajouter un client',
              firstNamePlace: 'Prénom',
              lastNamePlace: 'Nom',
              emailPlace: 'Courriel',
              phoneNumberPlace: 'Téléphone',
              addressPlace: 'Adresse complète',
              submit: 'Soumettre Le Client',
              cancel: 'Annuler'
            },
            clientTable: {
              firstNameHeader: 'Prénom',
              lastNameHeader: 'Nom',
              emailHeader: 'Courriel',
              phoneHeader: 'Téléphone',
              addressHeader: 'Adresse',
              actionHeader: 'Actions',
              deleteButton: "Supprimer",
              editButton: 'Modifier'
            },
            clientDetails: {
              header: ``,
              id: 'ID Client: ',
              name: 'Prénom: ',
              email: 'Courriel: ',
              phone: 'Téléphone: ',
              address: 'Adresse: ',
              close: 'Fermer'
            }
          },
          adminDashboard: {
            pageTitle: 'Panneau Admin - CTA',
            welcome: 'Bienvenue',
            createAdmin: 'Créer Compte Admin',
            addAdmin: 'Ajouter Admin',
            createEmployee: 'Créer Compte Employé',
            addEmployee: 'Ajouter Employé',
            noAdmin: `Nombre d'Admins`,
            noEmployee: `Nombre d'Employés`,
            noClient: 'Nombre de Clients',
            successMessageAdmin: {
              title: 'Admin créé!',
              body: `L'Admin a été créé avec succès!`
            },
            successMessageEmployee: {
              title: 'Employé créé!',
              body: 'Employé a été créé avec succès!'
            },
            addAdminForm: {
              header: 'Ajouter Admin',
              email: 'Courriel',
              emailPlace: 'Courriel',
              pass: 'Mot de passe',
              passPlace: 'Mot de passe',
              button: 'Ajouter Admin',
              cancel: 'Annuler'
            },
            addEmployeeForm: {
              header: 'Ajouter Employé',
              email: 'Courriel',
              emailPlace: 'Courriel',
              pass: 'Mot de passe',
              passPlace: 'Mot de passe',
              button: 'Ajouter Employé',
              cancel: 'Annuler'
            }
          },
          clientProfile: {
            pageTitle: 'Profil - CTA',
            profileSuccessMessage: 'Profil Créé Avec Succès',
            profileErrorMessage: {
              title: 'Erreur!',
              body: 'Un ou plusieurs erreurs présentes !\n'
            },
            header: 'Votre profil',
            firstName: 'Prénom: ',
            lastName: 'Nom: ',
            email: 'Courriel: ',
            phone: 'Téléphone: ',
            address: 'Adresse: ',
            updateButton: 'Mettre à jour vos informations',
            profileCreation: {
              header: 'Bonjour et bienvenue au CTA!',
              followUp: 'Pour commencer, veuillez remplir le formulaire suivant avec vos informations',
              firstName: 'Prénom:',
              firstNamePlace: 'Prénom',
              lastName: 'Nom:',
              lastNamePlace: 'Nom',
              email: 'Courriel:',
              emailPlace: 'Courriel',
              phone: 'Téléphone:',
              phonePlace: 'Téléphone',
              address: 'Adresse:',
              addressPlace: 'Adresse',
              submit: 'Soumettre',
              errorBothHeader: 'Courriel et Numéro de Téléphone Invalides',
              errorBothBody: 'Veuillez Entrer une Adresse Courriel et un Numéro de Téléphone Valides',
              errorEmailHeader: 'Courriel Invalide',
              errorEmailBody: 'Veuillez Entrer une Adresse Courriel Valide',
              errorPhoneHeader: 'Numéro de Téléphone Invalide',
              errorPhoneBody: 'Veuillez Entrer un Numéro de Téléphone Valide'
            }
          },
          updateClientProfile: {
            pageTitle: 'Mise à Jour du Profil - CTA',
            header: 'Mettre à Jour Vos Informations',
            form: {
              firstName: 'Prénom:',
              lastName: 'Nom:',
              email: 'Courriel:',
              phone: 'Téléphone:',
              address: 'Adresse:',
              save: 'Sauvegarde'
            }
          },
          employeeProfile: {
            pageTitle: 'Profil - CTA',
            header: 'Votre profil',
            firstName: 'Prénom: ',
            lastName: 'Nom: ',
            profileSuccessMessage: 'Profil Créé Avec Succès',
            profileErrorMessage: {
              title: 'Erreur!',
              body: 'Un ou plusieurs erreurs présentes !\n'
            },
            profileCreation: {
              header: 'Bonjour et bienvenue au CTA!',
              followUp: 'Pour commencer, veuillez remplir le formulaire suivant avec vos informations',
              firstName: 'Prénom:',
              firstNamePlace: 'Prénom',
              lastName: 'Nom:',
              lastNamePlace: 'Nom',
              submit: 'Soumettre'
            }
          },
          appointmentsAdmin: {
            cancelAlert: `Il n'est pas possible d'annuler un rendez-vous la veille ou le jour même!`,
            cancelConfirmation: 'Êtes-vous sûr de vouloir annuler ce rendez-vous?',
            pageTitle: 'Rendez-Vous - CTA',
            header: 'Gestion des Rendez-Vous',
            createButton: 'Créer Un Rendez-Vous',
            table: {
              firstName: 'Prénom',
              lastName: 'Nom',
              time: 'Heure',
              date: 'Date',
              price: 'Prix',
              service: 'Service',
              status: 'Statut',
              employeeAssigned: 'Employé Désigné',
              employeeAssignedBody: `Pas d'employé désigné`,
              editButton: 'Modifier',
              details: {
                appointmentID: 'ID de Rendez-Vous:',
                quoteID: 'ID de Devis:',
                clientID: 'ID du Client:',
                clientName: 'Nom du Client:',
                employeeAssigned: 'Employé Désigné:',
                time: 'Heure:',
                date: 'Date:',
                price: 'Prix:',
                address: 'Adresse',
                description: 'Description:',
                service: 'Service:',
                status: 'Statut:',
                closeButton: 'Fermer',
                cancelButton: 'Annuler'
              },
              serviceEnum: {
                HedgeTrimming: 'Taille des Haies',
                TreeTrimming: 'Taille des Arbres',
                TreeBranchRemoval: `Enlèvement des Branches d'Arbres`,
                SmallTreeRemoval: 'Enlèvement des Petits Arbres',
                HedgeTrimmingAndTreeTrimming: `Taille de Haies et d'Arbres`,
                HedgeTrimmingAndTreeBranchRemoval: `Taille des Haies et Enlèvement des Branches d'Arbres`,
                TreeTrimmingAndTreeBranchRemoval: 'Taille des Arbres et Enlèvement des Branches',
                HedgeTrimmingAndTreeTrimmingAndTreeBranchRemoval: `Taille de Haies, Taille d'Arbres et Enlèvement de Branches d'Arbres`,
                HedgeTrimmingAndSmallTreeRemoval: 'Taille de Haies et Enlèvement de Petits Arbres',
                TreeTrimmingAndSmallTreeRemoval: 'Taille des Arbres et Enlèvement des Petits Arbres',
                HedgeTrimmingAndTreeTrimmingAndSmallTreeRemoval: `Taille de Haies, Taille d'Arbres et Enlèvement de Petits Arbres`,
                HedgeTrimmingAndTreeBranchRemovalAndSmallTreeRemoval: `Taille de Haies, Enlèvement de Branches d'Arbres et Enlèvement de Petits Arbres`,
                TreeTrimmingAndTreeBranchRemovalAndSmallTreeRemoval: 'Taille des Arbres, Enlèvement des Branches et Enlèvement des Petits Arbres',
                HedgeTrimmingAndTreeTrimmingAndTreeBranchRemovalAndSmallTreeRemoval: `Taille de Haies et Taille d'Arbres et Enlèvement de Branches d'Arbres et Enlèvement de Petits Arbres`
              },
              statusEnum: {
                COMPLETED: 'Complété',
                POSTPONED: 'Reporté',
                CANCELLED: 'Annulé',
                UPCOMING: 'À venir',
                WITHIN_24_HOURS: 'Dans les prochaines 24 heures',
                PENDING: 'En attente',
                WITHIN_ONE_WEEK: `En moins d'une semaine`
              }
            }
          },
          appointmentsClient: {
            cancelAlert: `Il n'est pas possible d'annuler un rendez-vous la veille ou le jour même!`,
            cancelConfirmation: 'Êtes-vous sûr de vouloir annuler ce rendez-vous?',
            pageTitle: 'Rendez-Vous - CTA',
            header: 'Vos Rendez-Vous',
            createButton: 'Créer Un Rendez-Vous',
            viewButton: 'Voir la facture',
            table: {
              firstName: 'Prénom',
              lastName: 'Nom',
              time: 'Heure',
              date: 'Date',
              price: 'Prix',
              service: 'Service',
              status: 'Statut',
              editButton: 'Modifier',
              details: {
                appointmentID: 'ID de Rendez-Vous:',
                quoteID: 'ID de Devis:',
                clientID: 'ID du Client:',
                clientName: 'Nom du Client:',
                time: 'Heure:',
                date: 'Date:',
                price: 'Prix:',
                address: 'Adresse',
                description: 'Description:',
                service: 'Service:',
                status: 'Statut:',
                closeButton: 'Fermer',
                cancelButton: 'Annuler'
              },
              serviceEnum: {
                HedgeTrimming: 'Taille des Haies',
                TreeTrimming: 'Taille des Arbres',
                TreeBranchRemoval: `Enlèvement des Branches d'Arbres`,
                SmallTreeRemoval: 'Enlèvement des Petits Arbres',
                HedgeTrimmingAndTreeTrimming: `Taille de Haies et d'Arbres`,
                HedgeTrimmingAndTreeBranchRemoval: `Taille des Haies et Enlèvement des Branches d'Arbres`,
                TreeTrimmingAndTreeBranchRemoval: 'Taille des Arbres et Enlèvement des Branches',
                HedgeTrimmingAndTreeTrimmingAndTreeBranchRemoval: `Taille de Haies, Taille d'Arbres et Enlèvement de Branches d'Arbres`,
                HedgeTrimmingAndSmallTreeRemoval: 'Taille de Haies et Enlèvement de Petits Arbres',
                TreeTrimmingAndSmallTreeRemoval: 'Taille des Arbres et Enlèvement des Petits Arbres',
                HedgeTrimmingAndTreeTrimmingAndSmallTreeRemoval: `Taille de Haies, Taille d'Arbres et Enlèvement de Petits Arbres`,
                HedgeTrimmingAndTreeBranchRemovalAndSmallTreeRemoval: `Taille de Haies, Enlèvement de Branches d'Arbres et Enlèvement de Petits Arbres`,
                TreeTrimmingAndTreeBranchRemovalAndSmallTreeRemoval: 'Taille des Arbres, Enlèvement des Branches et Enlèvement des Petits Arbres',
                HedgeTrimmingAndTreeTrimmingAndTreeBranchRemovalAndSmallTreeRemoval: `Taille de Haies et Taille d'Arbres et Enlèvement de Branches d'Arbres et Enlèvement de Petits Arbres`
              },
              statusEnum: {
                COMPLETED: 'Complété',
                POSTPONED: 'Reporté',
                CANCELLED: 'Annulé',
                UPCOMING: 'À venir',
                WITHIN_24_HOURS: 'Dans les prochaines 24 heures',
                PENDING: 'En attente',
                WITHIN_ONE_WEEK: `En moins d'une semaine`
              }
            }
          },
          appointmentsEmployee: {
            pageTitle: 'Rendez-Vous - Employee',
            header: 'Vos Rendez-Vous Assignés',
            table: {
              firstName: 'Prénom',
              lastName: 'Nom',
              time: 'Heure',
              date: 'Date',
              price: 'Prix',
              service: 'Service',
              status: 'Statut',
              serviceEnum: {
                HedgeTrimming: 'Taille des Haies',
                TreeTrimming: 'Taille des Arbres',
                TreeBranchRemoval: `Enlèvement des Branches d'Arbres`,
                SmallTreeRemoval: 'Enlèvement des Petits Arbres',
                HedgeTrimmingAndTreeTrimming: `Taille de Haies et d'Arbres`,
                HedgeTrimmingAndTreeBranchRemoval: `Taille des Haies et Enlèvement des Branches d'Arbres`,
                TreeTrimmingAndTreeBranchRemoval: 'Taille des Arbres et Enlèvement des Branches',
                HedgeTrimmingAndTreeTrimmingAndTreeBranchRemoval: `Taille de Haies, Taille d'Arbres et Enlèvement de Branches d'Arbres`,
                HedgeTrimmingAndSmallTreeRemoval: 'Taille de Haies et Enlèvement de Petits Arbres',
                TreeTrimmingAndSmallTreeRemoval: 'Taille des Arbres et Enlèvement des Petits Arbres',
                HedgeTrimmingAndTreeTrimmingAndSmallTreeRemoval: `Taille de Haies, Taille d'Arbres et Enlèvement de Petits Arbres`,
                HedgeTrimmingAndTreeBranchRemovalAndSmallTreeRemoval: `Taille de Haies, Enlèvement de Branches d'Arbres et Enlèvement de Petits Arbres`,
                TreeTrimmingAndTreeBranchRemovalAndSmallTreeRemoval: 'Taille des Arbres, Enlèvement des Branches et Enlèvement des Petits Arbres',
                HedgeTrimmingAndTreeTrimmingAndTreeBranchRemovalAndSmallTreeRemoval: `Taille de Haies et Taille d'Arbres et Enlèvement de Branches d'Arbres et Enlèvement de Petits Arbres`
              },
              statusEnum: {
                COMPLETED: 'Complété',
                POSTPONED: 'Reporté',
                CANCELLED: 'Annulé',
                UPCOMING: 'À venir',
                WITHIN_24_HOURS: 'Dans les prochaines 24 heures',
                PENDING: 'En attente',
                WITHIN_ONE_WEEK: `En moins d'une semaine`
              }
            }
          },
          quotesAdmin: {
            pageTitle: 'Devis - CTA',
            header: 'Devis',
            addButton: 'Ajouter Une Demande de Devis',
            deleteConfirmation: 'Êtes-vous sûr de vouloir supprimer cette demande de devis?',
            table: {
              firstName: 'Prénom',
              lastName: 'Nom',
              time: 'Heure',
              date: 'Date',
              price: 'Prix',
              service: 'Service',
              status: 'Statut',
              employeeAssigned: 'Employé Désigné',
              employeeAssignedBody: `Pas d'employé désigné`,
              editButton: 'Modifier',
              deleteButton: 'Effacer',
              tbd: 'À déterminer',
              details: {
                quoteID: 'ID de Devis:',
                clientID: 'ID du Client:',
                clientName: 'Nom du Client:',
                employeeAssigned: 'Employé Désigné:',
                time: 'Heure:',
                date: 'Date:',
                price: 'Prix:',
                description: 'Description:',
                address: 'Adresse',
                service: 'Service:',
                status: 'Statut:',
                closeButton: 'Fermer',
              }
            },
            serviceEnum: {
              HedgeTrimming: 'Taille des Haies',
              TreeTrimming: 'Taille des Arbres',
              TreeBranchRemoval: `Enlèvement des Branches d'Arbres`,
              SmallTreeRemoval: 'Enlèvement des Petits Arbres',
              HedgeTrimmingAndTreeTrimming: `Taille de Haies et d'Arbres`,
              HedgeTrimmingAndTreeBranchRemoval: `Taille des Haies et Enlèvement des Branches d'Arbres`,
              TreeTrimmingAndTreeBranchRemoval: 'Taille des Arbres et Enlèvement des Branches',
              HedgeTrimmingAndTreeTrimmingAndTreeBranchRemoval: `Taille de Haies, Taille d'Arbres et Enlèvement de Branches d'Arbres`,
              HedgeTrimmingAndSmallTreeRemoval: 'Taille de Haies et Enlèvement de Petits Arbres',
              TreeTrimmingAndSmallTreeRemoval: 'Taille des Arbres et Enlèvement des Petits Arbres',
              HedgeTrimmingAndTreeTrimmingAndSmallTreeRemoval: `Taille de Haies, Taille d'Arbres et Enlèvement de Petits Arbres`,
              HedgeTrimmingAndTreeBranchRemovalAndSmallTreeRemoval: `Taille de Haies, Enlèvement de Branches d'Arbres et Enlèvement de Petits Arbres`,
              TreeTrimmingAndTreeBranchRemovalAndSmallTreeRemoval: 'Taille des Arbres, Enlèvement des Branches et Enlèvement des Petits Arbres',
              HedgeTrimmingAndTreeTrimmingAndTreeBranchRemovalAndSmallTreeRemoval: `Taille de Haies et Taille d'Arbres et Enlèvement de Branches d'Arbres et Enlèvement de Petits Arbres`
            },
            statusEnum: {
              QUOTE_SENT: 'Devis Envoyé',
              QUOTE_ACCEPTED: 'Devis Accepté',
              QUOTE_REFUSED: 'Devis Refusé',
              PENDING: 'En Cours'
            }
          },
          quotesClient: {
            pageTitle: 'Devis - Client',
            header: 'Devis',
            addButton: 'Ajouter Une Demande de Devis',
            deleteConfirmation: 'Êtes-vous sûr de vouloir supprimer cette demande de devis?',
            table: {
              firstName: 'Prénom',
              lastName: 'Nom',
              time: 'Heure',
              date: 'Date',
              price: 'Prix',
              service: 'Service',
              status: 'Statut',
              editButton: 'Modifier',
              deleteButton: 'Effacer',
              tbd: 'À déterminer',
              actions: 'Actions',
              details: {
                quoteID: 'ID de Devis:',
                clientID: 'ID du Client:',
                clientName: 'Nom du Client:',
                time: 'Heure:',
                date: 'Date:',
                price: 'Prix:',
                description: 'Description:',
                address: 'Adresse',
                service: 'Service:',
                status: 'Statut:',
                closeButton: 'Fermer',
              }
            },
            serviceEnum: {
              HedgeTrimming: 'Taille des Haies',
              TreeTrimming: 'Taille des Arbres',
              TreeBranchRemoval: `Enlèvement des Branches d'Arbres`,
              SmallTreeRemoval: 'Enlèvement des Petits Arbres',
              HedgeTrimmingAndTreeTrimming: `Taille de Haies et d'Arbres`,
              HedgeTrimmingAndTreeBranchRemoval: `Taille des Haies et Enlèvement des Branches d'Arbres`,
              TreeTrimmingAndTreeBranchRemoval: 'Taille des Arbres et Enlèvement des Branches',
              HedgeTrimmingAndTreeTrimmingAndTreeBranchRemoval: `Taille de Haies, Taille d'Arbres et Enlèvement de Branches d'Arbres`,
              HedgeTrimmingAndSmallTreeRemoval: 'Taille de Haies et Enlèvement de Petits Arbres',
              TreeTrimmingAndSmallTreeRemoval: 'Taille des Arbres et Enlèvement des Petits Arbres',
              HedgeTrimmingAndTreeTrimmingAndSmallTreeRemoval: `Taille de Haies, Taille d'Arbres et Enlèvement de Petits Arbres`,
              HedgeTrimmingAndTreeBranchRemovalAndSmallTreeRemoval: `Taille de Haies, Enlèvement de Branches d'Arbres et Enlèvement de Petits Arbres`,
              TreeTrimmingAndTreeBranchRemovalAndSmallTreeRemoval: 'Taille des Arbres, Enlèvement des Branches et Enlèvement des Petits Arbres',
              HedgeTrimmingAndTreeTrimmingAndTreeBranchRemovalAndSmallTreeRemoval: `Taille de Haies et Taille d'Arbres et Enlèvement de Branches d'Arbres et Enlèvement de Petits Arbres`
            },
            statusEnum: {
              QUOTE_SENT: 'Devis Envoyé',
              QUOTE_ACCEPTED: 'Devis Accepté',
              QUOTE_REFUSED: 'Devis Refusé',
              PENDING: 'En Cours'
            }
          },
          quotesEmployee: {
            pageTitle: 'Devis - Employee',
            header: 'Devis',
            table: {
              firstName: 'Prénom',
              lastName: 'Nom',
              time: 'Heure',
              date: 'Date',
              address: 'Adresse',
              price: 'Prix',
              service: 'Service',
              status: 'Statut',
              actions: 'Actions',
              editButton: 'Modifier',
              tbd: 'À déterminer',
            },
            serviceEnum: {
              HedgeTrimming: 'Taille des Haies',
              TreeTrimming: 'Taille des Arbres',
              TreeBranchRemoval: `Enlèvement des Branches d'Arbres`,
              SmallTreeRemoval: 'Enlèvement des Petits Arbres',
              HedgeTrimmingAndTreeTrimming: `Taille de Haies et d'Arbres`,
              HedgeTrimmingAndTreeBranchRemoval: `Taille des Haies et Enlèvement des Branches d'Arbres`,
              TreeTrimmingAndTreeBranchRemoval: 'Taille des Arbres et Enlèvement des Branches',
              HedgeTrimmingAndTreeTrimmingAndTreeBranchRemoval: `Taille de Haies, Taille d'Arbres et Enlèvement de Branches d'Arbres`,
              HedgeTrimmingAndSmallTreeRemoval: 'Taille de Haies et Enlèvement de Petits Arbres',
              TreeTrimmingAndSmallTreeRemoval: 'Taille des Arbres et Enlèvement des Petits Arbres',
              HedgeTrimmingAndTreeTrimmingAndSmallTreeRemoval: `Taille de Haies, Taille d'Arbres et Enlèvement de Petits Arbres`,
              HedgeTrimmingAndTreeBranchRemovalAndSmallTreeRemoval: `Taille de Haies, Enlèvement de Branches d'Arbres et Enlèvement de Petits Arbres`,
              TreeTrimmingAndTreeBranchRemovalAndSmallTreeRemoval: 'Taille des Arbres, Enlèvement des Branches et Enlèvement des Petits Arbres',
              HedgeTrimmingAndTreeTrimmingAndTreeBranchRemovalAndSmallTreeRemoval: `Taille de Haies et Taille d'Arbres et Enlèvement de Branches d'Arbres et Enlèvement de Petits Arbres`
            },
            statusEnum: {
              QUOTE_SENT: 'Devis Envoyé',
              QUOTE_ACCEPTED: 'Devis Accepté',
              QUOTE_REFUSED: 'Devis Refusé',
              PENDING: 'En Cours'
            }
          },
          feedbackAdmin: {
            search:"Filtrer par notes :",
            deleteConfirmation: 'Êtes-vous sûr de vouloir supprimer ce commentaire?',
            pageTitle: 'Commentaires - CTA',
            header: 'Liste des Commentaires',
            table: {
              title: 'Titre',
              firstName: 'Prénom',
              lastName: 'Nom',
              date: 'Date',
              ratings: 'Évaluations',
              service: 'Service',
              actions: 'Actions',
              editButton: 'Modifier',
              details: {
                feedbackID: 'ID de Commentaire:',
                appointmentID: 'ID de Rendez-Vous:',
                clientID: 'ID du Client:',
                clientName: 'Nom du Client:',
                rating: 'Évaluation:',
                date: 'Date:',
                title: 'Titre:',
                comments: 'Observations:',
                service: 'Service:',
                closeButton: 'Fermer',
                deleteButton: 'Supprimer'
              }
            },
            updateForm: {
              header: 'Mise à Jour de Commentaire',
              title: 'Titre',
              comments: 'Observations',
              service: 'Service',
              submitButton: 'Soumettre',
              cancelButton: 'Annuler'
            },
            serviceEnum: {
              HedgeTrimming: 'Taille des Haies',
              TreeTrimming: 'Taille des Arbres',
              TreeBranchRemoval: `Enlèvement des Branches d'Arbres`,
              SmallTreeRemoval: 'Enlèvement des Petits Arbres',
              HedgeTrimmingAndTreeTrimming: `Taille de Haies et d'Arbres`,
              HedgeTrimmingAndTreeBranchRemoval: `Taille des Haies et Enlèvement des Branches d'Arbres`,
              TreeTrimmingAndTreeBranchRemoval: 'Taille des Arbres et Enlèvement des Branches',
              HedgeTrimmingAndTreeTrimmingAndTreeBranchRemoval: `Taille de Haies, Taille d'Arbres et Enlèvement de Branches d'Arbres`,
              HedgeTrimmingAndSmallTreeRemoval: 'Taille de Haies et Enlèvement de Petits Arbres',
              TreeTrimmingAndSmallTreeRemoval: 'Taille des Arbres et Enlèvement des Petits Arbres',
              HedgeTrimmingAndTreeTrimmingAndSmallTreeRemoval: `Taille de Haies, Taille d'Arbres et Enlèvement de Petits Arbres`,
              HedgeTrimmingAndTreeBranchRemovalAndSmallTreeRemoval: `Taille de Haies, Enlèvement de Branches d'Arbres et Enlèvement de Petits Arbres`,
              TreeTrimmingAndTreeBranchRemovalAndSmallTreeRemoval: 'Taille des Arbres, Enlèvement des Branches et Enlèvement des Petits Arbres',
              HedgeTrimmingAndTreeTrimmingAndTreeBranchRemovalAndSmallTreeRemoval: `Taille de Haies et Taille d'Arbres et Enlèvement de Branches d'Arbres et Enlèvement de Petits Arbres`
            }
          },
          feedbackClient: {
            deleteConfirmation: 'Êtes-vous sûr de vouloir supprimer ce commentaire?',
            pageTitle: 'Commentaires - Client',
            header: 'Liste des Commentaires',
            addButton: 'Laisser un Commentaire',
            table: {
              title: 'Titre',
              firstName: 'Prénom',
              lastName: 'Nom',
              date: 'Date',
              ratings: 'Évaluations',
              service: 'Service',
              actions: 'Actions',
              details: {
                feedbackID: 'ID de Commentaire:',
                appointmentID: 'ID de Rendez-Vous:',
                clientID: 'ID du Client:',
                clientName: 'Nom du Client:',
                rating: 'Évaluation:',
                date: 'Date:',
                title: 'Titre:',
                comments: 'Observations:',
                service: 'Service:',
                closeButton: 'Fermer',
                deleteButton: 'Supprimer'
              }
            },
            serviceEnum: {
              HedgeTrimming: 'Taille des Haies',
              TreeTrimming: 'Taille des Arbres',
              TreeBranchRemoval: `Enlèvement des Branches d'Arbres`,
              SmallTreeRemoval: 'Enlèvement des Petits Arbres',
              HedgeTrimmingAndTreeTrimming: `Taille de Haies et d'Arbres`,
              HedgeTrimmingAndTreeBranchRemoval: `Taille des Haies et Enlèvement des Branches d'Arbres`,
              TreeTrimmingAndTreeBranchRemoval: 'Taille des Arbres et Enlèvement des Branches',
              HedgeTrimmingAndTreeTrimmingAndTreeBranchRemoval: `Taille de Haies, Taille d'Arbres et Enlèvement de Branches d'Arbres`,
              HedgeTrimmingAndSmallTreeRemoval: 'Taille de Haies et Enlèvement de Petits Arbres',
              TreeTrimmingAndSmallTreeRemoval: 'Taille des Arbres et Enlèvement des Petits Arbres',
              HedgeTrimmingAndTreeTrimmingAndSmallTreeRemoval: `Taille de Haies, Taille d'Arbres et Enlèvement de Petits Arbres`,
              HedgeTrimmingAndTreeBranchRemovalAndSmallTreeRemoval: `Taille de Haies, Enlèvement de Branches d'Arbres et Enlèvement de Petits Arbres`,
              TreeTrimmingAndTreeBranchRemovalAndSmallTreeRemoval: 'Taille des Arbres, Enlèvement des Branches et Enlèvement des Petits Arbres',
              HedgeTrimmingAndTreeTrimmingAndTreeBranchRemovalAndSmallTreeRemoval: `Taille de Haies et Taille d'Arbres et Enlèvement de Branches d'Arbres et Enlèvement de Petits Arbres`
            }
          },
          createAppointmentAdmin: {
            pageTitle: 'Nouveau Rendez-Vous - CTA',
            header: 'Créer un Rendez-Vous',
            clientLabel: 'Sélectionnez Client:',
            clientInputPlace: 'Filtrer Le Client Par Nom',
            selectClient: 'Sélectionnez Client',
            employeeLabel: 'Sélectionnez un Employé:',
            employeeInputPlace: 'Filtrer Les Employés Par Nom',
            selectEmployee: 'Sélectionnez un Employé',
            quoteLabel: 'Sélectionner un Devis Approuvé:',
            selectQuote: 'Sélectionner un Devis',
            dateLabel: 'Date:',
            timeLabel: 'Heure:',
            submitButton: 'Soumettre',
            cancelButton: 'Annuler'
          },
          createAppointmentClient: {
            pageTitle: 'Nouveau Rendez-Vous - Client',
            header: 'Créer un Rendez-Vous',
            quoteLabel: 'Sélectionner un Devis Approuvé:',
            selectQuote: 'Sélectionner un Devis',
            dateLabel: 'Date:',
            timeLabel: 'Heure:',
            submitButton: 'Soumettre',
            cancelButton: 'Annuler'
          },
          createFeedbackClient: {
            pageTitle: 'Nouveau Commentaire - Client',
            header: 'Créer un Commentaire',
            appointmentLabel: 'Sélectionner un Rendez-Vous:',
            selectAppointment: 'Sélectionner un Rendez-Vous',
            serviceLabel: 'Service:',
            selectService: 'Sélectionner un Service',
            titleLabel: 'Titre',
            ratingLabel: 'Evaluation:',
            commentLabel: 'Observations',
            submitButton: 'Soumettre',
            cancelButton: 'Annuler'
          },
          createQuoteAdmin: {
            pageTitle: 'Nouvelle Demande de Devis - CTA',
            header: 'Créer une Demande de Devis',
            clientLabel: 'Sélectionnez Client:',
            clientInputPlace: 'Filtrer Le Client Par Nom',
            selectClient: 'Sélectionnez Client',
            employeeLabel: 'Sélectionnez un Employé:',
            employeeInputPlace: 'Filtrer Les Employés Par Nom',
            selectEmployee: 'Sélectionnez un Employé',
            descriptionLabel: 'Description:',
            serviceLabel: 'Service:',
            selectService: 'Sélectionner un Service',
            dateLabel: 'Date:',
            timeLabel: 'Heure:',
            submitButton: 'Soumettre',
            cancelButton: 'Annuler'
          },
          createQuoteClient: {
            pageTitle: 'Nouvelle Demande de Devis - Client',
            header: 'Créer une Demande de Devis',
            descriptionLabel: 'Description:',
            serviceLabel: 'Service:',
            selectService: 'Sélectionner un Service',
            dateLabel: 'Date:',
            timeLabel: 'Heure:',
            submitButton: 'Soumettre',
            cancelButton: 'Annuler'
          },
          updateAppointmentAdmin: {
            pageTitle: 'Mise à Jour Des Rendez-Vous - CTA',
            header: 'Mise à Jour du Rendez-Vous Pour',
            employeeLabel: 'Sélectionnez un Employé:',
            employeeInputPlace: 'Filtrer Les Employés Par Nom',
            selectEmployee: 'Pas D\'Employé Assigné',
            descriptionLabel: 'Description:',
            serviceLabel: 'Service:',
            selectService: 'Sélectionner un Service',
            dateLabel: 'Date:',
            timeLabel: 'Heure:',
            statusLabel: 'Statut:',
            selectStatus: 'Modifier Statut',
            updateButton: 'Confirmer',
            cancelButton: 'Annuler'
          },
          updateAppointmentClient: {
            pageTitle: 'Mise à Jour Des Rendez-Vous - Client',
            header: 'Mise à Jour du Rendez-Vous',
            descriptionLabel: 'Description:',
            serviceLabel: 'Service:',
            selectService: 'Sélectionner un Service',
            dateLabel: 'Date:',
            timeLabel: 'Heure:',
            updateButton: 'Confirmer',
            cancelButton: 'Annuler'
          },
          updateAppointmentEmployee: {
            pageTitle: 'Mise à Jour Des Rendez-Vous - CTA',
            header: 'Mise à Jour du Rendez-Vous',
            priceLabel: 'Prix:',
            statusLabel: 'Statut:',
            selectStatus: 'Modifier Statut',
            updateButton: 'Confirmer',
          },
          updateClientAdmin: {
            pageTitle: 'Mise à Jour du Client - CTA',
            header: 'Mise à Jour du Client',
            firstName: 'Prénom:',
            lastName: 'Nom:',
            email: 'Courriel:',
            phone: 'Téléphone:',
            address: 'Adresse:',
            updateButton: 'Confirmer'
          },
          updateQuoteAdmin: {
            pageTitle: 'Mise à jour de la Demande de Devis - CTA',
            header: 'Mise à jour de la Demande de Devis',
            employeeLabel: 'Sélectionnez un Employé:',
            employeeInputPlace: 'Filtrer Les Employés Par Nom',
            selectEmployee: 'Pas D\'Employé Assigné',
            descriptionLabel: 'Description:',
            serviceLabel: 'Service:',
            selectService: 'Sélectionner un Service',
            dateLabel: 'Date:',
            timeLabel: 'Heure:',
            statusLabel: 'Statut:',
            selectStatus: 'Modifier Statut',
            updateButton: 'Confirmer',
            cancelButton: 'Annuler'
          },
          updateQuoteClient: {
            pageTitle: 'Mise à jour de la Demande de Devis - Client',
            header: 'Mise à jour de la Demande de Devis',
            descriptionLabel: 'Description:',
            serviceLabel: 'Service:',
            dateLabel: 'Date:',
            timeLabel: 'Heure:',
            updateButton: 'Confirmer',
            cancelButton: 'Annuler'
          },
          updateQuoteEmployee: {
            pageTitle: 'Mise à jour de la Demande de Devis - CTA',
            header: 'Mise à jour de la Demande de Devis',
            priceLabel: 'Prix',
            statusLabel: 'Statut:',
            selectStatus: 'Modifier Statut',
            updateButton: 'Confirmer',
          },
          invoicesAdmin: {
            pageTitle: 'Factures - CTA',
            header: 'Gestion des Factures',
            deleteConfirmation: 'Êtes-vous sûr de vouloir supprimer cette facture?',
            table: {
              firstName: 'Prénom',
              lastName: 'Nom',
              time: 'Heure',
              date: 'Date',
              total: 'Total',
              service: 'Service',
              deleteButton: 'Surprimmer'
            },
            detailsTable: {
              invoiceId: 'Id de la facture',
              appointmentId: 'Id du rendez-vous',
              clientId: 'Id',
              clientName: 'Nom complet:',
              time: 'Heure',
              date: 'Date',
              subTotal: 'Sous-total',
              total: 'Total',
              description: 'Description',
              address: 'Addresse',
              service: 'Service'
            },
            serviceEnum: {
              HedgeTrimming: 'Taille des Haies',
              TreeTrimming: 'Taille des Arbres',
              TreeBranchRemoval: `Enlèvement des Branches d'Arbres`,
              SmallTreeRemoval: 'Enlèvement des Petits Arbres',
              HedgeTrimmingAndTreeTrimming: `Taille de Haies et d'Arbres`,
              HedgeTrimmingAndTreeBranchRemoval: `Taille des Haies et Enlèvement des Branches d'Arbres`,
              TreeTrimmingAndTreeBranchRemoval: 'Taille des Arbres et Enlèvement des Branches',
              HedgeTrimmingAndTreeTrimmingAndTreeBranchRemoval: `Taille de Haies, Taille d'Arbres et Enlèvement de Branches d'Arbres`,
              HedgeTrimmingAndSmallTreeRemoval: 'Taille de Haies et Enlèvement de Petits Arbres',
              TreeTrimmingAndSmallTreeRemoval: 'Taille des Arbres et Enlèvement des Petits Arbres',
              HedgeTrimmingAndTreeTrimmingAndSmallTreeRemoval: `Taille de Haies, Taille d'Arbres et Enlèvement de Petits Arbres`,
              HedgeTrimmingAndTreeBranchRemovalAndSmallTreeRemoval: `Taille de Haies, Enlèvement de Branches d'Arbres et Enlèvement de Petits Arbres`,
              TreeTrimmingAndTreeBranchRemovalAndSmallTreeRemoval: 'Taille des Arbres, Enlèvement des Branches et Enlèvement des Petits Arbres',
              HedgeTrimmingAndTreeTrimmingAndTreeBranchRemovalAndSmallTreeRemoval: `Taille de Haies et Taille d'Arbres et Enlèvement de Branches d'Arbres et Enlèvement de Petits Arbres`
            },
            closeButton: 'Fermer',
            deleteButton: 'Supprimer',
            viewButton: 'Voir la facture'
          }
        }
      }
    }
  });

export default i18n;