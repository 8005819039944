  import React, { useState, useEffect } from 'react';
  import Helmet from 'react-helmet';
  import axios from 'axios';
  import DatePicker from 'react-datepicker';
  import 'react-datepicker/dist/react-datepicker.css';
  import TimePicker from 'rc-time-picker';
  import 'rc-time-picker/assets/index.css';
  import moment from 'moment';
  import ClientNavbar from '../components/ClientNavbar';
  import { useParams } from 'react-router-dom';
  import '../styles/CreateQuoteRequest.css';
  import { useAuth } from '../security/Components/AuthProvider';
  import { useTranslation } from 'react-i18next';

  const format = 'h:mm a';

  function CreateFeedBackClient() {
    const auth = useAuth();
    const { t, i18n } = useTranslation();

    const [existingFeedBacks, setExistingFeedBacks] = useState([]);
    const [formData, setFormData] = useState({
      ratings: 1.0,
      title: '',
      comments: '',
      service: '',
    });
    const [approvedAppointments, setApprovedAppointments] = useState([]);

    const [clientAcceptedAppoitnment, setclientAcceptedAppoitnments] = useState([]);
    const [selectedAppointmentId, setSelectedAppointmentId] = useState('');
    const [hasProfile, setHasProfile] = useState(false);

    useEffect(() => {
      if (!auth.isAuthenticated) {
        window.location.href = 'http://www.coupetonarbre.live/client/feedbacks';
      }

      checkIfProfileExists();
      fetchApprovedAppointments();
    }, []);

    const checkIfProfileExists = async () => {
      try {
        const response = await axios.get('https://api.coupetonarbre.live/users/client?simpleCheck=true', {
          headers: {
            'X-XSRF-TOKEN': auth.getXsrfToken(),
          },
        });

        if (response.status === 200) {
          setHasProfile(true);
        } else {
          setHasProfile(false);
          window.location.href = 'http://www.coupetonarbre.live/profile';
        }
      } catch (error) {
        console.error('Error checking profile:', error);
        setHasProfile(false);
        window.location.href = 'http://www.coupetonarbre.live/profile';
      }
    };

    const fetchApprovedAppointments = async () => {
      try {
        const response = await axios.get('https://api.coupetonarbre.live/appointments/approved/client', {
          headers: {
            'X-XSRF-TOKEN': auth.getXsrfToken(),
          },
        });

        setApprovedAppointments(response.data);
      } catch (error) {
        console.error('Error fetching approved appointments:', error);
      }
    };

    const renderRatingOptions = () => {
      const ratings = [1.0, 1.5, 2.0, 2.5, 3.0, 3.5, 4.0, 4.5, 5.0];

      return ratings.map((value) => (
        <option key={value} value={value}>
          {value} <StarRating />
        </option>
      ));
    };

    const StarRating = () => {
      return <span className="star-filled">★</span>;
    };

    const handleSubmit = (event) => {
      event.preventDefault();
    
      // Find the selected appointment object
      const selectedAppointment = approvedAppointments.find(
        (appointment) => appointment.appointmentId === selectedAppointmentId
      );
    
      if (!selectedAppointment) {
        console.error('Selected appointment not found');
        return;
      }
    
      const requestBody = {
        appointmentId: selectedAppointmentId,
        date: moment(selectedAppointment.date).format('YYYY-MM-DDTHH:mm:ss'),
        ratings: formData.ratings,
        title: formData.title,
        comments: formData.comments,
        service: formData.service,
      };
    
      axios
        .post('https://api.coupetonarbre.live/feedbacks/client', requestBody, {
          headers: {
            'X-XSRF-TOKEN': auth.getXsrfToken(),
          },
        })
        .then((response) => {
          console.log(response.data);
          window.location.href = 'http://www.coupetonarbre.live/client/feedbacks';
        })
        .catch((error) => {
          console.error('Error:', error);
        });
    };
    
    useEffect(() => {
      if (selectedAppointmentId) {
        const selectedAppointment = approvedAppointments.find(
          (appointment) => appointment.appointmentId === selectedAppointmentId
        );
  
        if (selectedAppointment) {
          fetchServiceForDate(moment(selectedAppointment.date).format('YYYY-MM-DD'));
  
          setFormData({
            ...formData,
            service: selectedAppointment.service,
            date: moment(selectedAppointment.date).format('YYYY-MM-DDTHH:mm:ss'),
          });
        }
      }
    }, [selectedAppointmentId, approvedAppointments]);
    const renderAppointmentOptions = () => {
      return approvedAppointments.map(appointment => (
        <option key={appointment.appointmentId} value={appointment.appointmentId}>
          {moment(appointment.date).format('YYYY-MM-DD')}
        </option>
      ));
    };
    

    
    const renderServiceOptions = () => {
      // Check if a valid appointment is selected
      const selectedAppointment = approvedAppointments.find(
        (appointment) => appointment.appointmentId === selectedAppointmentId
      );
    
      if (selectedAppointment) {
        // Convert camelCase to spaced words with the first letter of each word capitalized
        const serviceValue = selectedAppointment.service.replace(/([a-z])([A-Z])/g, '$1 $2')
                                                          .replace(/\b\w/g, c => c.toUpperCase());
    
        // Render only the service corresponding to the selected appointment
        return (
          <option key={serviceValue} value={serviceValue}>
            {serviceValue}
          </option>
        );
      }
    
      // If no valid appointment is selected, render a default option
      return (
        <option value="" disabled>Select a Service</option>
      );
    };
    

    const handleInputChange = async (name, value) => {
      if (name === 'appointment') {
        const selectedAppointment = approvedAppointments.find(
          (appointment) => appointment.appointmentId === value
        );
    
        if (selectedAppointment) {
          await fetchServiceForDate(
            moment(selectedAppointment.date).format('YYYY-MM-DD')
          );
    
          setFormData({
            ...formData,
            service: selectedAppointment.service,
            [name]: value,
            date: moment(selectedAppointment.date).format('YYYY-MM-DDTHH:mm:ss'),
          });
        } else {
          setFormData({
            ...formData,
            service: '',
            [name]: value,
            date: '',
          });
        }
    
        setSelectedAppointmentId(value);
      } else {
        setFormData({
          ...formData,
          [name]: name === 'ratings' ? parseFloat(value) : value,
          date: name === 'date' ? moment(value).format('YYYY-MM-DDTHH:mm:ss') : formData.date,
        });
    
        if (name === 'service') {
          const selectedAppointment = approvedAppointments.find(
            (appointment) => appointment.appointmentId === selectedAppointmentId
          );
    
          if (selectedAppointment && selectedAppointment.service !== value) {
            setFormData((prevFormData) => ({
              ...prevFormData,
              service: selectedAppointment.service,
            }));
          }
        }
      }
    };
    
    // ...
    
    const fetchServiceForDate = async (selectedDate) => {
      try {
        const response = await axios.get(`https://api.coupetonarbre.live/appointments/service`, {
          params: {
            date: selectedDate,
          },
          headers: {
            'X-XSRF-TOKEN': auth.getXsrfToken(),
          },
        });
    
        if (response.status === 200) {
          const service = response.data.service;
          setFormData(prevFormData => ({ ...prevFormData, service }));
        } else {
          console.error('Error fetching service for the selected date');
        }
      } catch (error) {
        console.error('Error fetching service:', error);
      }
    };

    const handleCancel = () => {
      window.location.href = 'http://www.coupetonarbre.live/client/feedbacks';
    };

    return (
      <div className='content'>
        <Helmet>
          <title>{t('createFeedbackClient.pageTitle')}</title>
        </Helmet>
        <div id='nav-container'>
          <ClientNavbar />

          <div className='text-align center'>
            <h1 className='heading'>{t('createFeedbackClient.header')}</h1>
          </div>

          <div>
            <form onSubmit={handleSubmit} className='form-styles'>
            <label className='form-label'>{t('createFeedbackClient.appointmentLabel')}</label>
            <select
              name="appointment"
              className='form-select'
              value={selectedAppointmentId}
              onChange={(e) => setSelectedAppointmentId(e.target.value)}
              required
            >
              <option value="" disabled>{t('createFeedbackClient.selectAppointment')}</option>
              {renderAppointmentOptions()}
            </select>
            <label className='form-label'>{t('createFeedbackClient.serviceLabel')}</label>
                        <select name="service" className='form-select' value={formData.service} onChange={(e) => handleInputChange('service', e.target.value)} required>
              <option value="" disabled>{t('createFeedbackClient.selectService')}</option>
              {renderServiceOptions()}
            </select>

              <label className='form-label'>{t('createFeedbackClient.titleLabel')}</label>
              <input type="text" name="title" className='form-input' value={formData.title} onChange={(e) => handleInputChange('title', e.target.value)} required />

              <label className='form-label'>{t('createFeedbackClient.ratingLabel')}</label>
              <select
                name="ratings"
                className='form-select'
                value={formData.ratings}
                onChange={(e) => handleInputChange('ratings', parseFloat(e.target.value))}
                required
              >
                {renderRatingOptions()}
              </select>

              <label className='form-label'>{t('createFeedbackClient.commentLabel')}</label>
              <input type="text" name="comments" className='form-input' value={formData.comments} onChange={(e) => handleInputChange('comments', e.target.value)} required />

              <button className='form-button' id='submit-btn' type="submit">{t('createFeedbackClient.submitButton')}</button>
              <button className='form-button' type='button' onClick={handleCancel}>{t('createFeedbackClient.cancelButton')}</button>

            </form>
          </div>
        </div>
      </div>
    );
  }

  export default CreateFeedBackClient;
