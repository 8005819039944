import React, { useState, useEffect } from 'react';
import Helmet from 'react-helmet';
import axios from 'axios';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import TimePicker from 'rc-time-picker';
import 'rc-time-picker/assets/index.css';
import moment from 'moment'; 
import ClientNavbar from '../components/ClientNavbar';
import { useParams } from 'react-router-dom';
import '../styles/CreateQuoteRequest.css';
import { useAuth } from '../security/Components/AuthProvider';
import { useTranslation } from 'react-i18next';

const format = 'h:mm A';


function CreateAppointmentClient() {

  const auth = useAuth();
  const { t, i18n } = useTranslation();

  //storing the existing quote requests (Dates)
  const [existingAppointments, setExistingAppointments] = useState([]);
  const [clientAcceptedQuotes, setClientAcceptedQuotes] = useState([]);
  const [selectedQuoteId, setSelectedQuoteId] = useState('');
  const [selectedQuoteDetails, setSelectedQuoteDetails] = useState(null);

  //storing the data from the form
  const [formData, setFormData] = useState({
    description: '',
    service: '',
    date: new Date(),
    time: new Date(),
  });

  const clarityServiceMapping = {
    'HedgeTrimming': 'Hedge Trimming',
    'TreeTrimming': 'Tree Trimming',
    'TreeBranchRemoval': 'Tree Branch Removal',
    'SmallTreeRemoval': 'Small Tree Removal',
    'HedgeTrimmingAndTreeTrimming': 'Hedge Trimming and Tree Trimming',
    'HedgeTrimmingAndTreeBranchRemoval': 'Hedge Trimming and Tree Branch Removal',
    'TreeTrimmingAndTreeBranchRemoval': 'Tree Trimming and Tree Branch Removal',
    'HedgeTrimmingAndTreeTrimmingAndTreeBranchRemoval': 'Hedge Trimming and Tree Trimming and Tree Branch Removal',
    'HedgeTrimmingAndSmallTreeRemoval': 'Hedge Trimming and Small Tree Removal',
    'TreeTrimmingAndSmallTreeRemoval': 'Tree Trimming and Small Tree Removal',
    'HedgeTrimmingAndTreeTrimmingAndSmallTreeRemoval': 'Hedge Trimming and Tree Trimming and Small Tree Removal',
    'HedgeTrimmingAndTreeBranchRemovalAndSmallTreeRemoval': 'Hedge Trimming and Tree Branch Removal and Small Tree Removal',
    'TreeTrimmingAndTreeBranchRemovalAndSmallTreeRemoval': 'Tree Trimming and Tree Branch Removal and Small Tree Removal',
    'HedgeTrimmingAndTreeTrimmingAndTreeBranchRemovalAndSmallTreeRemoval': 'Hedge Trimming and Tree Trimming and Tree Branch Removal and Small Tree Removal'
};

  //Mapping the enum names to better named options
  const serviceOptionsMapping = {
    'Hedge Trimming': 'HedgeTrimming',
    'Tree Trimming': 'TreeTrimming',
    'Tree Branch Removal': 'TreeBranchRemoval',
    'Small Tree Removal': 'SmallTreeRemoval',
    'Hedge Trimming and Tree Trimming': 'HedgeTrimmingAndTreeTrimming',
    'Hedge Trimming and Tree Branch Removal': 'HedgeTrimmingAndTreeBranchRemoval',
    'Tree Trimming and Tree Branch Removal': 'TreeTrimmingAndTreeBranchRemoval',
    'Hedge Trimming and Tree Trimming and Tree Branch Removal': 'HedgeTrimmingAndTreeTrimmingAndTreeBranchRemoval',
    'Hedge Trimming and Small Tree Removal': 'HedgeTrimmingAndSmallTreeRemoval',
    'Tree Trimming and Small Tree Removal': 'TreeTrimmingAndSmallTreeRemoval',
    'Hedge Trimming and Tree Trimming and Small Tree Removal': 'HedgeTrimmingAndTreeTrimmingAndSmallTreeRemoval',
    'Hedge Trimming and Tree Branch Removal and Small Tree Removal': 'HedgeTrimmingAndTreeBranchRemovalAndSmallTreeRemoval',
    'Tree Trimming and Tree Branch Removal and Small Tree Removal': 'TreeTrimmingAndTreeBranchRemovalAndSmallTreeRemoval',
    'Hedge Trimming and Tree Trimming and Tree Branch Removal and Small Tree Removal': 'HedgeTrimmingAndTreeTrimmingAndTreeBranchRemovalAndSmallTreeRemoval',
  };

  const [hasProfile, setHasProfile] = useState(false);
  const [profile, setProfile] = useState(false);


  //Check if user is logged in and has profile
  useEffect(() => {
    // @ts-ignore
    if (!auth.isAuthenticated) {
      window.location.href = 'http://www.coupetonarbre.live/quoteRequests/client';
    }

    checkIfProfileExists().then((r) => {
      console.log(r);
    });
  }, []);

  const checkIfProfileExists = async () => {
    //check if user exists
    await axios
      .get('https://api.coupetonarbre.live/users/client?simpleCheck=true', {
        headers: {
          // @ts-ignore
          'X-XSRF-TOKEN': auth.getXsrfToken(),
        },
      })
      .then((r) => {
        console.log(r.status);
        if (r.status === 200) {
          setHasProfile(true);
          console.log('Does he have a profile? ' + hasProfile);
          //check if user has profile
          axios
            .get('https://api.coupetonarbre.live/users/client', {
              headers: {
                // @ts-ignore
                'X-XSRF-TOKEN': auth.getXsrfToken(),
              },
            })
            .then((r) => {
              if (r.status === 200) {
                setProfile(r.data);
              }
            })
            .catch((e) => {
              console.log(e);
            });
        } else {
          //redirect to create profile page
          setHasProfile(false);
          window.location.href = 'http://www.coupetonarbre.live/profile';

        }
      })
      .catch((e) => {
        //redirect to create profile page
        console.log(e);
        setHasProfile(false);
        window.location.href = 'http://www.coupetonarbre.live/profile';

        
      });
  };


  useEffect(() => {
    // Fetching existing appointments dates
    axios.get("https://api.coupetonarbre.live/appointments/dates", {
      headers: {
        // @ts-ignore
        'X-XSRF-TOKEN': auth.getXsrfToken()
      }
    })
      .then(response => {
        setExistingAppointments(response.data);
      })
      .catch(error => {
        console.error('Error fetching existing appointments:', error);
      });
  }, []);

  useEffect(() => {
    // Fetching existing accepted quote requests
    axios.get("https://api.coupetonarbre.live/quoteRequests/approved/client", {
      headers: {
        // @ts-ignore
        'X-XSRF-TOKEN': auth.getXsrfToken()
      }
    })
      .then(response => {
        setClientAcceptedQuotes(response.data);
      })
      .catch(error => {
        console.error('Error fetching approved quote requests:', error);
      });
  }, []);

    //gets the selected quote and stores its details
    useEffect(() => {
      if (selectedQuoteId) {
        axios.get(`https://api.coupetonarbre.live/quoteRequests/${selectedQuoteId}/client`, {
          headers: {
            // @ts-ignore
            'X-XSRF-TOKEN': auth.getXsrfToken()
          }
        })
          .then(response => {
            setSelectedQuoteDetails(response.data);
            console.log("hello")
          })
          .catch(error => {
            console.error('Error:', error);
            console.log("error")
  
          });
      }
    }, [selectedQuoteId]);


  //creating a new quote request
  const handleSubmit = (event) => {
    event.preventDefault();

    formData.service = selectedQuoteDetails.service

    const requestBody = {
      quoteRequestId : selectedQuoteId,
      ...formData,

    };

      const email = {
        recipient: profile.email, 
        subject: "Scheduled Appointment",
        message: `Hello, ${profile.firstName}!\n\nAn appointment has been scheduled for:\n\nDate: ${moment(formData.date).format('MMMM DD, YYYY')}\nTime: ${formData.time}\nAddress: ${profile.address}\n\nDescription: ${selectedQuoteDetails.description}\nService: ${clarityServiceMapping[formData.service]}\nThank you for choosing us!`
      };

      axios.post('https://api.coupetonarbre.live/appointments/client', requestBody, {
      headers: {
          // @ts-ignore
          'X-XSRF-TOKEN': auth.getXsrfToken()
       }
      })
      .then(response => {
        console.log(response.data);

        axios.post('https://api.coupetonarbre.live/email', email, {
        headers: {
          // @ts-ignore
          'X-XSRF-TOKEN': auth.getXsrfToken()
          }
        }
      )

        window.location.href = 'http://www.coupetonarbre.live/client/appointments';

      })
      .catch(error => {
        console.error('Error:', error);
      });
  };


  //Dropdown for quotes
  const renderQuoteOptions = () => {
    return clientAcceptedQuotes.map(quote => (
      <option key={quote.quoteRequestId} value={quote.quoteRequestId}>
        {quote.description} 
      </option>
    ));
  };



    //handles when the client is changed to change the selected client
    const handleQuoteChange = (event) => {
        const quoteId = event.target.value;
        setSelectedQuoteId(quoteId);
      };

    
  // handling the information changed in the form
  const handleInputChange = (name, value) => {
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // handling if time was changed

  const handleTimeChange = (value) => {
    setFormData({
      ...formData,
      time: value && value.format(format),
    });
  };

  //making sure day is valid
  const isDateValid = (date) => {
    return moment(date).isSameOrAfter(moment(), 'day');
  };

//returning an array of hours that should be disabled based on day of week
  const closedHours = () => {
    const dayOfWeek = moment(formData.date).day();


    //weekdays
    if (dayOfWeek >= 1 && dayOfWeek <= 5) {
      return [0, 1, 2, 3, 4, 5, 6, 7, 20, 21, 22, 23];
    }
    //saturday
     else if (dayOfWeek === 6) {
      return [0, 1, 2, 3, 4, 5, 6, 7, ,8,18, 19, 20, 21, 22, 23];
    } 
    //sunday
    else if (dayOfWeek === 0) {
      return [0, 1, 2, 3, 4, 5, 6, 7, 8, 17, 18, 19, 20, 21, 22, 23];
    }

    return false;
  };

 //returning the time slots that are already taken for that day
  const isTimeTaken = () => {

    // day chosen in form
    const chosenDay = moment(formData.date).dayOfYear();
    //year chosen in form
    const chosenYear = moment(formData.date).year();

  
    // Initialize the array to store taken hours and minutes
    const takenTimeSlots = [];
  
    // Iterate through all existing quote requests
    existingAppointments.forEach(request => {
      const requestYear = moment(request.date).year();

      // storing day from existing quote request
      let requestDay = moment(request.date).dayOfYear();
      requestDay += 1; // Adjust day because dayOfYear is 1-based

      //storing time from existing quote request
      const requestTime = moment(request.time, format);
  
      //Checking if date chosen from user is a day that has existing quote requests during it
      if (chosenDay === requestDay && chosenYear === requestYear) {
  
        // Add the taken hour and minute to the array
        takenTimeSlots.push({
          hour: requestTime.hour(),
          minute: requestTime.minute(),
        });
      }
    });
  
  
    return takenTimeSlots;
  };


  const handleCancel = () =>{
    window.location.href = 'http://www.coupetonarbre.live/client/appointments';

  }
  
  return (
    <div className='content'>
      <Helmet>
        <title>{t('createAppointmentClient.pageTitle')}</title>
      </Helmet>
      <div id='nav-container'>
        <ClientNavbar />

        <div className='text-align center'>
          <h1 className='heading'>{t('createAppointmentClient.header')}</h1>
        </div>


        <div>
          <form onSubmit={handleSubmit} className='form-styles'>
           

            <br></br>
            <label className='form-label'>{t('createAppointmentClient.quoteLabel')}</label>
            <select className='form-select' id='quote-select' value={selectedQuoteId} onChange={handleQuoteChange}>
              <option value="" >{t('createAppointmentClient.selectQuote')}</option>
              {renderQuoteOptions()}
            </select>

           
            <label className='form-label'>{t('createAppointmentClient.dateLabel')}</label>
            <DatePicker
              className='form-date'
              selected={formData.date}
              onChange={(date) => handleInputChange('date', date)}
              minDate={moment()}
              filterDate={isDateValid}
              //winter (no work)
              excludeDateIntervals={[
                { start: new Date("2024-11-1"), end: new Date("2025-04-1") },
                { start: new Date("2024-01-1"), end: new Date("2024-04-1") },

              ]}
              //holidays
              excludeDates={[new Date("2024-4-1"), new Date("2024-7-1"),new Date("2024-10-15"),new Date("2024-9-2") ]}

            />

            <label className='form-label'>{t('createAppointmentClient.timeLabel')}</label>
            <TimePicker
              showSecond={false}            
              onChange={handleTimeChange}
              format={format}
              use12Hours
              className='form-time'
              allowEmpty={false}
              min={moment().hours(9).minutes(0)}
              max={moment().hours(19).minutes(59)}
              minuteStep={60}

             //disabling hours that CTA is closed
              disabledHours={() => closedHours()}
              
              //disabling time slots that are already taken
              disabledMinutes={(hour) => isTimeTaken().filter(slot => slot.hour === hour).map(slot => slot.minute)}
              disabledSeconds={() => []}

            />

            <button className='form-button' id='form-submit' type="submit">{t('createAppointmentClient.submitButton')}</button>
            <button className='form-button' type='button' onClick={handleCancel}>{t('createAppointmentClient.cancelButton')}</button>

          </form>
        </div>
      </div>
    </div>
  );
}

export default CreateAppointmentClient;
