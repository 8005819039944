import React, { useState, useEffect } from 'react';
import Helmet from 'react-helmet';
import axios from 'axios';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import TimePicker from 'rc-time-picker';
import 'rc-time-picker/assets/index.css';
import moment from 'moment';
import ClientNavbar from '../components/ClientNavbar';
import { useParams } from 'react-router-dom';
import '../styles/CreateQuoteRequest.css';
import { useAuth } from '../security/Components/AuthProvider';
import { useTranslation } from 'react-i18next';


const UpdateAppointmentClient = () => {
  const { t, i18n } = useTranslation();
    const format = 'h:mm A';
    const auth = useAuth();
    const { appointmentId } = useParams();
    const [quoteRequestId, setQuoteRequestId] = useState('');
    const [appointmentStatus, setAppointmentStatus] = useState('');

    const [selectedEmployeeId, setSelectedEmployeeId] = useState('');
    const [selectedEmployeeDetails, setSelectedEmployeeDetails] = useState(null);
    const [profile, setProfile] = useState(false);


    // Storing the existing appointment request data for pre-filling the form
    const [existingAppointmentData, setExistingAppointmentData] = useState(null);
    const [appointmentPrice, setPrice] = useState('');
    const [clientAddress, setClientAddress] = useState('');
    const [existingAppointments, setExistingAppointments] = useState([]);

    // Storing the data from the form
    const [formData, setFormData] = useState({
        description: '',
        service: '',
        date: new Date(),
        time: new Date(),
    });

    useEffect(() => {
      axios.get('https://api.coupetonarbre.live/users/client', {
               headers: {
                 // @ts-ignore
                 'X-XSRF-TOKEN': auth.getXsrfToken(),
               },
             })
             .then((r) => {
               if (r.status === 200) {
                 setProfile(r.data);
               }
             })
             .catch((e) => {
               console.log(e);
             });
    },[])

    useEffect(() => {
        // Fetching the existing appointment data for the given appointmentId
        axios
          .get(`https://api.coupetonarbre.live/appointments/${appointmentId}/client`, {
            headers: {
              'X-XSRF-TOKEN': auth.getXsrfToken(),
            },
          })
          .then((response) => {
            const existingData = response.data;
            setExistingAppointmentData(existingData);
            setClientAddress(existingData.address)
            setPrice(existingData.price)
            setQuoteRequestId(existingData.quoteRequestId)
            setAppointmentStatus(existingData.status)
            // Set the form data based on the existing data
            setFormData({
              description: existingData.description,
              service: existingData.service,
              date: new Date(existingData.date),
              time: moment(existingData.time, format).format(format),
            });
          })
          .catch((error) => {
            console.error('Error fetching existing appointment data:', error);
          });
    }, [auth, appointmentId]);

    useEffect(() => {
      if (selectedEmployeeId) {
      axios.get(`https://api.coupetonarbre.live/users/employees/${selectedEmployeeId}`, {
          headers: {
          // @ts-ignore
          'X-XSRF-TOKEN': auth.getXsrfToken()
          }
      })
          .then(response => {
          setSelectedEmployeeDetails(response.data);
          })
          .catch(error => {
          console.error('Error:', error);
          });
      }
    }, [selectedEmployeeId]);

    const serviceOptionsMappingEN = {
        'Hedge Trimming': 'HedgeTrimming',
        'Tree Trimming': 'TreeTrimming',
        'Tree Branch Removal': 'TreeBranchRemoval',
        'Small Tree Removal': 'SmallTreeRemoval',
        'Hedge Trimming and Tree Trimming': 'HedgeTrimmingAndTreeTrimming',
        'Hedge Trimming and Tree Branch Removal': 'HedgeTrimmingAndTreeBranchRemoval',
        'Tree Trimming and Tree Branch Removal': 'TreeTrimmingAndTreeBranchRemoval',
        'Hedge Trimming and Tree Trimming and Tree Branch Removal': 'HedgeTrimmingAndTreeTrimmingAndTreeBranchRemoval',
        'Hedge Trimming and Small Tree Removal': 'HedgeTrimmingAndSmallTreeRemoval',
        'Tree Trimming and Small Tree Removal': 'TreeTrimmingAndSmallTreeRemoval',
        'Hedge Trimming and Tree Trimming and Small Tree Removal': 'HedgeTrimmingAndTreeTrimmingAndSmallTreeRemoval',
        'Hedge Trimming and Tree Branch Removal and Small Tree Removal': 'HedgeTrimmingAndTreeBranchRemovalAndSmallTreeRemoval',
        'Tree Trimming and Tree Branch Removal and Small Tree Removal': 'TreeTrimmingAndTreeBranchRemovalAndSmallTreeRemoval',
        'Hedge Trimming and Tree Trimming and Tree Branch Removal and Small Tree Removal': 'HedgeTrimmingAndTreeTrimmingAndTreeBranchRemovalAndSmallTreeRemoval'
    };
    const clarityServiceMapping = {
      'HedgeTrimming': 'Hedge Trimming',
      'TreeTrimming': 'Tree Trimming',
      'TreeBranchRemoval': 'Tree Branch Removal',
      'SmallTreeRemoval': 'Small Tree Removal',
      'HedgeTrimmingAndTreeTrimming': 'Hedge Trimming and Tree Trimming',
      'HedgeTrimmingAndTreeBranchRemoval': 'Hedge Trimming and Tree Branch Removal',
      'TreeTrimmingAndTreeBranchRemoval': 'Tree Trimming and Tree Branch Removal',
      'HedgeTrimmingAndTreeTrimmingAndTreeBranchRemoval': 'Hedge Trimming and Tree Trimming and Tree Branch Removal',
      'HedgeTrimmingAndSmallTreeRemoval': 'Hedge Trimming and Small Tree Removal',
      'TreeTrimmingAndSmallTreeRemoval': 'Tree Trimming and Small Tree Removal',
      'HedgeTrimmingAndTreeTrimmingAndSmallTreeRemoval': 'Hedge Trimming and Tree Trimming and Small Tree Removal',
      'HedgeTrimmingAndTreeBranchRemovalAndSmallTreeRemoval': 'Hedge Trimming and Tree Branch Removal and Small Tree Removal',
      'TreeTrimmingAndTreeBranchRemovalAndSmallTreeRemoval': 'Tree Trimming and Tree Branch Removal and Small Tree Removal',
      'HedgeTrimmingAndTreeTrimmingAndTreeBranchRemovalAndSmallTreeRemoval': 'Hedge Trimming and Tree Trimming and Tree Branch Removal and Small Tree Removal'
  };

    const serviceOptionsMappingFR = {
      'Taille des Haies': 'Taille des Haies',
      'Taille des Arbres': 'Taille des Arbres',
      'Enlèvement des Branches d\'Arbre' : 'Enlèvement des Branches d\'Arbres',
      'Enlèvement des Petits Arbres': 'Enlèvement des Petits Arbres',
      'Taille de Haies et d\'Arbres': 'Taille de Haies et d\'Arbres',
      'Taille des Haies et Enlèvement des Branches d\'Arbres': 'Taille des Haies et Enlèvement des Branches d\'Arbres',
      'Taille des Arbres et Enlèvement des Branches': 'Taille des Arbres et Enlèvement des Branches',
      'Taille de Haies, Taille d\'Arbres et Enlèvement de Branches d\'Arbres': 'Taille de Haies, Taille d\'Arbres et Enlèvement de Branches d\'Arbres',
      'Taille de Haies et Enlèvement de Petits Arbres': 'Taille de Haies et Enlèvement de Petits Arbres',
      'Taille des Arbres et Enlèvement des Petits Arbres': 'Taille des Arbres et Enlèvement des Petits Arbres',
      'Taille de Haies, Taille d\'Arbres et Enlèvement de Petits Arbres': 'Taille de Haies, Taille d\'Arbres et Enlèvement de Petits Arbres',
      'Taille de Haies, Enlèvement de Branches d\'Arbres et Enlèvement de Petits Arbres': 'Taille de Haies, Enlèvement de Branches d\'Arbres et Enlèvement de Petits Arbres',
      'Taille des Arbres, Enlèvement des Branches et Enlèvement des Petits Arbres': 'Taille des Arbres, Enlèvement des Branches et Enlèvement des Petits Arbres',
      'Taille de Haies et Taille d\'Arbres et Enlèvement de Branches d\'Arbres et Enlèvement de Petits Arbres': 'Taille de Haies et Taille d\'Arbres et Enlèvement de Branches d\'Arbres et Enlèvement de Petits Arbres'
    }

    const renderServiceOptions = () => {
      const currentLanguage = i18n.language;
      let serviceOptions;
      let serviceMapping;

      // Determine which service mapping to use based on the current language
      if (currentLanguage === 'fr') {
        serviceOptions = Object.keys(serviceOptionsMappingFR);
        serviceMapping = serviceOptionsMappingFR;
      } else {
        serviceOptions = Object.keys(serviceOptionsMappingEN);
        serviceMapping = serviceOptionsMappingEN;
      }

      return serviceOptions.map(option => (
        <option key={option} value={serviceMapping[option]}>
          {option}
        </option>
      ));
    };
    
    const handleUpdate = (event) => {
      event.preventDefault();

      const newDate = moment(formData.date).dayOfYear();

      const originalDate = moment(existingAppointmentData.date).dayOfYear();
      const newYear = moment(formData.date).year();
      const originalYear = moment(existingAppointmentData.date).year();
      console.log(newDate)
      console.log(originalDate)
      if (newDate > originalDate || newYear > originalYear) {
        // Send email notification
        const email = {
          recipient: profile.email,
          subject: "Appointment Postponed",
          message: `Hello, ${existingAppointmentData.clientFirstName}!\n\nYour appointment has been postponed.\n\nDescription: ${formData.description}\nService: ${clarityServiceMapping[formData.service]}\n\nThe new scheduled date is: ${moment(formData.date).format('MMMM DD, YYYY')} at ${moment(formData.time, format).format(format)}.\n\nThank you for your understanding.`,
        };
      
        axios.post('https://api.coupetonarbre.live/email', email, {
          headers: {
            'X-XSRF-TOKEN': auth.getXsrfToken(),
          },
        })
          .then(response => {
            console.log('Email notification sent:', response.data);
          })
          .catch(error => {
            console.error('Error sending email notification:', error);
          });
      }
  
      const requestBody = {
        quoteRequestId: quoteRequestId,
        address: clientAddress,
        price: appointmentPrice,
        status: appointmentStatus,
        ...formData,
      };
  
      axios
        .put(`https://api.coupetonarbre.live/appointments/${appointmentId}/client`, requestBody, {
          headers: {
            'X-XSRF-TOKEN': auth.getXsrfToken(),
          },
        })
        .then((response) => {
          console.log(response.data);
          window.location.href = 'http://www.coupetonarbre.live/client/appointments';
        })
        .catch((error) => {
          console.error('Error during request:', error);
        });
    };

    // Handling the information changed in the form
    const handleInputChange = (name, value) => {
      if (name === 'date') {
          const newDate = moment(value).dayOfYear();
          const originalDate = moment(existingAppointmentData.date).dayOfYear();
          const newYear = moment(value).year();
          const originalYear = moment(existingAppointmentData.date).year();
  
          if (newDate > originalDate || newYear > originalYear) {
              setFormData({
                  ...formData,
                  [name]: value,
                  status: 'POSTPONED',
              });
          } else {
              setFormData({
                  ...formData,
                  [name]: value,
              });
          }
      } else {
          setFormData({
              ...formData,
              [name]: value,
          });
      }
  };

    // Handling if time was changed
    
    const handleTimeChange = (value) => {
        setFormData({
            ...formData,
            time: value && moment(value).format(format), // Use moment to format the time
        });
    };

    const isDateValid = (date) => {
        return moment(date).isSameOrAfter(moment(), 'day'); 
    };

    //Returns a list of hours that should be disabled
    const closedHours = () => {
        const dayOfWeek = moment(formData.date).day();

        //weekdays
        if (dayOfWeek >= 1 && dayOfWeek <= 5) {
            return [0, 1, 2, 3, 4, 5, 6, 7, 20, 21, 22, 23];
        }
        //saturday
        else if (dayOfWeek === 6) {
            return [0, 1, 2, 3, 4, 5, 6, 7, 8, 18, 19, 20, 21, 22, 23];
        } 
        //sunday
        else if (dayOfWeek === 0) {
            return [0, 1, 2, 3, 4, 5, 6, 7, 8, 17, 18, 19, 20, 21, 22, 23];
        }

        return false;
    };

    //Returns time slots that are already taken
    const isTimeTaken = () => {
        const chosenDay = moment(formData.date).dayOfYear();
        const chosenYear = moment(formData.date).year();
    
    
        console.log("Chosen day is " + chosenDay);
    
        // Initialize the array to store taken hours and minutes
        const takenTimeSlots = [];
    
        // Iterate through all existing appointments
        existingAppointments.forEach(request => {
            const requestYear = moment(request.date).year();
            let requestDay = moment(request.date).dayOfYear();
            requestDay += 1; // Adjust day because dayOfYear is 1-based
            const requestTime = moment(request.time, format);
        
            console.log("Already created days are " + requestDay);
            console.log("Hours and minute are " + requestTime.hour() + " " + requestTime.minute());
        
            if (chosenDay === requestDay && chosenYear === requestYear) {
                console.log("They are the same day");
        
                // Add the taken hour and minute to the array
                takenTimeSlots.push({
                hour: requestTime.hour(),
                minute: requestTime.minute(),
                });
            }
        });
    
        console.log(takenTimeSlots);
    
        return takenTimeSlots;
    };

    const handleCancel = () => {
        window.location.href = 'http://www.coupetonarbre.live/client/appointments';
    };

    return (
        <div className='content'>
          <Helmet>
            <title>{t('updateAppointmentClient.pageTitle')}</title>
          </Helmet>
          <div id='nav-container'>
            <ClientNavbar />
    
            <div className='text-align center'>
              <h1 className='heading'>{t('updateAppointmentClient.header')}</h1>
            </div>
    
            <div>
              <form onSubmit={handleUpdate} className='form-styles'>
                <label className='form-label'>{t('updateAppointmentClient.descriptionLabel')}</label>
                <input
                  type='text'
                  name='description'
                  className='form-input'
                  value={formData.description}
                  onChange={(e) => handleInputChange('description', e.target.value)}
                  required
                />
    
                <label className='form-label'>{t('updateAppointmentClient.serviceLabel')}</label>
                <select
                  name='service'
                  className='form-select'
                  value={formData.service}
                  onChange={(e) => handleInputChange('service', e.target.value)}
                  required
                >
                  {renderServiceOptions()}
                </select>
    
                <label className='form-label'>{t('updateAppointmentClient.dateLabel')}</label>
                <DatePicker
                  className='form-date'
                  selected={formData.date}
                  onChange={(date) => handleInputChange('date', date)}
                  minDate={moment()}
                  filterDate={isDateValid}
                  excludeDateIntervals={[
                    { start: new Date("2024-11-1"), end: new Date("2025-04-1") },
                    { start: new Date("2024-01-1"), end: new Date("2024-04-1") },
    
                  ]}
                  excludeDates={[new Date("2024-4-1"), new Date("2024-7-1"), new Date("2024-10-15"), new Date("2024-9-2")]}
                />
    
                <label className='form-label'>{t('updateAppointmentClient.timeLabel')}</label>
                <TimePicker
                showSecond={false}
                value={moment(formData.time, format)}  // Ensure the value is set directly using moment
                onChange={handleTimeChange}
                format={format}
                use12Hours
                className='form-time'
                allowEmpty={false}
                min={moment().hours(9).minutes(0)}
                max={moment().hours(19).minutes(59)}
                minuteStep={15}
                disabledHours={() => closedHours()}
                disabledMinutes={(hour) => isTimeTaken().filter(slot => slot.hour === hour).map(slot => slot.minute)}
                disabledSeconds={() => []}
                />
    
                <button className='form-button' type='submit'>{t('updateAppointmentClient.updateButton')}</button>
                  
                <button className='form-button' type='button' onClick={handleCancel}>{t('updateAppointmentClient.cancelButton')}</button>
                  
              </form>
            </div>
          </div>
        </div>
    );
}

export default UpdateAppointmentClient;