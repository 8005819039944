import React, { useState, useEffect } from 'react';
import Helmet from 'react-helmet';
import axios from 'axios';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import TimePicker from 'rc-time-picker';
import 'rc-time-picker/assets/index.css';
import moment from 'moment';
import AdminNavbar from '../components/AdminNavbar';
import { useParams } from 'react-router-dom';
import '../styles/CreateQuoteRequest.css';
import { useAuth } from '../security/Components/AuthProvider'; 
import { useTranslation } from 'react-i18next';

const format = 'h:mm A';

const CreateAppointmentAdmin = () => {
  //storing all clients
  const [clients, setClients] = useState([]);
  //filtering clients
  const [filteredClients, setFilteredClients] = useState([]);
  //storing the selected client
  const [selectedClientId, setSelectedClientId] = useState('');
  //storing the selected quote
  const [selectedQuoteId, setSelectedQuoteId] = useState('');

  //storing all employees
  const [employees, setEmployees] = useState([]);
  //filtering employees
  const [filteredEmployees, setFilteredEmployees] = useState([]);
  //storing the selected employee
  const [selectedEmployeeId, setSelectedEmployeeId] = useState('');

  //storing the details of the selected employee
  const [selectedEmployeeDetails, setSelectedEmployeeDetails] = useState(null);
  //storing the details of the selected client and quote
  const [selectedClientDetails, setSelectedClientDetails] = useState(null);
  const [selectedQuoteDetails, setSelectedQuoteDetails] = useState(null);

  //storing the existing appointments and approved quotes
  const [existingAppointments, setExistingAppointments] = useState([]);
  const [clientAcceptedQuotes, setClientAcceptedQuotes] = useState([]);

  //filter 
  const [searchTerm, setSearchTerm] = useState('');
  const [searchTermEmployee, setSearchTermEmployee] = useState('');

  const auth = useAuth();
  const { t } = useTranslation();
  


  //storing data from form
  const [formData, setFormData] = useState({
    description: '',
    service: '',
    date: new Date(),
    time: new Date(),
  });

  const { clientId } = useParams();

   //Mapping the enum names to better named options
   const serviceOptionsMapping = {
    'Hedge Trimming': 'HedgeTrimming',
    'Tree Trimming': 'TreeTrimming',
    'Tree Branch Removal': 'TreeBranchRemoval',
    'Small Tree Removal': 'SmallTreeRemoval',
    'Hedge Trimming and Tree Trimming': 'HedgeTrimmingAndTreeTrimming',
    'Hedge Trimming and Tree Branch Removal': 'HedgeTrimmingAndTreeBranchRemoval',
    'Tree Trimming and Tree Branch Removal': 'TreeTrimmingAndTreeBranchRemoval',
    'Hedge Trimming and Tree Trimming and Tree Branch Removal': 'HedgeTrimmingAndTreeTrimmingAndTreeBranchRemoval',
    'Hedge Trimming and Small Tree Removal': 'HedgeTrimmingAndSmallTreeRemoval',
    'Tree Trimming and Small Tree Removal': 'TreeTrimmingAndSmallTreeRemoval',
    'Hedge Trimming and Tree Trimming and Small Tree Removal': 'HedgeTrimmingAndTreeTrimmingAndSmallTreeRemoval',
    'Hedge Trimming and Tree Branch Removal and Small Tree Removal': 'HedgeTrimmingAndTreeBranchRemovalAndSmallTreeRemoval',
    'Tree Trimming and Tree Branch Removal and Small Tree Removal': 'TreeTrimmingAndTreeBranchRemovalAndSmallTreeRemoval',
    'Hedge Trimming and Tree Trimming and Tree Branch Removal and Small Tree Removal': 'HedgeTrimmingAndTreeTrimmingAndTreeBranchRemovalAndSmallTreeRemoval',
  };

  const clarityServiceMapping = {
    'HedgeTrimming': 'Hedge Trimming',
    'TreeTrimming': 'Tree Trimming',
    'TreeBranchRemoval': 'Tree Branch Removal',
    'SmallTreeRemoval': 'Small Tree Removal',
    'HedgeTrimmingAndTreeTrimming': 'Hedge Trimming and Tree Trimming',
    'HedgeTrimmingAndTreeBranchRemoval': 'Hedge Trimming and Tree Branch Removal',
    'TreeTrimmingAndTreeBranchRemoval': 'Tree Trimming and Tree Branch Removal',
    'HedgeTrimmingAndTreeTrimmingAndTreeBranchRemoval': 'Hedge Trimming and Tree Trimming and Tree Branch Removal',
    'HedgeTrimmingAndSmallTreeRemoval': 'Hedge Trimming and Small Tree Removal',
    'TreeTrimmingAndSmallTreeRemoval': 'Tree Trimming and Small Tree Removal',
    'HedgeTrimmingAndTreeTrimmingAndSmallTreeRemoval': 'Hedge Trimming and Tree Trimming and Small Tree Removal',
    'HedgeTrimmingAndTreeBranchRemovalAndSmallTreeRemoval': 'Hedge Trimming and Tree Branch Removal and Small Tree Removal',
    'TreeTrimmingAndTreeBranchRemovalAndSmallTreeRemoval': 'Tree Trimming and Tree Branch Removal and Small Tree Removal',
    'HedgeTrimmingAndTreeTrimmingAndTreeBranchRemovalAndSmallTreeRemoval': 'Hedge Trimming and Tree Trimming and Tree Branch Removal and Small Tree Removal'
};

  useEffect(() => {
    // @ts-ignore
    if (!auth.isAuthenticated) {
      window.location.href = 'http://www.coupetonarbre.live/';
    }
  }, []);


  useEffect(() => {
    // Fetch existing appointments
    axios.get("https://api.coupetonarbre.live/appointments/admin", {
      headers: {
        // @ts-ignore
        'X-XSRF-TOKEN': auth.getXsrfToken()
      }
    })
      .then(response => {
        setExistingAppointments(response.data);
      })
      .catch(error => {
        console.error('Error fetching existing quote requests:', error);
      });
  }, []);

 

  
 
  //Getting and storing all the clients in the system
  useEffect(() => {
    axios.get("https://api.coupetonarbre.live/users/clients", {
      headers: {
        // @ts-ignore
        'X-XSRF-TOKEN': auth.getXsrfToken()
      }
    })
      .then(r => {
        setClients(r.data);
        setFilteredClients(r.data);
      })
      .catch(e => {
        console.error('Error:', e);
      });
  }, [auth]);

    //Getting and storing all the employees in the system
  useEffect(() => {
    axios.get("https://api.coupetonarbre.live/users/employees", {
      headers: {
        // @ts-ignore
        'X-XSRF-TOKEN': auth.getXsrfToken()
      }
    })
      .then(r => {
        setEmployees(r.data);
        setFilteredEmployees(r.data);
      })
      .catch(e => {
        console.error('Error:', e);
      });
  }, [auth]);


  //Gets the selected client and stores its details
  useEffect(() => {
    if (selectedClientId) {
      axios.get(`https://api.coupetonarbre.live/users/clients/${selectedClientId}`, {
        headers: {
          // @ts-ignore
          'X-XSRF-TOKEN': auth.getXsrfToken()
        }
      })
        .then(response => {
          setSelectedClientDetails(response.data);
        })
        .catch(error => {
          console.error('Error:', error);
        });
    }
  }, [selectedClientId]);


  //Gets the selected employee and stores its details
  useEffect(() => {
    if (selectedEmployeeId) {
      axios.get(`https://api.coupetonarbre.live/users/employees/${selectedEmployeeId}`, {
        headers: {
          // @ts-ignore
          'X-XSRF-TOKEN': auth.getXsrfToken()
        }
      })
        .then(response => {
          setSelectedEmployeeDetails(response.data);
        })
        .catch(error => {
          console.error('Error:', error);
        });
    }
  }, [selectedEmployeeId]);

  //gets the selected quote and stores its details
  useEffect(() => {
    if (selectedQuoteId) {
      axios.get(`https://api.coupetonarbre.live/quoteRequests/${selectedQuoteId}`, {
        headers: {
          // @ts-ignore
          'X-XSRF-TOKEN': auth.getXsrfToken()
        }
      })
        .then(response => {
          setSelectedQuoteDetails(response.data);
          console.log("hello")
        })
        .catch(error => {
          console.error('Error:', error);
          console.log("error")

        });
    }
  }, [selectedQuoteId]);

   //Gets the approved quotes from client
   useEffect(() => {
    if (selectedClientId) {
      axios.get(`https://api.coupetonarbre.live/quoteRequests/approved/admin/${selectedClientId}`, {
        headers: {
          // @ts-ignore
          'X-XSRF-TOKEN': auth.getXsrfToken()
        }
      })
        .then(response => {
          setClientAcceptedQuotes(response.data);
        })
        .catch(error => {
          console.error('Error:', error);
        });
    }
  }, [selectedClientId]);


  //handles when the client is changed to change the selected client
  const handleClientChange = (event) => {
    const clientId = event.target.value;
    setSelectedClientId(clientId);
  };

  //handles when the client is changed to change the selected client
  const handleEmployeeChange = (event) => {
    const employeeId = event.target.value;
    setSelectedEmployeeId(employeeId);
  };

    //handles when the quote is changed to change the selected quote
    const handleQuoteChange = (event) => {
        const quoteId = event.target.value;
        setSelectedQuoteId(quoteId);
        
      };

  // handling the information changed in the form
  const handleInputChange = (name, value) => {
    setFormData({
      ...formData,
      [name]: value,
    });
    
  };

  // handling if time was changed
  // handling if time was changed
  const handleTimeChange = (value) => {
  setFormData({
    ...formData,
    time: value ? value.format(format) : null,
  });
};



  
  //Creating an appointment with form data and selected client information
  const handleSubmit = (event) => {
    event.preventDefault();
    
    //setting the service to the service from the quote
    formData.service = selectedQuoteDetails.service



    const employeeFirstName = selectedEmployeeDetails && selectedEmployeeDetails.firstName ? selectedEmployeeDetails.firstName : null;
    const employeeLastName = selectedEmployeeDetails && selectedEmployeeDetails.lastName ? selectedEmployeeDetails.lastName : null;

    const requestBody = {
      quoteRequestId : selectedQuoteId,
      clientId: selectedClientId,
      clientFirstName: selectedClientDetails.firstName,
      clientLastName: selectedClientDetails.lastName,
      address: selectedClientDetails.address,
      employeeId: selectedEmployeeId,
      employeeFirstName: employeeFirstName,
      employeeLastName : employeeLastName,
      ...formData,

    };

    const email = {
      recipient: selectedClientDetails.email, 
      subject: "Scheduled Appointment",
      message: `Hello, ${selectedClientDetails.firstName}!\n\nAn appointment has been scheduled for:\n\nDate: ${moment(formData.date).format('MMMM DD, YYYY')}\nTime: ${formData.time}\nAddress: ${selectedClientDetails.address}\n\nDescription: ${selectedQuoteDetails.description}\nService: ${clarityServiceMapping[selectedQuoteDetails.service]}\n\nThank you for choosing us!`
    };
    

    axios.post('https://api.coupetonarbre.live/appointments/admin', requestBody, {
      headers: {
        // @ts-ignore
        'X-XSRF-TOKEN': auth.getXsrfToken()
      }
    } )
      .then(response => {
        console.log(response.data);

        axios.post('https://api.coupetonarbre.live/email', email, {
        headers: {
          // @ts-ignore
          'X-XSRF-TOKEN': auth.getXsrfToken()
          }
        }
      )

        window.location.href = 'http://www.coupetonarbre.live/admin/appointments';

      })
      .catch(error => {
        console.error('Error:', error);
      });
  };

  //Handling change in filtering
  const handleSearchTermChange = (event) => {
    setSearchTerm(event.target.value);
    filterClients(event.target.value);
  };

  //Filtering functionality
  const filterClients = (term) => {
    const filtered = clients.filter(client =>
      client.firstName.toLowerCase().includes(term.toLowerCase()) ||
      client.lastName.toLowerCase().includes(term.toLowerCase())
    );
    setFilteredClients(filtered);
  };

  const handleSearchTermChangeEmployee = (event) => {
    setSearchTermEmployee(event.target.value);
    filterEmployees(event.target.value);
  };


  //Filtering functionality
  const filterEmployees = (term) => {
    const filtered = employees.filter(employee =>
      employee.firstName.toLowerCase().includes(term.toLowerCase()) ||
      employee.lastName.toLowerCase().includes(term.toLowerCase())
    );
    setFilteredEmployees(filtered);
  };

  //Dropdown for clients
  const renderClientOptions = () => {
    return filteredClients.map(client => (
      <option key={client.clientId} value={client.clientId}>
        {client.firstName} {client.lastName}
      </option>
    ));
  };

  //Dropdown for employees
  const renderEmployeeOptions = () => {
    return filteredEmployees.map(employee => (
      <option key={employee.employeeId} value={employee.employeeId}>
        {employee.firstName} {employee.lastName}
      </option>
    ));
  };

  //Dropdown for quotes
  const renderQuoteOptions = () => {
    return clientAcceptedQuotes.map(quote => (
      <option key={quote.quoteRequestId} value={quote.quoteRequestId}>
        {quote.description} 
      </option>
    ));
  };



  //Checks if the day is valid
  const isDateValid = (date) => {
    return moment(date).isSameOrAfter(moment(), 'day');
  };


  //Returns a list of hours that should be disabled
  const closedHours = () => {
    const dayOfWeek = moment(formData.date).day();


    //weekdays
    if (dayOfWeek >= 1 && dayOfWeek <= 5) {
      return [0, 1, 2, 3, 4, 5, 6, 7, 20, 21, 22, 23];
    }
    //saturday
     else if (dayOfWeek === 6) {
      return [0, 1, 2, 3, 4, 5, 6, 7, ,8,18, 19, 20, 21, 22, 23];
    } 
    //sunday
    else if (dayOfWeek === 0) {
      return [0, 1, 2, 3, 4, 5, 6, 7, 8, 17, 18, 19, 20, 21, 22, 23];
    }

    return false;
  };

 //Returns time slots that are already taken
  const isTimeTaken = () => {
    const chosenDay = moment(formData.date).dayOfYear();
    const chosenYear = moment(formData.date).year();

  
    console.log("Chosen day is " + chosenDay);
  
    // Initialize the array to store taken hours and minutes
    const takenTimeSlots = [];
  
    // Iterate through all existing quote requests
    existingAppointments.forEach(request => {
      const requestYear = moment(request.date).year();
      let requestDay = moment(request.date).dayOfYear();
      requestDay += 1; // Adjust day because dayOfYear is 1-based
      const requestTime = moment(request.time, format);

  
      if (chosenDay === requestDay && chosenYear === requestYear) {
  
        // Add the taken hour and minute to the array
        takenTimeSlots.push({
          hour: requestTime.hour(),
          minute: requestTime.minute(),
        });
      }
    });
  
    console.log(takenTimeSlots);
  
    return takenTimeSlots;
  };

  const handleCancel = () =>{
    window.location.href = 'http://www.coupetonarbre.live/admin/appointments';

  }

  return (
    <div className='content'>
      <Helmet>
        <title>{t('createAppointmentAdmin.pageTitle')}</title>
      </Helmet>
      <div id='nav-container'>
        <AdminNavbar />

        <div className='text-align center'>
          <h1 className='heading'>{t('createAppointmentAdmin.header')}</h1>
        </div>

        

        <div>
          <form onSubmit={handleSubmit} className='form-styles'>
            <label className='form-label'>{t('createAppointmentAdmin.clientLabel')}</label>
            <input
              type="text"
              name="search"
              className='form-input'
              value={searchTerm}
              onChange={handleSearchTermChange}
              placeholder={t('createAppointmentAdmin.clientInputPlace')}
            />
            <select className='form-select' id='client-select' value={selectedClientId} onChange={handleClientChange}>
              <option value="" >{t('createAppointmentAdmin.selectClient')}</option>
              {renderClientOptions()}
            </select>

            <label className='form-label'>{t('createAppointmentAdmin.employeeLabel')}</label>
            <input
              type="text"
              name="search"
              className='form-input'
              value={searchTermEmployee}
              onChange={handleSearchTermChangeEmployee}
              placeholder={t('createAppointmentAdmin.employeeInputPlace')}
            />
            <select className='form-select' id='employee-select' value={selectedEmployeeId} onChange={handleEmployeeChange}>
              <option value="" >{t('createAppointmentAdmin.selectEmployee')}</option>
              {renderEmployeeOptions()}
            </select>
            <br></br>
            <label className='form-label'>{t('createAppointmentAdmin.quoteLabel')}</label>
            <select className='form-select' id='quote-select' value={selectedQuoteId} onChange={handleQuoteChange}>
              <option value="" >{t('createAppointmentAdmin.selectQuote')}</option>
              {renderQuoteOptions()}
            </select>


            <label className='form-label'>{t('createAppointmentAdmin.dateLabel')}</label>
            <DatePicker
              className='form-date'
              selected={formData.date}
              onChange={(date) => handleInputChange('date', date)}
              minDate={moment()}
              filterDate={isDateValid}
              //winter (no work)
              excludeDateIntervals={[
                { start: new Date("2024-11-1"), end: new Date("2025-04-1") },
                { start: new Date("2024-01-1"), end: new Date("2024-04-1") },

              ]}
              //holidays
              excludeDates={[new Date("2024-4-1"), new Date("2024-7-1"),new Date("2024-10-15"),new Date("2024-9-2") ]}
            />

            <label className='form-label'>{t('createAppointmentAdmin.timeLabel')}</label>
            <TimePicker
              showSecond={false}            
              onChange={handleTimeChange}
              format={format}
              use12Hours
              className='form-time'
              allowEmpty={false}
              required={true}
              min={moment().hours(9).minutes(0)}
              max={moment().hours(19).minutes(59)}
              minuteStep={60}
              disabledHours={() => closedHours()}
              disabledMinutes={(hour) => isTimeTaken().filter(slot => slot.hour === hour).map(slot => slot.minute)}
              disabledSeconds={() => []}

            />

            <button className='form-button' id='form-submit' type="submit">{t('createAppointmentAdmin.submitButton')}</button>
            <button className='form-button' type='button' onClick={handleCancel}>{t('createAppointmentAdmin.cancelButton')}</button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default CreateAppointmentAdmin;
