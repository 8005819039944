import React, { useState, useEffect } from 'react';
import Helmet from 'react-helmet';
import axios from 'axios';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import TimePicker from 'rc-time-picker';
import 'rc-time-picker/assets/index.css';
import moment from 'moment';
import ClientNavbar from '../components/ClientNavbar';
import { useParams } from 'react-router-dom';
import '../styles/CreateQuoteRequest.css';
import { useAuth } from '../security/Components/AuthProvider';
import { useTranslation } from 'react-i18next';

const format = 'h:mm a';

function UpdateQuoteRequestClient() {
  const { t, i18n } = useTranslation();
  const auth = useAuth();
  const { quoteRequestId } = useParams();

  // Storing the existing quote request data for pre-filling the form
  const [existingQuoteRequestData, setExistingQuoteRequestData] = useState(null);
  const [existingQuoteRequests, setExistingQuoteRequests] = useState([]);
  const [profile, setProfile] = useState(false);


  // Storing the data from the form
  const [formData, setFormData] = useState({
    description: '',
    service: '',
    date: new Date(),
    time: new Date(),
  });

  useEffect(() => {
    axios.get('https://api.coupetonarbre.live/users/client', {
             headers: {
               // @ts-ignore
               'X-XSRF-TOKEN': auth.getXsrfToken(),
             },
           })
           .then((r) => {
             if (r.status === 200) {
               setProfile(r.data);
             }
           })
           .catch((e) => {
             console.log(e);
           });
  },[])

  useEffect(() => {
    // Fetching the existing quote request data for the given quoteRequestId
    axios
      .get(`https://api.coupetonarbre.live/quoteRequests/${quoteRequestId}/client`, {
        headers: {
          'X-XSRF-TOKEN': auth.getXsrfToken(),
        },
      })
      .then((response) => {
        const existingData = response.data;
        setExistingQuoteRequestData(existingData);

        // Set the form data based on the existing data
        setFormData({
          description: existingData.description,
          service: existingData.service,
          date: new Date(existingData.date),
          time: moment(existingData.time, format).format(format),
        });
      })
      .catch((error) => {
        console.error('Error fetching existing quote request data:', error);
      });
  }, [auth, quoteRequestId]);

  
  const serviceOptionsMappingEN = {
    'Hedge Trimming': 'HedgeTrimming',
    'Tree Trimming': 'TreeTrimming',
    'Tree Branch Removal': 'TreeBranchRemoval',
    'Small Tree Removal': 'SmallTreeRemoval',
    'Hedge Trimming and Tree Trimming': 'HedgeTrimmingAndTreeTrimming',
    'Hedge Trimming and Tree Branch Removal': 'HedgeTrimmingAndTreeBranchRemoval',
    'Tree Trimming and Tree Branch Removal': 'TreeTrimmingAndTreeBranchRemoval',
    'Hedge Trimming and Tree Trimming and Tree Branch Removal': 'HedgeTrimmingAndTreeTrimmingAndTreeBranchRemoval',
    'Hedge Trimming and Small Tree Removal': 'HedgeTrimmingAndSmallTreeRemoval',
    'Tree Trimming and Small Tree Removal': 'TreeTrimmingAndSmallTreeRemoval',
    'Hedge Trimming and Tree Trimming and Small Tree Removal': 'HedgeTrimmingAndTreeTrimmingAndSmallTreeRemoval',
    'Hedge Trimming and Tree Branch Removal and Small Tree Removal': 'HedgeTrimmingAndTreeBranchRemovalAndSmallTreeRemoval',
    'Tree Trimming and Tree Branch Removal and Small Tree Removal': 'TreeTrimmingAndTreeBranchRemovalAndSmallTreeRemoval',
    'Hedge Trimming and Tree Trimming and Tree Branch Removal and Small Tree Removal': 'HedgeTrimmingAndTreeTrimmingAndTreeBranchRemovalAndSmallTreeRemoval',
  };

  const serviceOptionsMappingFR = {
    'Taille des Haies': 'HedgeTrimming',
    'Taille des Arbres': 'TreeTrimming',
    'Enlèvement des Branches d\'Arbre' : 'TreeBranchRemoval',
    'Enlèvement des Petits Arbres': 'SmallTreeRemoval',
    'Taille de Haies et d\'Arbres': 'HedgeTrimmingAndTreeTrimming',
    'Taille des Haies et Enlèvement des Branches d\'Arbres': 'HedgeTrimmingAndTreeBranchRemoval',
    'Taille des Arbres et Enlèvement des Branches': 'TreeTrimmingAndTreeBranchRemoval',
    'Taille de Haies, Taille d\'Arbres et Enlèvement de Branches d\'Arbres': 'HedgeTrimmingAndTreeTrimmingAndTreeBranchRemoval',
    'Taille de Haies et Enlèvement de Petits Arbres': 'HedgeTrimmingAndSmallTreeRemoval',
    'Taille des Arbres et Enlèvement des Petits Arbres': 'TreeTrimmingAndSmallTreeRemoval',
    'Taille de Haies, Taille d\'Arbres et Enlèvement de Petits Arbres': 'HedgeTrimmingAndTreeTrimmingAndSmallTreeRemoval',
    'Taille de Haies, Enlèvement de Branches d\'Arbres et Enlèvement de Petits Arbres': 'HedgeTrimmingAndTreeBranchRemovalAndSmallTreeRemoval',
    'Taille des Arbres, Enlèvement des Branches et Enlèvement des Petits Arbres': 'TreeTrimmingAndTreeBranchRemovalAndSmallTreeRemoval',
    'Taille de Haies et Taille d\'Arbres et Enlèvement de Branches d\'Arbres et Enlèvement de Petits Arbres': 'HedgeTrimmingAndTreeTrimmingAndTreeBranchRemovalAndSmallTreeRemoval'
  };

  const clarityServiceMapping = {
    'HedgeTrimming': 'Hedge Trimming',
    'TreeTrimming': 'Tree Trimming',
    'TreeBranchRemoval': 'Tree Branch Removal',
    'SmallTreeRemoval': 'Small Tree Removal',
    'HedgeTrimmingAndTreeTrimming': 'Hedge Trimming and Tree Trimming',
    'HedgeTrimmingAndTreeBranchRemoval': 'Hedge Trimming and Tree Branch Removal',
    'TreeTrimmingAndTreeBranchRemoval': 'Tree Trimming and Tree Branch Removal',
    'HedgeTrimmingAndTreeTrimmingAndTreeBranchRemoval': 'Hedge Trimming and Tree Trimming and Tree Branch Removal',
    'HedgeTrimmingAndSmallTreeRemoval': 'Hedge Trimming and Small Tree Removal',
    'TreeTrimmingAndSmallTreeRemoval': 'Tree Trimming and Small Tree Removal',
    'HedgeTrimmingAndTreeTrimmingAndSmallTreeRemoval': 'Hedge Trimming and Tree Trimming and Small Tree Removal',
    'HedgeTrimmingAndTreeBranchRemovalAndSmallTreeRemoval': 'Hedge Trimming and Tree Branch Removal and Small Tree Removal',
    'TreeTrimmingAndTreeBranchRemovalAndSmallTreeRemoval': 'Tree Trimming and Tree Branch Removal and Small Tree Removal',
    'HedgeTrimmingAndTreeTrimmingAndTreeBranchRemovalAndSmallTreeRemoval': 'Hedge Trimming and Tree Trimming and Tree Branch Removal and Small Tree Removal'
};

  const renderServiceOptions = () => {
    const currentLanguage = i18n.language;
    let serviceOptions;
    let serviceMapping;

    // Determine which service mapping to use based on the current language
    if (currentLanguage === 'fr') {
      serviceOptions = Object.keys(serviceOptionsMappingFR);
      serviceMapping = serviceOptionsMappingFR;
    } else {
      serviceOptions = Object.keys(serviceOptionsMappingEN);
      serviceMapping = serviceOptionsMappingEN;
    }

    return serviceOptions.map(option => (
      <option key={option} value={serviceMapping[option]}>
        {option}
      </option>
    ));
  };

  const handleUpdate = (event) => {
    event.preventDefault();

    const newDate = moment(formData.date).dayOfYear();

    const originalDate = moment(existingQuoteRequestData.date).dayOfYear();

    const newYear = moment(formData.date).year();

    const originalYear = moment(existingQuoteRequestData.date).year();

    if (newDate > originalDate || newYear > originalYear) {
      // Send email notification
      const email = {
        recipient: profile.email,
        subject: "Quote Request Postponed",
        message: `Hello, ${existingQuoteRequestData.clientFirstName}!\n\nYou have postponed your quote request.\n\nDescription: ${formData.description}\nService: ${clarityServiceMapping[formData.service]}\n\nThe new scheduled date is: ${moment(formData.date).format('MMMM DD, YYYY')} at ${moment(formData.time, format).format(format)}.\n\nWe hope to see you soon!`,
      };
  
      axios.post('https://api.coupetonarbre.live/email', email, {
        headers: {
          'X-XSRF-TOKEN': auth.getXsrfToken(),
        },
      })
        .then(response => {
          console.log('Email notification sent:', response.data);
        })
        .catch(error => {
          console.error('Error sending email notification:', error);
        });
    }

    const requestBody = {
      ...formData,
    };

    axios
      .put(`https://api.coupetonarbre.live/quoteRequests/${quoteRequestId}/client`, requestBody, {
        headers: {
          'X-XSRF-TOKEN': auth.getXsrfToken(),
        },
      })
      .then((response) => {
        console.log(response.data);
        window.location.href = 'http://www.coupetonarbre.live/client/quoteRequests';
      })
      .catch((error) => {
        console.error('Error during request:', error);
      });
  };

  // Handling the information changed in the form
  const handleInputChange = (name, value) => {
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // Handling if time was changed
 
const handleTimeChange = (value) => {
  setFormData({
    ...formData,
    time: value && moment(value).format(format), // Use moment to format the time
  });
};
const isDateValid = (date) => {
  return moment(date).isSameOrAfter(moment(), 'day');
};

//Returns a list of hours that should be disabled
const closedHours = () => {
  const dayOfWeek = moment(formData.date).day();


  //weekdays
  if (dayOfWeek >= 1 && dayOfWeek <= 5) {
    return [0, 1, 2, 3, 4, 5, 6, 7, 20, 21, 22, 23];
  }
  //saturday
   else if (dayOfWeek === 6) {
    return [0, 1, 2, 3, 4, 5, 6, 7, 8, 18, 19, 20, 21, 22, 23];
  } 
  //sunday
  else if (dayOfWeek === 0) {
    return [0, 1, 2, 3, 4, 5, 6, 7, 8, 17, 18, 19, 20, 21, 22, 23];
  }

  return false;
};
//Returns time slots that are already taken
const isTimeTaken = () => {
  const chosenDay = moment(formData.date).dayOfYear();
  const chosenYear = moment(formData.date).year();


  console.log("Chosen day is " + chosenDay);

  // Initialize the array to store taken hours and minutes
  const takenTimeSlots = [];

  // Iterate through all existing quote requests
  existingQuoteRequests.forEach(request => {
    const requestYear = moment(request.date).year();
    let requestDay = moment(request.date).dayOfYear();
    requestDay += 1; // Adjust day because dayOfYear is 1-based
    const requestTime = moment(request.time, format);

    console.log("Already created days are " + requestDay);
    console.log("Hours and minute are " + requestTime.hour() + " " + requestTime.minute());

    if (chosenDay === requestDay && chosenYear === requestYear) {
      console.log("They are the same day");

      // Add the taken hour and minute to the array
      takenTimeSlots.push({
        hour: requestTime.hour(),
        minute: requestTime.minute(),
      });
    }
  });

  console.log(takenTimeSlots);

  return takenTimeSlots;
};

const handleCancel = () => {
  window.location.href = 'http://www.coupetonarbre.live/client/quoteRequests';
};

  return (
    <div className='content'>
      <Helmet>
        <title>{t('updateQuoteClient.pageTitle')}</title>
      </Helmet>
      <div id='nav-container'>
        <ClientNavbar />

        <div className='text-align center'>
          <h1 className='heading'>{t('updateQuoteClient.header')}</h1>
        </div>

        <div>
          <form onSubmit={handleUpdate} className='form-styles'>
            <label className='form-label'>{t('updateQuoteClient.descriptionLabel')}</label>
            <input
              type='text'
              name='description'
              className='form-input'
              value={formData.description}
              onChange={(e) => handleInputChange('description', e.target.value)}
              required
            />

            <label className='form-label'>{t('updateQuoteClient.serviceLabel')}</label>
            <select
              name='service'
              className='form-select'
              value={formData.service}
              onChange={(e) => handleInputChange('service', e.target.value)}
              required
            >
              {/* Assuming you have a function to render service options */}
              {renderServiceOptions()}
            </select>

            <label className='form-label'>{t('updateQuoteClient.dateLabel')}</label>
            <DatePicker
              className='form-date'
              selected={formData.date}
              onChange={(date) => handleInputChange('date', date)}
              minDate={moment()}
              filterDate={isDateValid}
              excludeDateIntervals={[
                { start: new Date("2024-11-1"), end: new Date("2025-04-1") },
                { start: new Date("2024-01-1"), end: new Date("2024-04-1") },

              ]}
              excludeDates={[new Date("2024-4-1"), new Date("2024-7-1"), new Date("2024-10-15"), new Date("2024-9-2")]}
            />

            <label className='form-label'>{t('updateQuoteClient.timeLabel')}</label>

            <TimePicker
              showSecond={false}
              value={moment(formData.time, format)}  // Ensure the value is set directly using moment
              onChange={handleTimeChange}
              format={format}
              use12Hours
              className='form-time'
              allowEmpty={false}
              min={moment().hours(9).minutes(0)}
              max={moment().hours(19).minutes(59)}
              minuteStep={15}
              disabledHours={() => closedHours()}
              disabledMinutes={(hour) => isTimeTaken().filter(slot => slot.hour === hour).map(slot => slot.minute)}
              disabledSeconds={() => []}
            />


            <button className='form-button' type='button' onClick={handleCancel}>{t('updateQuoteClient.cancelButton')}</button>
            <button className='form-button' type='button' onClick={handleUpdate}>{t('updateQuoteClient.updateButton')}</button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default UpdateQuoteRequestClient;
