import React, { useState, useEffect } from 'react';
import Helmet from 'react-helmet';
import axios from 'axios';
import AdminNavbar from '../components/AdminNavbar';
import moment from 'moment';
import '../styles/QuotesAdmin.css';

import { useAuth } from "../security/Components/AuthProvider";
import { useTranslation } from 'react-i18next';

const format = 'h:mm a';

const clarityServiceMapping = {
  'HedgeTrimming': 'Hedge Trimming',
  'TreeTrimming': 'Tree Trimming',
  'TreeBranchRemoval': 'Tree Branch Removal',
  'SmallTreeRemoval': 'Small Tree Removal',
  'HedgeTrimmingAndTreeTrimming': 'Hedge Trimming and Tree Trimming',
  'HedgeTrimmingAndTreeBranchRemoval': 'Hedge Trimming and Tree Branch Removal',
  'TreeTrimmingAndTreeBranchRemoval': 'Tree Trimming and Tree Branch Removal',
  'HedgeTrimmingAndTreeTrimmingAndTreeBranchRemoval': 'Hedge Trimming and Tree Trimming and Tree Branch Removal',
  'HedgeTrimmingAndSmallTreeRemoval': 'Hedge Trimming and Small Tree Removal',
  'TreeTrimmingAndSmallTreeRemoval': 'Tree Trimming and Small Tree Removal',
  'HedgeTrimmingAndTreeTrimmingAndSmallTreeRemoval': 'Hedge Trimming and Tree Trimming and Small Tree Removal',
  'HedgeTrimmingAndTreeBranchRemovalAndSmallTreeRemoval': 'Hedge Trimming and Tree Branch Removal and Small Tree Removal',
  'TreeTrimmingAndTreeBranchRemovalAndSmallTreeRemoval': 'Tree Trimming and Tree Branch Removal and Small Tree Removal',
  'HedgeTrimmingAndTreeTrimmingAndTreeBranchRemovalAndSmallTreeRemoval': 'Hedge Trimming and Tree Trimming and Tree Branch Removal and Small Tree Removal'
};

function QuotesAdmin() {
  const [quoteRequests, setQuoteRequests] = useState([]);
  const [quoteRequestId, setQuoteRequestId] = useState(null);
  const [quoteRequestDetails, setQuoteRequestDetails] = useState(null);
  const [clientId, setClientId] = useState(null)
  const [clientDetails, setClientDetails] = useState(null)
  const [currentPage, setCurrentPage] = useState(1); 
  const [quoteRequestsPerPage] = useState(5); 
  const auth = useAuth();
  const { t, i18n } = useTranslation();

  const handleUpdate = (quoteRequestId) => {
    window.location.href = `/admin/quotes/updateQuoteRequest/${quoteRequestId}`;
  };

  const getAllQuoteRequests = async () => {
    axios.get("https://api.coupetonarbre.live/quoteRequests/admin", {
      headers: {
        'X-XSRF-TOKEN': auth.getXsrfToken()
      }
    })
    .then(response => {
      setQuoteRequests(response.data);
    })
    .catch(error => {
      console.error('Error fetching quote requests:', error);
    });
  };

  useEffect(() => {
    getAllQuoteRequests();
  }, []);

  // Fetch a specific quote request by ID
  useEffect(() => {
    if (quoteRequestId !== null) {
      axios.get(`https://api.coupetonarbre.live/quoteRequests/${quoteRequestId}`, {
        headers: {
          'X-XSRF-TOKEN': auth.getXsrfToken()
        }
      })
      .then(response => {
        setQuoteRequestDetails(response.data);
        setClientId(response.data.clientId)
      })
      .catch(error => {
        console.error('Error fetching quote request details:', error);
      });
    }
  }, [quoteRequestId]);

  useEffect(() => {
    if (clientId !== null) {
      axios.get(`https://api.coupetonarbre.live/users/clients/${clientId}`, {
        headers: {
          'X-XSRF-TOKEN': auth.getXsrfToken()
        }
      })
      .then(response => {
        setClientDetails(response.data)
      })
      .catch(error => {
        console.error('Error fetching client details:', error);
      });
    }
  }, [clientId]);

  // Utility functions for formatting
  const formatDate = (dateString) => {
    const lang = i18n.language;

    const date = new Date(dateString);

    if (lang === 'fr') {
      const day = date.getDate();
      const month = date.getMonth() + 1;
      const year = date.getFullYear();

      return `${day < 10 ? '0' + day : day}/${month < 10 ? '0' + month : month}/${year}`;
    } else {
      const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
      return date.toLocaleDateString(undefined, options);
    }
  };

  const formatStatus = (status) => {
    return status
      .replace(/_/g, ' ')
      .replace(/[^a-zA-Z ]/g, '')
      .toLowerCase()
      .replace(/\b\w/g, (l) => l.toUpperCase());
  };

  const formatService = (service) => {
    return service.replace(/([a-z])([A-Z])/g, '$1 $2');
  };

  const getStatusStyle = (status) => {
    switch (status.toLowerCase()) {
      case 'quote_accepted':
        return { backgroundColor: 'lightgreen' };
      case 'pending':
        return { backgroundColor: 'lightyellow' };
      case 'quote_refused':
        return { backgroundColor: 'lightcoral' };
      default:
        return {};
    }
  };

  const handleQuoteRequestClick = (id) => {
    if (quoteRequestId === id) {
      handleCloseDetails();
    } else {
      setQuoteRequestId(id);
      axios.get(`https://api.coupetonarbre.live/quoteRequests/${id}`, {
        headers: {
          'X-XSRF-TOKEN': auth.getXsrfToken()
        }
      })
      .then(response => {
        setQuoteRequestDetails(response.data);
      })
      .catch(error => {
        console.error('Error fetching quote request details:', error);
      });
    }
  };
  

  const handleCloseDetails = () => {
    setQuoteRequestId(null);
    setQuoteRequestDetails(null);
  };
  

  const handleAddQuoteRequest = () => {
    window.location.href = 'http://www.coupetonarbre.live/admin/quoteRequests/new';

  }

  const handleDelete = (quoteRequestId) => {
    if (typeof quoteRequestId === 'undefined') {
      console.error('Quote Request ID is undefined. Cannot delete.');
      return;
    }
  
    const isConfirmed = window.confirm(t('quotesAdmin.deleteConfirmation'));
  
    if (!isConfirmed) {
      return;
    }

    const email = {
      recipient: clientDetails.email,
      subject: "Quote Request Cancelled",
      message: `Hello, ${clientDetails.firstName}\n\nYour quote request:\n\nDescription: ${quoteRequestDetails.description}\nService: ${clarityServiceMapping[quoteRequestDetails.service]}\n\nDate: ${moment(quoteRequestDetails.date).format('MMMM DD, YYYY')} at ${moment(quoteRequestDetails.time, format).format(format)}.\n\nHas been cancelled, thank you for your understanding.`,
    };
  
    axios.post('https://api.coupetonarbre.live/email', email, {
      headers: {
        'X-XSRF-TOKEN': auth.getXsrfToken(),
      },
    })
      .then(response => {
        console.log('Email notification sent:', response.data);
      })
      .catch(error => {
        console.error('Error sending email notification:', error);
      });
  
    console.log('Deleting quote request with id:', quoteRequestId);
  
    axios.delete(`https://api.coupetonarbre.live/quoteRequests/${quoteRequestId}/admin`, {
      headers: {
        'X-XSRF-TOKEN': auth.getXsrfToken()
      }
    })
    .then(response => {
      const updatedQuoteRequestsList = quoteRequests.filter(quoteRequests => quoteRequests.quoteRequestId !== quoteRequestId);
       // Calculate the new current page after deletion
       setQuoteRequests(updatedQuoteRequestsList);
       const totalPages = Math.ceil(updatedQuoteRequestsList.length / quoteRequestsPerPage);
       const newCurrentPage = Math.min(currentPage, totalPages);
       setCurrentPage(newCurrentPage);
    })
    .catch(error => {
      console.error('Error:', error);
    });
  };
  
  const getServiceTranslation = (service) => {
    return t(`quotesAdmin.serviceEnum.${service}`);
  };

  const getStatusTranslation = (status) => {
    return t(`quotesAdmin.statusEnum.${status}`);
  };

  const formatTime = (timeString) => {
    const lang = i18n.language;

    const timeRegex = /(\d{1,2}):(\d{2})\s*(AM|PM)?/i;

    const match = timeString.match(timeRegex);

    if (!match) return timeString;

    let hour = parseInt(match[1], 10);
    const minute = match[2];

    if (lang === 'fr') {
      if (match[3] && match[3].toUpperCase() === 'PM') {
        hour = (hour % 12) + 12;
      } else if (match[3] && match[3].toUpperCase() === 'AM' && hour === 12) {
        hour = 0;
      }

      return `${hour < 10 ? '0' + hour : hour}:${minute}`;
    } else {
      const period = match[3] ? match[3].toUpperCase() : '';
      return `${hour % 12 || 12}:${minute} ${period}`;
    }
  };
  const handlePaginationClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const renderPaginationButtons = () => {
    const totalPages = Math.ceil(quoteRequests.length / quoteRequestsPerPage);

    const paginationButtons = [];
    for (let i = 1; i <= totalPages; i++) {
      paginationButtons.push(
        <button
          key={i}
          className={`page-link ${currentPage === i ? 'active' : ''}`}
          onClick={() => handlePaginationClick(i)}
        >
          {i}
        </button>
      );
    }
    return paginationButtons;
  };

  // Logic for pagination
  const indexOfLastQuoteRequests = currentPage * quoteRequestsPerPage;
  const indexOfFirstQuoteRequests = indexOfLastQuoteRequests - quoteRequestsPerPage;
  const currentQuoteRequests = quoteRequests.slice(indexOfFirstQuoteRequests, indexOfLastQuoteRequests);
  
  return (
    <div className='content'>
      <Helmet>
        <title>{t('quotesAdmin.pageTitle')}</title>
      </Helmet>
      <div id='nav-container'>
        <AdminNavbar />
      </div>
      <div className='header-container'>
        <h1>{t('quotesAdmin.header')}</h1>
      </div>
      <div>
      <div className="add-quote-button">
          <button className='button' type="button" onClick={handleAddQuoteRequest}>{t('quotesAdmin.addButton')}</button>
      </div>
      </div>
      <div>
        <table>
          <thead>
            <tr>
              <th>{t('quotesAdmin.table.firstName')}</th>
              <th>{t('quotesAdmin.table.lastName')}</th>
              <th>{t('quotesAdmin.table.time')}</th>
              <th>{t('quotesAdmin.table.date')}</th>
              <th>{t('quotesAdmin.table.price')}</th>
              <th>{t('quotesAdmin.table.service')}</th>
              <th>{t('quotesAdmin.table.status')}</th>
              <th>{t('quotesAdmin.table.employeeAssigned')}</th>
            </tr>
          </thead>
          <tbody>
            {currentQuoteRequests.map((quoteRequest) => (
              <React.Fragment key={quoteRequest.quoteRequestId}>
                <tr onClick={() => handleQuoteRequestClick(quoteRequest.quoteRequestId)}>
                  <td>{quoteRequest.clientFirstName}</td>
                  <td>{quoteRequest.clientLastName}</td>
                  <td>{formatTime(quoteRequest.time)}</td>
                  <td>{formatDate(quoteRequest.date)}</td>
                  <td>{quoteRequest.price ? `$${quoteRequest.price}` : t('quotesAdmin.table.tbd')}</td>
                  <td>{getServiceTranslation(quoteRequest.service)}</td>
                  <td style={getStatusStyle(quoteRequest.status)}>{getStatusTranslation(quoteRequest.status)}</td>

                  <td>{quoteRequest.employeeFirstName && quoteRequest.employeeLastName ? `${quoteRequest.employeeFirstName + " " + quoteRequest.employeeLastName}` : t('quotesAdmin.table.employeeAssignedBody')}</td>

                  <td>
                    <button className='buttons edit-button' onClick={() => handleUpdate(quoteRequest.quoteRequestId)}>{t('quotesAdmin.table.editButton')}</button>
                  </td>


                </tr>
                {quoteRequestId === quoteRequest.quoteRequestId && quoteRequestDetails && (
                  <tr>
                    <td colSpan="7">
                      <div className="additional-details">
                        <p><strong>{t('quotesAdmin.table.details.quoteID')}</strong> {quoteRequestDetails.quoteRequestId}</p>
                        <p><strong>{t('quotesAdmin.table.details.clientID')}</strong> {quoteRequestDetails.clientId}</p>
                        <p><strong>{t('quotesAdmin.table.details.clientName')}</strong> {quoteRequestDetails.clientFirstName} {quoteRequestDetails.clientLastName}</p>
                        <p><strong>{t('quotesAdmin.table.details.employeeAssigned')}</strong> {quoteRequest.employeeFirstName && quoteRequest.employeeLastName ? `${quoteRequest.employeeFirstName + " " + quoteRequest.employeeLastName}` : 'No Employee Assigned'}</p>
                        <p><strong>{t('quotesAdmin.table.details.time')}</strong> {quoteRequestDetails.time}</p>
                        <p><strong>{t('quotesAdmin.table.details.date')}</strong> {formatDate(quoteRequestDetails.date)}</p>
                        <p><strong>{t('quotesAdmin.table.details.price')}</strong> {quoteRequestDetails.price ? `$${quoteRequestDetails.price}` : t('quotesAdmin.table.tbd')}</p>
                        <p><strong>{t('quotesAdmin.table.details.description')}</strong> {quoteRequestDetails.description}</p>
                        <p><strong>{t('quotesAdmin.table.details.address')}</strong> {quoteRequestDetails.address}</p>
                        <p><strong>{t('quotesAdmin.table.details.service')}</strong> {getServiceTranslation(quoteRequestDetails.service)}</p>
                        <p><strong>{t('quotesAdmin.table.details.status')}</strong> {getStatusTranslation(quoteRequestDetails.status)}</p>
                        <button className="buttons close-button" onClick={handleCloseDetails}>{t('quotesAdmin.table.details.closeButton')}</button>
                        <button className='buttons delete-button' onClick={() => handleDelete(quoteRequest.quoteRequestId)}>Cancel</button>
                      </div>
                    </td>
                  </tr>
                )}
              </React.Fragment>
            ))}
          </tbody>
        </table>
        <div>
          <div className="pagination">
            {renderPaginationButtons()}
          </div>
          </div>
      </div>
    </div>
  );
}

export default QuotesAdmin;