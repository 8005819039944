import React, { useState, useEffect } from 'react';
import Helmet from 'react-helmet';
import axios from 'axios';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import TimePicker from 'rc-time-picker';
import 'rc-time-picker/assets/index.css';
import moment from 'moment'; 
import ClientNavbar from '../components/ClientNavbar';
import '../styles/CreateQuoteRequest.css';
import { useAuth } from '../security/Components/AuthProvider';
import { useTranslation } from 'react-i18next';

const format = 'h:mm a';

function CreateQuoteRequestClient() {

  const auth = useAuth();
  const { t, i18n } = useTranslation();

  //storing the existing quote requests (Dates)
  const [existingQuoteRequests, setExistingQuoteRequests] = useState([]);

  //storing the data from the form
  const [formData, setFormData] = useState({
    description: '',
    service: '',
    date: new Date(),
    time: new Date(),
  });

  const clarityServiceMapping = {
    'HedgeTrimming': 'Hedge Trimming',
    'TreeTrimming': 'Tree Trimming',
    'TreeBranchRemoval': 'Tree Branch Removal',
    'SmallTreeRemoval': 'Small Tree Removal',
    'HedgeTrimmingAndTreeTrimming': 'Hedge Trimming and Tree Trimming',
    'HedgeTrimmingAndTreeBranchRemoval': 'Hedge Trimming and Tree Branch Removal',
    'TreeTrimmingAndTreeBranchRemoval': 'Tree Trimming and Tree Branch Removal',
    'HedgeTrimmingAndTreeTrimmingAndTreeBranchRemoval': 'Hedge Trimming and Tree Trimming and Tree Branch Removal',
    'HedgeTrimmingAndSmallTreeRemoval': 'Hedge Trimming and Small Tree Removal',
    'TreeTrimmingAndSmallTreeRemoval': 'Tree Trimming and Small Tree Removal',
    'HedgeTrimmingAndTreeTrimmingAndSmallTreeRemoval': 'Hedge Trimming and Tree Trimming and Small Tree Removal',
    'HedgeTrimmingAndTreeBranchRemovalAndSmallTreeRemoval': 'Hedge Trimming and Tree Branch Removal and Small Tree Removal',
    'TreeTrimmingAndTreeBranchRemovalAndSmallTreeRemoval': 'Tree Trimming and Tree Branch Removal and Small Tree Removal',
    'HedgeTrimmingAndTreeTrimmingAndTreeBranchRemovalAndSmallTreeRemoval': 'Hedge Trimming and Tree Trimming and Tree Branch Removal and Small Tree Removal'
};

  //Mapping the enum names to better named options
  const serviceOptionsMappingEN = {
    'Hedge Trimming': 'HedgeTrimming',
    'Tree Trimming': 'TreeTrimming',
    'Tree Branch Removal': 'TreeBranchRemoval',
    'Small Tree Removal': 'SmallTreeRemoval',
    'Hedge Trimming and Tree Trimming': 'HedgeTrimmingAndTreeTrimming',
    'Hedge Trimming and Tree Branch Removal': 'HedgeTrimmingAndTreeBranchRemoval',
    'Tree Trimming and Tree Branch Removal': 'TreeTrimmingAndTreeBranchRemoval',
    'Hedge Trimming and Tree Trimming and Tree Branch Removal': 'HedgeTrimmingAndTreeTrimmingAndTreeBranchRemoval',
    'Hedge Trimming and Small Tree Removal': 'HedgeTrimmingAndSmallTreeRemoval',
    'Tree Trimming and Small Tree Removal': 'TreeTrimmingAndSmallTreeRemoval',
    'Hedge Trimming and Tree Trimming and Small Tree Removal': 'HedgeTrimmingAndTreeTrimmingAndSmallTreeRemoval',
    'Hedge Trimming and Tree Branch Removal and Small Tree Removal': 'HedgeTrimmingAndTreeBranchRemovalAndSmallTreeRemoval',
    'Tree Trimming and Tree Branch Removal and Small Tree Removal': 'TreeTrimmingAndTreeBranchRemovalAndSmallTreeRemoval',
    'Hedge Trimming and Tree Trimming and Tree Branch Removal and Small Tree Removal': 'HedgeTrimmingAndTreeTrimmingAndTreeBranchRemovalAndSmallTreeRemoval',
  };

  const serviceOptionsMappingFR = {
    'Taille des Haies': 'HedgeTrimming',
    'Taille des Arbres': 'TreeTrimming',
    'Enlèvement des Branches d\'Arbre' : 'TreeBranchRemoval',
    'Enlèvement des Petits Arbres': 'SmallTreeRemoval',
    'Taille de Haies et d\'Arbres': 'HedgeTrimmingAndTreeTrimming',
    'Taille des Haies et Enlèvement des Branches d\'Arbres': 'HedgeTrimmingAndTreeBranchRemoval',
    'Taille des Arbres et Enlèvement des Branches': 'TreeTrimmingAndTreeBranchRemoval',
    'Taille de Haies, Taille d\'Arbres et Enlèvement de Branches d\'Arbres': 'HedgeTrimmingAndTreeTrimmingAndTreeBranchRemoval',
    'Taille de Haies et Enlèvement de Petits Arbres': 'HedgeTrimmingAndSmallTreeRemoval',
    'Taille des Arbres et Enlèvement des Petits Arbres': 'TreeTrimmingAndSmallTreeRemoval',
    'Taille de Haies, Taille d\'Arbres et Enlèvement de Petits Arbres': 'HedgeTrimmingAndTreeTrimmingAndSmallTreeRemoval',
    'Taille de Haies, Enlèvement de Branches d\'Arbres et Enlèvement de Petits Arbres': 'HedgeTrimmingAndTreeBranchRemovalAndSmallTreeRemoval',
    'Taille des Arbres, Enlèvement des Branches et Enlèvement des Petits Arbres': 'TreeTrimmingAndTreeBranchRemovalAndSmallTreeRemoval',
    'Taille de Haies et Taille d\'Arbres et Enlèvement de Branches d\'Arbres et Enlèvement de Petits Arbres': 'HedgeTrimmingAndTreeTrimmingAndTreeBranchRemovalAndSmallTreeRemoval'
  };

  const [profile, setHasProfile] = useState(false);

  //Check if user is logged in and has profile
  useEffect(() => {
    // @ts-ignore
    if (!auth.isAuthenticated) {
      window.location.href = 'http://www.coupetonarbre.live/client/quoteRequests';
    }

    axios.get('https://api.coupetonarbre.live/users/client', {
              headers: {
                // @ts-ignore
                'X-XSRF-TOKEN': auth.getXsrfToken(),
              },
            })
            .then((r) => {
              if (r.status === 200) {
                setHasProfile(r.data)
              }
            })
            .catch((e) => {
              console.log(e);
            });

  }, []);



  useEffect(() => {
    // Fetching existing quote requests dates
    axios.get("https://api.coupetonarbre.live/quoteRequests/dates", {
      headers: {
        // @ts-ignore
        'X-XSRF-TOKEN': auth.getXsrfToken()
      }
    })
      .then(response => {
        setExistingQuoteRequests(response.data);
      })
      .catch(error => {
        console.error('Error fetching existing quote requests:', error);
      });
  }, []);



  //creating a new quote request
  const handleSubmit = (event) => {
    event.preventDefault();


      const requestBody = {
        ...formData,
      };

      const email = {
        recipient: profile.email, 
        subject : "Scheduled Quote Request",
        message : `Hello, ${profile.firstName}!\n\nA quote request has been scheduled for:\n\nDate: ${moment(formData.date).format('MMMM DD, YYYY')}
        \nTime: ${formData.time}\nAddress: ${profile.address}\n\nDescription: ${formData.description}\nService: ${clarityServiceMapping[formData.service]}\n\nThank you for choosing us!`
      };

      
    axios.post('https://api.coupetonarbre.live/quoteRequests/client', requestBody, {
      headers: {
          // @ts-ignore
          'X-XSRF-TOKEN': auth.getXsrfToken()
      }   

      })

      .then(response => {
        console.log(response.data);

        axios.post('https://api.coupetonarbre.live/email', email, {
        headers: {
          // @ts-ignore
          'X-XSRF-TOKEN': auth.getXsrfToken()
          }
        }
      )

        window.location.href = 'http://www.coupetonarbre.live/client/quoteRequests';

      })
      .catch(error => {
        console.error('Error:', error);
      });
  };


  // dropdown for services
  const renderServiceOptions = () => {
    const currentLanguage = i18n.language;
    let serviceOptions;
    let serviceMapping;

    // Determine which service mapping to use based on the current language
    if (currentLanguage === 'fr') {
      serviceOptions = Object.keys(serviceOptionsMappingFR);
      serviceMapping = serviceOptionsMappingFR;
    } else {
      serviceOptions = Object.keys(serviceOptionsMappingEN);
      serviceMapping = serviceOptionsMappingEN;
    }

    return serviceOptions.map(option => (
      <option key={option} value={serviceMapping[option]}>
        {option}
      </option>
    ));
  };

    
  // handling the information changed in the form
  const handleInputChange = (name, value) => {
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // handling if time was changed

  const handleTimeChange = (value) => {
    setFormData({
      ...formData,
      time: value && value.format(format),
    });
  };

  //making sure day is valid
  const isDateValid = (date) => {
    return moment(date).isSameOrAfter(moment(), 'day');
  };

//returning an array of hours that should be disabled based on day of week
  const closedHours = () => {
    const dayOfWeek = moment(formData.date).day();


    //weekdays
    if (dayOfWeek >= 1 && dayOfWeek <= 5) {
      return [0, 1, 2, 3, 4, 5, 6, 7, 20, 21, 22, 23];
    }
    //saturday
     else if (dayOfWeek === 6) {
      return [0, 1, 2, 3, 4, 5, 6, 7, ,8,18, 19, 20, 21, 22, 23];
    } 
    //sunday
    else if (dayOfWeek === 0) {
      return [0, 1, 2, 3, 4, 5, 6, 7, 8, 17, 18, 19, 20, 21, 22, 23];
    }

    return false;
  };

 //returning the time slots that are already taken for that day
  const isTimeTaken = () => {

    // day chosen in form
    const chosenDay = moment(formData.date).dayOfYear();
    //year chosen in form
    const chosenYear = moment(formData.date).year();

  
    // Initialize the array to store taken hours and minutes
    const takenTimeSlots = [];
  
    // Iterate through all existing quote requests
    existingQuoteRequests.forEach(request => {
      const requestYear = moment(request.date).year();

      // storing day from existing quote request
      let requestDay = moment(request.date).dayOfYear();
      requestDay += 1; // Adjust day because dayOfYear is 1-based

      //storing time from existing quote request
      const requestTime = moment(request.time, format);
  
      //Checking if date chosen from user is a day that has existing quote requests during it
      if (chosenDay === requestDay && chosenYear === requestYear) {
  
        // Add the taken hour and minute to the array
        takenTimeSlots.push({
          hour: requestTime.hour(),
          minute: requestTime.minute(),
        });
      }
    });
  
  
    return takenTimeSlots;
  };


  const handleCancel = () =>{
    window.location.href = 'http://www.coupetonarbre.live/client/quoteRequests';

  }

  return (
    <div className='content'>
      <Helmet>
        <title>{t('createQuoteClient.pageTitle')}</title>
      </Helmet>
      <div id='nav-container'>
        <ClientNavbar />

        <div className='text-align center'>
          <h1 className='heading'>{t('createQuoteClient.header')}</h1>
        </div>


        <div>
          <form onSubmit={handleSubmit} className='form-styles'>
           
            <label className='form-label'>{t('createQuoteClient.descriptionLabel')}</label>
            <input type="text" name="description" className='form-input' value={formData.description} onChange={(e) => handleInputChange('description', e.target.value)} required />

            <label className='form-label'>{t('createQuoteClient.serviceLabel')}</label>
            <select name="service" className='form-select' value={formData.service} onChange={(e) => handleInputChange('service', e.target.value)} required>
              <option value="" disabled>{t('createQuoteClient.selectService')}</option>
              {renderServiceOptions()}
            </select>

           

            <label className='form-label'>{t('createQuoteClient.dateLabel')}</label>
            <DatePicker
              className='form-date'
              selected={formData.date}
              onChange={(date) => handleInputChange('date', date)}
              minDate={moment()}
              filterDate={isDateValid}
              //winter (no work)
              excludeDateIntervals={[
                { start: new Date("2024-11-1"), end: new Date("2025-04-1") },
                { start: new Date("2024-01-1"), end: new Date("2024-04-1") },

              ]}
              //holidays
              excludeDates={[new Date("2024-4-1"), new Date("2024-7-1"),new Date("2024-10-15"),new Date("2024-9-2") ]}

            />

            <label className='form-label'>{t('createQuoteClient.timeLabel')}</label>
            <TimePicker
              showSecond={false}            
              onChange={handleTimeChange}
              format={format}
              use12Hours
              className='form-time'
              allowEmpty={false}
              min={moment().hours(9).minutes(0)}
              max={moment().hours(19).minutes(59)}
              minuteStep={15}

             //disabling hours that CTA is closed
              disabledHours={() => closedHours()}
              
              //disabling time slots that are already taken
              disabledMinutes={(hour) => isTimeTaken().filter(slot => slot.hour === hour).map(slot => slot.minute)}
              disabledSeconds={() => []}

            />

            <button className='form-button' id='submit-btn' type="submit">{t('createQuoteClient.submitButton')}</button>
            <button className='form-button' type='button' onClick={handleCancel}>{t('createQuoteClient.cancelButton')}</button>

          </form>
        </div>
      </div>
    </div>
  );
}

export default CreateQuoteRequestClient;
