import { Link as RouterLink } from 'react-router-dom';
import { Link } from "react-scroll";
import React, { useState, useEffect } from 'react';

import styles from "../styles/Navbar.module.css"
import GlobalStyles from '../styles/GlobalStyles';
import CTALogo from "../images/ctaLogo.png";

import {useAuth} from "../security/Components/AuthProvider";
import { useTranslation } from 'react-i18next';

const lngs = {
  en: { nativeName: 'EN' },
  fr: { nativeName: 'FR' }
};

function MainNavbar() {
  const auth = useAuth()
  const { t, i18n } = useTranslation();

  const handleLanguageChange = (event) => {
    i18n.changeLanguage(event.target.value);
  };

  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const onScroll = () => {
      const vhInPixels = window.innerHeight * 0.15; // for 20vh, adjust the multiplier as needed

      setIsScrolled(window.scrollY > vhInPixels);
    };

    window.addEventListener("scroll", onScroll);

    return () => {
      window.removeEventListener("scroll", onScroll);
    };
  }, []);

  return (
    <>
      <GlobalStyles />
      <nav className={`${styles.nav} ${isScrolled ? styles.navScrolled : ''}`}>
        <img src={CTALogo} alt="CTA Logo" className={`${styles.CTALogo} ${isScrolled ? styles.logoScrolled : ''}`} />
        <ul className={styles.ul}>
          <li className={`${styles.li} ${isScrolled ? styles.liScrolled : ''}`}>
            <RouterLink to='/' className={styles.navLink}>{t('mainNav.home')}</RouterLink>
            <Link activeClass="active" to="intro" spy={true} smooth={true} offset={-130} duration={500} className="nav-link"></Link>
          </li>
          <li className={`${styles.li} ${isScrolled ? styles.liScrolled : ''}`}>
            <Link activeClass="active" to="about" spy={true} smooth={true} offset={-130} duration={500} className="nav-link">{t('mainNav.about')}</Link>
          </li>
          <li className={`${styles.li} ${isScrolled ? styles.liScrolled : ''}`}>
            <Link activeClass="active" to="services" spy={true} smooth={true} offset={-10} duration={500} className="nav-link">{t('mainNav.services')}</Link>
          </li>

          {
            // @ts-ignore
            !auth.isAuthenticated &&
            <li className={`${styles.li} ${isScrolled ? styles.liScrolled : ''}`}>
              <RouterLink to="https://api.coupetonarbre.live/oauth2/authorization/okta" className={styles.li}>{t('mainNav.login')}</RouterLink>
            </li>
          }

          {
            // @ts-ignore
            auth.isAuthenticated &&
          <li className={`${styles.li} ${isScrolled ? styles.liScrolled : ''}`}>
          <form method={"post"} action={"https://api.coupetonarbre.live/cta/logout"}>
            <button className='logout-btn' type={"submit"}>{t('mainNav.logout')}</button>
            </form>
          </li>
          }

          <li className={`${styles.li} ${isScrolled ? styles.liScrolled : ''}`}>
            <select onChange={handleLanguageChange} value={i18n.resolvedLanguage} className={styles.languageDropdown}>
              {Object.keys(lngs).map((lng) => (
                <option key={lng} value={lng}>
                  {lngs[lng].nativeName}
                </option>
              ))}
            </select>
          </li>
        </ul>
      </nav>
    </>
  );
}

export default MainNavbar;