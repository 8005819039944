import React, { useState, useEffect } from 'react';
import Helmet from 'react-helmet';
import axios from 'axios';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import TimePicker from 'rc-time-picker';
import 'rc-time-picker/assets/index.css';
import moment from 'moment';
import AdminNavbar from '../components/AdminNavbar';
import { useParams } from 'react-router-dom';
import '../styles/CreateQuoteRequest.css';
import { useAuth } from '../security/Components/AuthProvider';
import { useTranslation } from 'react-i18next';

const UpdateAppointmentAdmin = () => {
  const { t, i18n } = useTranslation();
    const format = 'h:mm A';
    const auth = useAuth();

    const [quoteRequestId, setQuoteRequestId] = useState('');

    const [clients, setClients] = useState([]);
    const [filteredClients, setFilteredClients] = useState([]);
    const [selectedClientId, setSelectedClientId] = useState('');
    const [selectedClientDetails, setSelectedClientDetails] = useState(null);
    const [selectedClientFirstName, setSelectedClientFirstName] = useState('');
    const [selectedClientLastName, setSelectedClientLastName] = useState('');

    const [employees, setEmployees] = useState([]);
    const [filteredEmployees, setFilteredEmployees] = useState([]);
    const [selectedEmployeeId, setSelectedEmployeeId] = useState('');
    const [selectedEmployeeDetails, setSelectedEmployeeDetails] = useState(null);

    const [searchTerm, setSearchTerm] = useState('');
    const [searchTermEmployee, setSearchTermEmployee] = useState('');

    const [existingAppointments, setExistingAppointments] = useState([]);
    const [existingAppointment, setExistingAppointment] = useState([]);

    const [appointmentPrice, setPrice] = useState('');
    const [formData, setFormData] = useState({
      description: '',
      service: '',
      status: '',
      date: new Date(),
      time: new Date(),
    });

    const { appointmentId } = useParams();

    const statusOptionMappingEN = {
      'Completed' : 'COMPLETED',
      'Postponed' : 'POSTPONED',
      'Cancelled' : 'CANCELLED',
      'Upcoming' : 'UPCOMING',
      'Within 24 Hours' : 'WITHIN_24_HOURS',
      'Pending' : 'PENDING',
      'Within One Week' : 'WITHIN_ONE_WEEK'
    };

    const statusOptionMappingFR = {
      'Complété' : 'COMPLETED',
      'Reporté' : 'POSTPONED',
      'Annulé' : 'CANCELLED',
      'À venir' : 'UPCOMING',
      'Dans les prochaines 24 heures' : 'WITHIN_24_HOURS',
      'En attente' : 'PENDING',
      'En moins d\'une semaine' : 'WITHIN_ONE_WEEK'
    };

    const serviceOptionsMappingEN = {
      'Hedge Trimming': 'HedgeTrimming',
      'Tree Trimming': 'TreeTrimming',
      'Tree Branch Removal': 'TreeBranchRemoval',
      'Small Tree Removal': 'SmallTreeRemoval',
      'Hedge Trimming and Tree Trimming': 'HedgeTrimmingAndTreeTrimming',
      'Hedge Trimming and Tree Branch Removal': 'HedgeTrimmingAndTreeBranchRemoval',
      'Tree Trimming and Tree Branch Removal': 'TreeTrimmingAndTreeBranchRemoval',
      'Hedge Trimming and Tree Trimming and Tree Branch Removal': 'HedgeTrimmingAndTreeTrimmingAndTreeBranchRemoval',
      'Hedge Trimming and Small Tree Removal': 'HedgeTrimmingAndSmallTreeRemoval',
      'Tree Trimming and Small Tree Removal': 'TreeTrimmingAndSmallTreeRemoval',
      'Hedge Trimming and Tree Trimming and Small Tree Removal': 'HedgeTrimmingAndTreeTrimmingAndSmallTreeRemoval',
      'Hedge Trimming and Tree Branch Removal and Small Tree Removal': 'HedgeTrimmingAndTreeBranchRemovalAndSmallTreeRemoval',
      'Tree Trimming and Tree Branch Removal and Small Tree Removal': 'TreeTrimmingAndTreeBranchRemovalAndSmallTreeRemoval',
      'Hedge Trimming and Tree Trimming and Tree Branch Removal and Small Tree Removal': 'HedgeTrimmingAndTreeTrimmingAndTreeBranchRemovalAndSmallTreeRemoval'
    };
    const clarityServiceMapping = {
      'HedgeTrimming': 'Hedge Trimming',
      'TreeTrimming': 'Tree Trimming',
      'TreeBranchRemoval': 'Tree Branch Removal',
      'SmallTreeRemoval': 'Small Tree Removal',
      'HedgeTrimmingAndTreeTrimming': 'Hedge Trimming and Tree Trimming',
      'HedgeTrimmingAndTreeBranchRemoval': 'Hedge Trimming and Tree Branch Removal',
      'TreeTrimmingAndTreeBranchRemoval': 'Tree Trimming and Tree Branch Removal',
      'HedgeTrimmingAndTreeTrimmingAndTreeBranchRemoval': 'Hedge Trimming and Tree Trimming and Tree Branch Removal',
      'HedgeTrimmingAndSmallTreeRemoval': 'Hedge Trimming and Small Tree Removal',
      'TreeTrimmingAndSmallTreeRemoval': 'Tree Trimming and Small Tree Removal',
      'HedgeTrimmingAndTreeTrimmingAndSmallTreeRemoval': 'Hedge Trimming and Tree Trimming and Small Tree Removal',
      'HedgeTrimmingAndTreeBranchRemovalAndSmallTreeRemoval': 'Hedge Trimming and Tree Branch Removal and Small Tree Removal',
      'TreeTrimmingAndTreeBranchRemovalAndSmallTreeRemoval': 'Tree Trimming and Tree Branch Removal and Small Tree Removal',
      'HedgeTrimmingAndTreeTrimmingAndTreeBranchRemovalAndSmallTreeRemoval': 'Hedge Trimming and Tree Trimming and Tree Branch Removal and Small Tree Removal'
  };

  const serviceOptionsMappingFR = {
    'Taille des Haies': 'HedgeTrimming',
    'Taille des Arbres': 'TreeTrimming',
    'Enlèvement des Branches d\'Arbre' : 'TreeBranchRemoval',
    'Enlèvement des Petits Arbres': 'SmallTreeRemoval',
    'Taille de Haies et d\'Arbres': 'HedgeTrimmingAndTreeTrimming',
    'Taille des Haies et Enlèvement des Branches d\'Arbres': 'HedgeTrimmingAndTreeBranchRemoval',
    'Taille des Arbres et Enlèvement des Branches': 'TreeTrimmingAndTreeBranchRemoval',
    'Taille de Haies, Taille d\'Arbres et Enlèvement de Branches d\'Arbres': 'HedgeTrimmingAndTreeTrimmingAndTreeBranchRemoval',
    'Taille de Haies et Enlèvement de Petits Arbres': 'HedgeTrimmingAndSmallTreeRemoval',
    'Taille des Arbres et Enlèvement des Petits Arbres': 'TreeTrimmingAndSmallTreeRemoval',
    'Taille de Haies, Taille d\'Arbres et Enlèvement de Petits Arbres': 'HedgeTrimmingAndTreeTrimmingAndSmallTreeRemoval',
    'Taille de Haies, Enlèvement de Branches d\'Arbres et Enlèvement de Petits Arbres': 'HedgeTrimmingAndTreeBranchRemovalAndSmallTreeRemoval',
    'Taille des Arbres, Enlèvement des Branches et Enlèvement des Petits Arbres': 'TreeTrimmingAndTreeBranchRemovalAndSmallTreeRemoval',
    'Taille de Haies et Taille d\'Arbres et Enlèvement de Branches d\'Arbres et Enlèvement de Petits Arbres': 'HedgeTrimmingAndTreeTrimmingAndTreeBranchRemovalAndSmallTreeRemoval'
  };

    useEffect(() => {
        // @ts-ignore
        if (!auth.isAuthenticated) {
          window.location.href = 'http://www.coupetonarbre.live/';
        }
    }, []);



    useEffect(() => {
        axios.get(`https://api.coupetonarbre.live/appointments/${appointmentId}/admin`, {
          headers: {
            'X-XSRF-TOKEN': auth.getXsrfToken(),
          },
        })
        .then(response => {
        const appointmentData = response.data;
        setFormData({
            description: appointmentData.description,
            service: appointmentData.service,
            status: appointmentData.status,
            date: new Date(appointmentData.date),
            time: moment(appointmentData.time, format).format(format), 
        });
        setSelectedClientId(appointmentData.clientId);
        setSelectedEmployeeId(appointmentData.employeeId);
        setQuoteRequestId(appointmentData.quoteRequestId)
        setPrice(appointmentData.price)
        setExistingAppointment(appointmentData)
        })
        .catch(error => {
        console.error('Error fetching existing appointment:', error);
        });
    }, [appointmentId, auth]);

    useEffect(() => {
        // Add console logs to trace the execution
        console.log('Fetching existing appointments for admin...');
        axios.get("https://api.coupetonarbre.live/appointments/admin", {
          headers: {
            // @ts-ignore
            'X-XSRF-TOKEN': auth.getXsrfToken()
          }
        })
          .then(response => {
            setExistingAppointments(response.data);
          })
          .catch(error => {
            console.error('Error fetching existing appointments:', error);
          });
    }, [auth]);

    useEffect(() => {
        axios.get("https://api.coupetonarbre.live/users/clients", {
          headers: {
            // @ts-ignore
            'X-XSRF-TOKEN': auth.getXsrfToken()
          }
        })
          .then(response => {
            setClients(response.data);
            setFilteredClients(response.data);
          })
          .catch(error => {
            console.error('Error:', error);
          });
    }, [auth]);

    useEffect(() => {
        axios.get("https://api.coupetonarbre.live/users/employees", {
        headers: {
            // @ts-ignore
            'X-XSRF-TOKEN': auth.getXsrfToken()
        }
        })
        .then(r => {
            setEmployees(r.data);
            setFilteredEmployees(r.data);
        })
        .catch(e => {
            console.error('Error:', e);
        });
    }, [auth]);

    useEffect(() => {
        if (selectedClientId) {
          axios.get(`https://api.coupetonarbre.live/users/clients/${selectedClientId}`, {
            headers: {
              // @ts-ignore
              'X-XSRF-TOKEN': auth.getXsrfToken()
            }
          })
            .then(response => {
              setSelectedClientDetails(response.data);
              setSelectedClientFirstName(response.data.firstName)
              setSelectedClientLastName(response.data.lastName)
            })
            .catch(error => {
              console.error('Error:', error);
            });
        }
    }, [selectedClientId]);

    useEffect(() => {
        if (selectedEmployeeId) {
        axios.get(`https://api.coupetonarbre.live/users/employees/${selectedEmployeeId}`, {
            headers: {
            // @ts-ignore
            'X-XSRF-TOKEN': auth.getXsrfToken()
            }
        })
            .then(response => {
            setSelectedEmployeeDetails(response.data);
            })
            .catch(error => {
            console.error('Error:', error);
            });
        }
    }, [selectedEmployeeId]);

    const handleEmployeeChange = (event) => {
        const employeeId = event.target.value;
        setSelectedEmployeeId(employeeId);
    };

    
    const handleInputChange = (name, value) => {
      if (name === 'date') {
          const newDate = moment(value).dayOfYear();
          const originalDate = moment(existingAppointment.date).dayOfYear();
          const newYear = moment(value).year();
          const originalYear = moment(existingAppointment.date).year();
  
          if (newDate > originalDate || newYear > originalYear) {
              // If the new date is greater than the original date, set status to "Postponed"
              setFormData({
                  ...formData,
                  [name]: value,
                  status: statusOptionMappingEN['Postponed'],
              });
          } else {
              // Otherwise, update the form data normally
              setFormData({
                  ...formData,
                  [name]: value,
              });
          }
      } else {
          // For other inputs, update the form data normally
          setFormData({
              ...formData,
              [name]: value,
          });
      }
  };
  

    const handleTimeChange = (value) => {
        // Only update the time if it's different from the original time
        const newTime = value ? value.format(format) : null;
        const existingTime = formData.time;
      
        // Only update the time if it's different from the original time
        if (newTime !== existingTime) {
          setFormData(prevFormData => ({
            ...prevFormData,
            time: newTime,
          }));
        }
    };

    const handleSubmit = (event) => {
        event.preventDefault();

        const newDate = moment(formData.date).dayOfYear();

        const originalDate = moment(existingAppointment.date).dayOfYear();

        const newYear = moment(formData.date).year();

        const originalYear = moment(existingAppointment.date).year();

        console.log(newDate)
        console.log(originalDate)
        if (newDate > originalDate || newYear > originalYear) {
          // Send email notification
          const email = {
            recipient: selectedClientDetails.email,
            subject: "Appointment Postponed",
            message: `Hello, ${existingAppointment.clientFirstName}!\n\nYour appointment has been postponed.\n\nDescription: ${formData.description}\nService: ${clarityServiceMapping[formData.service]}\n\nThe new scheduled date is: ${moment(formData.date).format('MMMM DD, YYYY')} at ${moment(formData.time, format).format(format)}.\n\nThank you for your understanding.`,
          };
        
          axios.post('https://api.coupetonarbre.live/email', email, {
            headers: {
              'X-XSRF-TOKEN': auth.getXsrfToken(),
            },
          })
            .then(response => {
              console.log('Email notification sent:', response.data);
            })
            .catch(error => {
              console.error('Error sending email notification:', error);
            });
        }

        // Check if the status has been updated to "Completed" from another status
    const isCompletedFromOtherStatus = formData.status === statusOptionMappingEN['Completed'] && existingAppointment.status !== statusOptionMappingEN['Completed'];

    if (isCompletedFromOtherStatus) {
        // Send email to the client asking for feedback
        const feedbackEmail = {
            recipient: selectedClientDetails.email,
            subject: "Your experience!",
            message: `Hello, ${existingAppointment.clientFirstName}!\n\nWe hope you enjoyed your appointment with us. Your feedback is valuable to us. Please take a moment to share your experience with us on our website.\n\nThank you!`,
        };

        axios.post('https://api.coupetonarbre.live/email', feedbackEmail, {
            headers: {
                'X-XSRF-TOKEN': auth.getXsrfToken(),
            },
        })
        .then(response => {
            console.log('Feedback email sent:', response.data);
        })
        .catch(error => {
            console.error('Error sending feedback email:', error);
        });
    }

    
        const clientFirstName = selectedClientDetails && selectedClientDetails.firstName ? selectedClientDetails.firstName : null;
        const clientLastName = selectedClientDetails && selectedClientDetails.lastName ? selectedClientDetails.lastName : null;
    
        const employeeFirstName = selectedEmployeeDetails && selectedEmployeeDetails.firstName ? selectedEmployeeDetails.firstName : null;
        const employeeLastName = selectedEmployeeDetails && selectedEmployeeDetails.lastName ? selectedEmployeeDetails.lastName : null;
    
        const requestBody = {
            quoteRequestId: quoteRequestId,
            clientId: selectedClientId,
            clientFirstName: clientFirstName,
            clientLastName: clientLastName,
            address: selectedClientDetails && selectedClientDetails.address ? selectedClientDetails.address : null,
            price: appointmentPrice,
            employeeId: selectedEmployeeId,
            employeeFirstName: employeeFirstName,
            employeeLastName: employeeLastName,
            ...formData,
        };
    
        axios.put(`https://api.coupetonarbre.live/appointments/${appointmentId}/admin`, requestBody, {
          headers: {
            'X-XSRF-TOKEN': auth.getXsrfToken()
          }
        })
        .then(response => {
          console.log(response.data);
          window.location.href = 'http://www.coupetonarbre.live/admin/appointments';
        })
        .catch(error => {
          console.error('Error during request:', error);
        });
        
      };

    const handleSearchTermChangeEmployee = (event) => {
        setSearchTermEmployee(event.target.value);
        filterEmployees(event.target.value);
    };

    //Filtering functionality
    const filterEmployees = (term) => {
        const filtered = employees.filter(employee =>
        employee.firstName.toLowerCase().includes(term.toLowerCase()) ||
        employee.lastName.toLowerCase().includes(term.toLowerCase())
        );
        setFilteredEmployees(filtered);
    };

    const filterClients = (term) => {
        const filtered = clients.filter(client =>
        client.firstName.toLowerCase().includes(term.toLowerCase()) ||
        client.lastName.toLowerCase().includes(term.toLowerCase())
        );
        setFilteredClients(filtered);
    };

    const renderClientOptions = () => {
        return filteredClients.map(client => (
        <option key={client.clientId} value={client.clientId}>
            {client.firstName} {client.lastName}
        </option>
        ));
    };

    //Dropdown for employees
    const renderEmployeeOptions = () => {
        return filteredEmployees.map(employee => (
        <option key={employee.employeeId} value={employee.employeeId}>
            {employee.firstName} {employee.lastName}
        </option>
        ));
    };

    const renderServiceOptions = () => {
      const currentLanguage = i18n.language;
      let serviceOptions;
      let serviceMapping;

      // Determine which service mapping to use based on the current language
      if (currentLanguage === 'fr') {
        serviceOptions = Object.keys(serviceOptionsMappingFR);
        serviceMapping = serviceOptionsMappingFR;
      } else {
        serviceOptions = Object.keys(serviceOptionsMappingEN);
        serviceMapping = serviceOptionsMappingEN;
      }

      return serviceOptions.map(option => (
        <option key={option} value={serviceMapping[option]}>
          {option}
        </option>
      ));
    };

    const renderStatusOptions = () => {
      const currentLanguage = i18n.language;
      let statusOptions;
      let statusMapping;
      if (currentLanguage === 'fr') {
        statusOptions = Object.keys(statusOptionMappingFR);
        statusMapping = statusOptionMappingFR;
      } else {
        statusOptions = Object.keys(statusOptionMappingEN);
        statusMapping = statusOptionMappingEN;
      }

        return statusOptions.map(option => (
        <option key={option} value={statusMapping[option]}>
          {option}
        </option>
        ));
    };

    const isDateValid = (date) => {
        return moment(date).isSameOrAfter(moment(), 'day');
    };
    
    //Returns a list of hours that should be disabled
    const closedHours = () => {
        const dayOfWeek = moment(formData.date).day();

        //weekdays
        if (dayOfWeek >= 1 && dayOfWeek <= 5) {
            return [0, 1, 2, 3, 4, 5, 6, 7, 20, 21, 22, 23];
        }
        //saturday
            else if (dayOfWeek === 6) {
            return [0, 1, 2, 3, 4, 5, 6, 7, 8, 18, 19, 20, 21, 22, 23];
        } 
        //sunday
        else if (dayOfWeek === 0) {
            return [0, 1, 2, 3, 4, 5, 6, 7, 8, 17, 18, 19, 20, 21, 22, 23];
        }

        return false;
    };

    //Returns time slots that are already taken
    const isTimeTaken = () => {
        const chosenDay = moment(formData.date).dayOfYear();
        const chosenYear = moment(formData.date).year();

    
        console.log("Chosen day is " + chosenDay);
    
        // Initialize the array to store taken hours and minutes
        const takenTimeSlots = [];
    
        // Iterate through all existing appointments
        existingAppointments.forEach(request => {
            const requestYear = moment(request.date).year();
            let requestDay = moment(request.date).dayOfYear();
            requestDay += 1; // Adjust day because dayOfYear is 1-based
            const requestTime = moment(request.time, format);
        
            console.log("Already created days are " + requestDay);
            console.log("Hours and minute are " + requestTime.hour() + " " + requestTime.minute());
        
            if (chosenDay === requestDay && chosenYear === requestYear) {
                console.log("They are the same day");
        
                // Add the taken hour and minute to the array
                takenTimeSlots.push({
                hour: requestTime.hour(),
                minute: requestTime.minute(),
                });
            }
        });
    
        console.log(takenTimeSlots);
    
        return takenTimeSlots;
    };

    const handleCancel = () => {
        window.location.href = 'http://www.coupetonarbre.live/admin/appointments';
    };

    return (
        <div className='content'>
          <Helmet>
            <title>{t('updateAppointmentAdmin.pageTitle')}</title>
          </Helmet>
          <div id='nav-container'>
            <AdminNavbar />
    
            <div className='text-align center'>
              <h1 className='heading'>{t('updateAppointmentAdmin.header')} {selectedClientFirstName} {selectedClientLastName}</h1>
            </div>
    
            <div>
              <form onSubmit={handleSubmit} className='form-styles'>
                <label className='form-label'>{t('updateAppointmentAdmin.employeeLabel')}</label>
                <input
                  type="text"
                  name="search"
                  className='form-input'
                  value={searchTermEmployee}
                  onChange={handleSearchTermChangeEmployee}
                  placeholder={t('updateAppointmentAdmin.employeeInputPlace')}
                />
                <select name='employee' className='form-select' id='employee-select' value={selectedEmployeeId} onChange={handleEmployeeChange}>
                <option value="" >{t('updateAppointmentAdmin.selectEmployee')}</option>
                  {renderEmployeeOptions()}
                </select>
    
                <label className='form-label'>{t('updateAppointmentAdmin.descriptionLabel')}</label>
                <input type="text" name="description" className='form-input' value={formData.description} onChange={(e) => handleInputChange('description', e.target.value)} required />
    
                <label className='form-label'>{t('updateAppointmentAdmin.serviceLabel')}</label>
                <select name="service" className='form-select' value={formData.service} onChange={(e) => handleInputChange('service', e.target.value)} required>
                  <option value="" disabled>{t('updateAppointmentAdmin.selectService')}</option>
                  {renderServiceOptions()}
                </select>
    
                <label className='form-label'>{t('updateAppointmentAdmin.dateLabel')}</label>
                <DatePicker
                  className='form-date'
                  selected={formData.date}
                  onChange={(date) => handleInputChange('date', date)}
                  minDate={moment()}
                  filterDate={isDateValid}
                  excludeDateIntervals={[
                    { start: new Date("2024-11-1"), end: new Date("2025-04-1") },
                    { start: new Date("2024-01-1"), end: new Date("2024-04-1") },
    
                  ]}
                  excludeDates={[new Date("2024-4-1"), new Date("2024-7-1"), new Date("2024-10-15"), new Date("2024-9-2")]}
                />
    
              <label className='form-label'>{t('updateAppointmentAdmin.timeLabel')}</label>
              <TimePicker
                showSecond={false}
                value={moment(formData.time, format)}  // Ensure the value is set directly using moment
                onChange={handleTimeChange}
                format={format}
                use12Hours
                className='form-time'
                allowEmpty={false}
                min={moment().hours(9).minutes(0)}
                max={moment().hours(19).minutes(59)}
                minuteStep={15}
                disabledHours={() => closedHours()}
                disabledMinutes={(hour) => isTimeTaken().filter(slot => slot.hour === hour).map(slot => slot.minute)}
                disabledSeconds={() => []}
              />
              <label className='form-label'>{t('updateAppointmentAdmin.statusLabel')}</label>
                <select name="status" className='form-select' value={formData.status} onChange={(e) => handleInputChange('status', e.target.value)} required>
                  <option value="" disabled>{t('updateAppointmentAdmin.selectStatus')}</option>
                  {renderStatusOptions()}
                </select>
                <button className='form-button' type="submit">{t('updateAppointmentAdmin.updateButton')}</button>
                
                <button className='form-button' type='button' onClick={handleCancel}>{t('updateAppointmentAdmin.cancelButton')}</button>
              </form>
            </div>
          </div>
        </div>
    );
}

export default UpdateAppointmentAdmin;