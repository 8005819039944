import React, { useState, useEffect } from 'react';
import Helmet from 'react-helmet';
import axios from 'axios';
// import DatePicker from 'react-datepicker';
// import { useNavigate } from 'react-router-dom';

import 'react-datepicker/dist/react-datepicker.css';
// import TimePicker from 'rc-time-picker';
import 'rc-time-picker/assets/index.css';
import moment from 'moment';
import EmployeeNavbar from '../components/EmployeeNavbar';
import { useParams } from 'react-router-dom';
import '../styles/CreateQuoteRequest.css';  // Update the CSS file name if needed
import { useAuth } from '../security/Components/AuthProvider';
import { useTranslation } from 'react-i18next';

const format = 'h:mm a';



const UpdateQuoteRequestEmployee = () => {
  const { t, i18n } = useTranslation();

  const [existingQuoteRequestData, setExistingQuoteRequestData] = useState(null);

  const auth = useAuth();
  const [formData, setFormData] = useState({
    description: '',
    service: '',
    status: '',
    price: '',
    date: new Date(),
    time: new Date(),
  });
  
  const { quoteRequestId } = useParams();  // Assuming the parameter name is quoteRequestId

  const statusOptionMappingFR = {
    'Devis envoyé': 'QUOTE_SENT',
    'Devis accepté': 'QUOTE_ACCEPTED',
    'Devis refusé': 'QUOTE_REFUSED',
    'EN attente': 'PENDING'
  };
  
  const statusOptionMappingEN={
     'Quote Sent' : 'QUOTE_SENT',
     'Quote Accepted' : 'QUOTE_ACCEPTED',
     'Quote Refused' : 'QUOTE_REFUSED',
     'Pending' : 'PENDING',
  }

  useEffect(() => {
    // @ts-ignore
    if (!auth.isAuthenticated) {
      window.location.href = 'http://www.coupetonarbre.live/';
    }
  }, []);
  useEffect(() => {
    axios.get(`https://api.coupetonarbre.live/quoteRequests/${quoteRequestId}`, {
      headers: {
        'X-XSRF-TOKEN': auth.getXsrfToken(),
      },
    })
      .then(response => {
        const quoteRequestData = response.data;
        setExistingQuoteRequestData(quoteRequestData)

        setFormData({
          price : quoteRequestData.price,
          description: quoteRequestData.description,
          service: quoteRequestData.service,
          status: quoteRequestData.status,
          date: new Date(quoteRequestData.date),
          time: moment(quoteRequestData.time, format).format(format), 
        });
      })
      .catch(error => {
        console.error('Error fetching existing quote request:', error);
      });
  }, [quoteRequestId, auth]);
  
  


  const handleInputChange = (name, value) => {
    setFormData({
      ...formData,
      [name]: value,
    });
  };


  


  const handleSubmit = (event) => {
    event.preventDefault();

    const requestBody = {
      'clientId' : existingQuoteRequestData.clientId,
      'clientFirstName' : existingQuoteRequestData.clientFirstName,
      'clientLastName' : existingQuoteRequestData.clientLastName,
      'time' : existingQuoteRequestData.time,
      'date' : existingQuoteRequestData.date,
      'description' : existingQuoteRequestData.description,
      'address' : existingQuoteRequestData.address,
      'service' : existingQuoteRequestData.service,
      'employeeId' :existingQuoteRequestData.employeeId,
      'employeeFirstName' : existingQuoteRequestData.employeeFirstName,
      'employeeLastName' : existingQuoteRequestData.employeeLastName,
      ...formData,
    };

    axios.put(`https://api.coupetonarbre.live/quoteRequests/employee/${quoteRequestId}`, requestBody, {
      headers: {
        'X-XSRF-TOKEN': auth.getXsrfToken()
      }
    })
    .then(response => {
      console.log(response.data);
      window.location.href = 'http://www.coupetonarbre.live/employee/quoteRequests';
    })
    .catch(error => {
      console.error('Error during request:', error);
    });
    
  };

  const renderStatusOptions = () => {
    const currentLanguage = i18n.language;
    let statusOptions;
    let statusMapping;
    if (currentLanguage === 'fr') {
      statusOptions = Object.keys(statusOptionMappingFR);
      statusMapping = statusOptionMappingFR;
    } else {
      statusOptions = Object.keys(statusOptionMappingEN);
      statusMapping = statusOptionMappingEN;
    }

      return statusOptions.map(option => (
      <option key={option} value={statusMapping[option]}>
        {option}
      </option>
      ));
  };

  return (
    <div className='content'>
      <Helmet>
        <title>{t('updateQuoteEmployee.pageTitle')}</title>
      </Helmet>
      <div id='nav-container'>
        <EmployeeNavbar />

        <div className='text-align center'>
          <h1 className='heading'>{t('updateQuoteEmployee.header')}</h1>
        </div>

        <div>
          <form onSubmit={handleSubmit} className='form-styles'>

           

            <label className='form-label'>{t('updateQuoteEmployee.priceLabel')}</label>
            <input type="text" name="price" className='form-input' value={formData.price} onChange={(e) => handleInputChange('price', e.target.value)} required />

            
        <label className='form-label'>{t('updateQuoteEmployee.statusLabel')}</label>
            <select name="status" className='form-select' value={formData.status} onChange={(e) => handleInputChange('status', e.target.value)} required>
              <option value="" disabled>{t('updateQuoteEmployee.selectStatus')}</option>
              {renderStatusOptions()}
            </select>
            <button className='form-button' type="submit">{t('updateQuoteEmployee.updateButton')}</button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default UpdateQuoteRequestEmployee;