import React from "react";
import styles from '../styles/Footer.module.css'
import background from "../images/hedgeWallDark1.png";
import CTALogo from "../images/ctaLogoZoomed.png";
import { useTranslation } from 'react-i18next';
import {useAuth} from "../security/Components/AuthProvider";

const Footer = () => {
    const auth = useAuth()
    const { t } = useTranslation();
    return (
        <div data-cy="footer" className={styles.footer} style={{ backgroundImage: `url(${background})`}}>
            <div className={styles.links}>
                <div className={styles.links_div_img}>
                    <img data-cy="CTALogo" src={CTALogo} alt="CTA Logo" className={styles.CTALogo} />
                </div>
                <div data-cy="linkHeaders" className={styles.links_div}>
                    <h4>{t('footer.sectionOne.header')}</h4>
                    <a data-cy="links" className={styles.links_a} href="http://www.coupetonarbre.live">
                        <p>{t('footer.sectionOne.home')}</p>
                    </a>
                    <a data-cy="links" className={styles.links_a} href="http://www.coupetonarbre.live">
                        <p>{t('footer.sectionOne.ourWork')}</p>
                    </a>

                    {
                    // @ts-ignore
                    auth.userRoles().includes("Client") &&
                    <a data-cy="links" className={styles.links_a} href="http://www.coupetonarbre.live/profile">
                        <p>{t('footer.sectionOne.profile')}</p>
                    </a>
                    }

                    {
                    // @ts-ignore
                    auth.userRoles().includes("Employee") &&
                    <a data-cy="links" className={styles.links_a} href="http://www.coupetonarbre.live/employee/profile">
                        <p>{t('footer.sectionOne.profile')}</p>
                    </a>
                    }
                </div>
                <div data-cy="linkHeaders" className={styles.links_div}>
                    <h4>{t('footer.sectionTwo.header')}</h4>
                    <p data-cy="info">{t('footer.sectionTwo.serviceOne')}</p>
                    <p data-cy="info">{t('footer.sectionTwo.serviceTwo')}</p>
                </div>
                <div data-cy="linkHeaders" className={styles.links_div}>
                    <h4>{t('footer.sectionThree.header')}</h4>
                    <a data-cy="links" className={styles.links_a} href="https://maps.app.goo.gl/vkK4JbvrPjtQ4qeeA">
                        <p>{t('footer.sectionThree.address')}</p>
                    </a>
                    <a data-cy="links" className={styles.links_a} href="tel:4385046055">
                        <p>{t('footer.sectionThree.phone')}</p>
                    </a>
                </div>
                <div data-cy="linkHeaders" className={styles.links_div}>
                    <h4>{t('footer.sectionFour.header')}</h4>
                    <p data-cy="info">{t('footer.sectionFour.week')}</p>
                    <p data-cy="info">{t('footer.sectionFour.saturday')}</p>
                    <p data-cy="info">{t('footer.sectionFour.sunday')}</p>
                </div>
            </div>

            <hr data-cy="line"></hr>

            <div data-cy="copyright" className={styles.footer_copyright}>
                <p>
                    {new Date().getFullYear()} &copy; {t('footer.copyright')}
                </p>
            </div>
        </div>
    );
};

export default Footer;
