import React, { useState, useEffect } from 'react';
import Helmet from 'react-helmet';
import axios from 'axios';
import EmployeeNavbar from '../components/EmployeeNavbar';
import '../styles/QuotesAdmin.css';
import { useTranslation } from 'react-i18next';
import { useAuth } from "../security/Components/AuthProvider";

function QuotesEmployee() {
  const { t, i18n } = useTranslation();
  const [quoteRequests, setQuoteRequests] = useState([]);
  const [currentPage, setCurrentPage] = useState(1); 
  const [quoteRequestsPerPage] = useState(5); 

  const auth = useAuth();

  const handleUpdate = (quoteRequestId) => {
    window.location.href = `/employee/quotes/updateQuoteRequest/${quoteRequestId}`;
  };

  const getAllQuoteRequests = async () => {
    axios.get("https://api.coupetonarbre.live/quoteRequests/employee", {
      headers: {
        'X-XSRF-TOKEN': auth.getXsrfToken()
      }
    })
      .then(response => {
        setQuoteRequests(response.data);
      })
      .catch(error => {
        console.error('Error fetching quote requests:', error);
      });
  };

  useEffect(() => {
    getAllQuoteRequests();
  }, []);

  const formatDate = (dateString) => {
    const lang = i18n.language;

    const date = new Date(dateString);
  
    if (lang === 'fr') {
      const day = date.getDate();
      const month = date.getMonth() + 1;
      const year = date.getFullYear();

      return `${day < 10 ? '0' + day : day}/${month < 10 ? '0' + month : month}/${year}`;
    } else {
      const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
      return date.toLocaleDateString(undefined, options);
    }
  };

  const getStatusStyle = (status) => {
    switch (status.toLowerCase()) {
      case 'quote_accepted':
        return { backgroundColor: 'lightgreen' };
      case 'pending':
        return { backgroundColor: 'lightyellow' };
      case 'quote_refused':
        return { backgroundColor: 'lightcoral' };
      default:
        return {};
    }
  };

  const getServiceTranslation = (service) => {
    return t(`quotesEmployee.serviceEnum.${service}`);
  };

  const getStatusTranslation = (status) => {
    return t(`quotesEmployee.statusEnum.${status}`);
  };
  const handlePaginationClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const renderPaginationButtons = () => {
    const totalPages = Math.ceil(quoteRequests.length / quoteRequestsPerPage);

    const paginationButtons = [];
    for (let i = 1; i <= totalPages; i++) {
      paginationButtons.push(
        <button
          key={i}
          className={`page-link ${currentPage === i ? 'active' : ''}`}
          onClick={() => handlePaginationClick(i)}
        >
          {i}
        </button>
      );
    }
    return paginationButtons;
  };
 // Logic for pagination
 const indexOfLastQuoteRequests = currentPage * quoteRequestsPerPage;
 const indexOfFirstQuoteRequests = indexOfLastQuoteRequests - quoteRequestsPerPage;
 const currentQuoteRequests = quoteRequests.slice(indexOfFirstQuoteRequests, indexOfLastQuoteRequests);

  return (
    <div className='content'>
      <Helmet>
        <title>{t('quotesEmployee.pageTitle')}</title>
      </Helmet>
      <div id='nav-container'>
        <EmployeeNavbar />
      </div>
      <div className='header-container'>
        <h1>{t('quotesEmployee.header')}</h1>
      </div>

      <div>
        <table>
          <thead>
            <tr>
              <th>{t('quotesEmployee.table.firstName')}</th>
              <th>{t('quotesEmployee.table.lastName')}</th>
              <th>{t('quotesEmployee.table.time')}</th>
              <th>{t('quotesEmployee.table.date')}</th>
              <th>{t('quotesEmployee.table.address')}</th>
              <th>{t('quotesEmployee.table.price')}</th>
              <th>{t('quotesEmployee.table.service')}</th>
              <th>{t('quotesEmployee.table.status')}</th>
              <th>{t('quotesEmployee.table.actions')}</th>
            </tr>
          </thead>
          <tbody>
            {currentQuoteRequests.map((quoteRequest) => (
              <React.Fragment key={quoteRequest.quoteRequestId}>
                <tr>
                  <td>{quoteRequest.clientFirstName}</td>
                  <td>{quoteRequest.clientLastName}</td>
                  <td>{quoteRequest.time}</td>
                  <td>{formatDate(quoteRequest.date)}</td>
                  <td>{quoteRequest.address}</td>
                  <td>{quoteRequest.price ? `$${quoteRequest.price}` : t('quotesEmployee.table.tbd')}</td>
                  <td>{getServiceTranslation(quoteRequest.service)}</td>
                  <td style={getStatusStyle(quoteRequest.status)}>{getStatusTranslation(quoteRequest.status)}</td>
                  <td>
                    <button className='buttons edit-button' onClick={() => handleUpdate(quoteRequest.quoteRequestId)}>{t('quotesEmployee.table.editButton')}</button>
                  </td>
                </tr>
              </React.Fragment>
            ))}
          </tbody>
        </table>
        <div>
          <div className="pagination">
            {renderPaginationButtons()}
          </div>
          </div>
      </div>
      <br></br>        
      <br></br>
      <br></br>
    </div>
  );
}

export default QuotesEmployee;