import React, { useState } from 'react';

import styles from "../styles/HomeServices.module.css";
import hedges from "../images/hedges.jpg";

import { useTranslation } from 'react-i18next';

const HomeServices = () => {
    const { t } = useTranslation();

    const [hoveredService, setHoveredService] = useState(null);
    const serviceImages = document.querySelectorAll('.serviceImageWrapper');

    const servicesInfo = [
        { key: 'subtitle1', img: hedges },
        { key: 'subtitle2', img: hedges },
        { key: 'subtitle3', img: hedges },
        { key: 'subtitle4', img: hedges },
    ];

    serviceImages.forEach(image => {
        image.addEventListener('mouseenter', () => {
          const serviceId = image.dataset.service;
          const correspondingListItem = document.querySelector(`.servicesList li[data-service="${serviceId}"]`);
          correspondingListItem.classList.add('underline');
        });
        
        image.addEventListener('mouseleave', () => {
          const serviceId = image.dataset.service;
          const correspondingListItem = document.querySelector(`.servicesList li[data-service="${serviceId}"]`);
          correspondingListItem.classList.remove('underline');
        });
    });

    return (
        <section id="services" className={styles.homeServices}>
            <div className={styles.servicesContent}>
                <div className={styles.textSide}>
                    <h2 className={styles.servicesTitle}>{t('homeServices.title')}</h2>
                    <ul className={styles.servicesList}>
                        {servicesInfo.map((service, index) => (
                            <li
                                key={service.key}
                                className={hoveredService === index ? styles.serviceItemHovered : ''}
                                onMouseEnter={() => setHoveredService(index)}
                                onMouseLeave={() => setHoveredService(null)}
                            >
                                {t(`homeServices.${service.key}`)}
                            </li>
                        ))}
                    </ul>
                    <p className={styles.servicesNote}>{t('homeServices.note')}</p>
                </div>
                <div className={styles.imageSide}>
                    {servicesInfo.map((service, index) => (
                        <img
                            key={service.key}
                            src={service.img}
                            alt={t(`homeServices.${service.key}`)}
                            className={styles.serviceImageWrapper}
                            onMouseEnter={() => setHoveredService(index)}
                            onMouseLeave={() => setHoveredService(null)}
                        />
                    ))}
                </div>
            </div>
        </section>
  );
};

export default HomeServices;
