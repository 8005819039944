import React from 'react';
import '../styles/HomeAbout.css'; // Make sure the corresponding CSS file is updated
import { useTranslation } from 'react-i18next';

const HomeAbout = () => {
  const { t } = useTranslation();

  return (
    <section id='about' className="home-about-container">
      <div className="home-about-content">
        <h2>{t('homeAbout.title')}</h2>
        <div className="home-about-details">
          <div className="detail">
            <div className="detail-icon">{/* Icon placeholder or <img> tag if you have icons */}</div>
            <div className="detail-info">
              <h3>{t('homeAbout.subtitle1')}</h3>
              <p>{t('homeAbout.text1')}</p>
            </div>
          </div>
          <div className="detail">
            <div className="detail-icon">{/* Icon placeholder or <img> tag if you have icons */}</div>
            <div className="detail-info">
              <h3>{t('homeAbout.subtitle2')}</h3>
              <p>{t('homeAbout.text2')}</p>
            </div>
          </div>
          <div className="detail">
            <div className="detail-icon">{/* Icon placeholder or <img> tag if you have icons */}</div>
            <div className="detail-info">
              <h3>{t('homeAbout.subtitle3')}</h3>
              <p>{t('homeAbout.text3')}</p>
            </div>
          </div>
        </div>
        <div className="home-about-stats">
          {/* Previous stats content */}
        </div>
      </div>
    </section>
  );
};

export default HomeAbout;
