import React, { useState, useEffect } from 'react';
import Helmet from 'react-helmet'
import axios from 'axios';
import AdminNavbar from '../components/AdminNavbar';
import { useParams } from 'react-router-dom';
import '../styles/UpdateClientAdmin.css';
import { useTranslation } from 'react-i18next';
import swal from 'sweetalert';

function UpdateClientAdmin() {
  const { t } = useTranslation();
  const { clientId } = useParams();
  const [selectedClientDetails, setSelectedClientDetails] = useState(null);
  const [updateFormData, setUpdateFormData] = useState({
    clientId: '',
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    address: '',
  });

  const validateEmail = (email) => {
    const pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return pattern.test(email);
  };

  const validatePhoneNumber = (phoneNumber) => {
    const pattern = /^\+?1?\s*\(?(\d{3})\)?[\s.-]?\s*(\d{3})[\s.-]?\s*(\d{4})$/;
    return pattern.test(phoneNumber);
  };

  useEffect(() => {
    axios.get(`https://api.coupetonarbre.live/users/clients/${clientId}`)
      .then(response => {
        setSelectedClientDetails(response.data);
        setUpdateFormData({
          clientId: response.data.clientId,
          firstName: response.data.firstName,
          lastName: response.data.lastName,
          email: response.data.email,
          phoneNumber: response.data.phoneNumber,
          address: response.data.address,
        });
      })
      .catch(error => {
        console.error('Error:', error);
      });
  }, [clientId]);

  const handleUpdateFormChange = (e) => {
    setUpdateFormData({
      ...updateFormData,
      [e.target.name]: e.target.value,
    });
  };

  const handleUpdateButtonClick = () => {
    const { clientId, ...updateData } = updateFormData;

    if (!validateEmail(updateData.email) && !validatePhoneNumber(updateData.phoneNumber)) {
      swal(t('clientProfile.profileCreation.errorBothHeader'), t('clientProfile.profileCreation.errorBothBody'), 'error')
      return;
    } else if (!validatePhoneNumber(updateData.phoneNumber)) {
      swal(t('clientProfile.profileCreation.errorPhoneHeader'), t('clientProfile.profileCreation.errorPhoneBody'), 'error');
      return;
    } else if (!validateEmail(updateData.email)) {
      swal(t('clientProfile.profileCreation.errorEmailHeader'), t('clientProfile.profileCreation.errorEmailBody'), 'error');
      return;
    }

    const formattedPhoneNumber = updateData.phoneNumber.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');

    // Send a PUT request to update the client
    axios.put(`https://api.coupetonarbre.live/users/clients/${clientId}`, {...updateData, phoneNumber: formattedPhoneNumber})
      .then(response => {
        // Handle the response, e.g., update state or show a success message
        console.log('Client updated successfully:', response.data);
        window.location.href = `/admin/accounts`;

      })
      .catch(error => console.error('Error updating client:', error));
  };

  return (
    <div className='content'>
      <Helmet>
        <title>{t('updateClientAdmin.pageTitle')}</title>
      </Helmet>
      <div id='nav-container'>
        <AdminNavbar />

        <div className='text-align center'>
          <h1> {t('updateClientAdmin.header')}</h1>
        </div>
        <br></br>

        <div>
        {/* Conditionally render the form */}
        {selectedClientDetails && (
          <form className="update-client-form">
            
            <div className="form-group">
              <label>{t('updateClientAdmin.firstName')}</label>
              <input
                type="text"
                name="firstName"
                placeholder={selectedClientDetails.firstName}
                value={updateFormData.firstName}
                onChange={handleUpdateFormChange}
              />
            </div>
            <div className="form-group">
              <label>{t('updateClientAdmin.lastName')}</label>
              <input
                type="text"
                name="lastName"
                value={updateFormData.lastName}
                onChange={handleUpdateFormChange}
              />
            </div>
            <div className="form-group">
              <label>{t('updateClientAdmin.email')}</label>
              <input
                type="text"
                name="email"
                value={updateFormData.email}
                onChange={handleUpdateFormChange}
              />
            </div>
            <div className="form-group">
              <label>{t('updateClientAdmin.phone')}</label>
              <input
                type="text"
                name="phoneNumber"
                value={updateFormData.phoneNumber}
                onChange={handleUpdateFormChange}
              />
            </div>
            <div className="form-group">
              <label>{t('updateClientAdmin.address')}</label>
              <input
                type="text"
                name="address"
                value={updateFormData.address}
                onChange={handleUpdateFormChange}
              />
            </div>
            <button className='button' type="button" onClick={handleUpdateButtonClick}>
            {t('updateClientAdmin.updateButton')}
            </button>
          </form>
        )}
        </div>
        <br></br>
      </div>
    </div>
  );
}

export default UpdateClientAdmin;
