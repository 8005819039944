// Intro.jsx
import React from 'react';
import '../styles/HomeIntro.css'; // Make sure to create a corresponding CSS file for styling

import { useTranslation } from 'react-i18next';

const HomeIntro = () => {
  const { t } = useTranslation();
  
  return (
    <section className="intro">
      <div className="intro-content">
        <h1>{t('homeIntro.title')}</h1>
        <p>{t('homeIntro.subtitle1')}</p>
        <p>{t('homeIntro.subtitle2')}</p>
        {/* <button className="learn-more-btn">{t('homeIntro.signupButton')}</button> */}
        <p>{t('homeIntro.signupButton')}</p> {/* remove this line if implement button above */}
      </div>
    </section>
  );
};

export default HomeIntro;
