import { Link } from 'react-router-dom';
import React from 'react';

import styles from "../styles/LoggedInNavbar.module.css"
import GlobalStyles from '../styles/GlobalStyles';
import CTALogo from "../images/ctaLogo.png";

import {useAuth} from "../security/Components/AuthProvider";
import { useTranslation } from 'react-i18next';

const lngs = {
  en: { nativeName: 'EN' },
  fr: { nativeName: 'FR' }
};


function EmployeeNavbar() {
  const auth = useAuth()
  const { t, i18n } = useTranslation();

  const handleLanguageChange = (event) => {
    i18n.changeLanguage(event.target.value);
  };

  return (
    <>
    <GlobalStyles />


   <nav className={`${styles.nav} ${styles.navScrolled}`}>

    <img src={CTALogo} alt="CTA Logo" className={`${styles.CTALogo} ${styles.logoScrolled}`} />

     <ul className={styles.ul}>
       <li className={`${styles.li} ${styles.liScrolled}`}><Link to='/' className={styles.li}>{t('employeeNav.home')}</Link></li>
       {
        // @ts-ignore
        auth.userRoles().includes("Employee") &&
        <li className={`${styles.li} ${styles.liScrolled}`}>
          <Link to='/employee/appointments' className={styles.li}>{t('employeeNav.appointments')}</Link>
        </li>

        }

        {
        // @ts-ignore
        auth.userRoles().includes("Employee") &&
        <li className={`${styles.li} ${styles.liScrolled}`}>
          <Link to='/employee/quoteRequests' className={styles.li}>{t('employeeNav.quotes')}</Link>
        </li>
        }

        
        <li className={`${styles.li} ${styles.liScrolled}`}>
          <Link to='/employee/profile' className={styles.li}>{t('employeeNav.profile')}</Link>
        </li>

        <li className={`${styles.li} ${styles.liScrolled}`}>
          <select onChange={handleLanguageChange} value={i18n.resolvedLanguage} className={styles.languageDropdown}>
            {Object.keys(lngs).map((lng) => (
              <option key={lng} value={lng}>
                {lngs[lng].nativeName}
              </option>
            ))}
          </select>
        </li>

        {
          // @ts-ignore
          !auth.isAuthenticated &&
          <li><Link to="https://api.coupetonarbre.live/oauth2/authorization/okta" className={styles.li}>{t('employeeNav.login')}</Link></li>

        }
        
        {
          // @ts-ignore
          auth.isAuthenticated &&
        <li>
          <form method={"post"} action={"https://api.coupetonarbre.live/cta/logout"}>
            <button className='logout-btn' type={"submit"}>{t('employeeNav.logout')}</button>
          </form>
        </li>
        }

     </ul>
   </nav>
   </>
  );
 }

export default EmployeeNavbar;