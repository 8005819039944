import React, { useState, useEffect } from 'react';
import Helmet from 'react-helmet';
import axios from 'axios';
import EmployeeNavbar from '../components/EmployeeNavbar'
import moment from 'moment'; 
import { useTranslation } from 'react-i18next';

import { useAuth } from "../security/Components/AuthProvider";

function AppointmentsEmployee() {
  const [appointmentList, setAppointmentList] = useState([]);
  const [appointmentId, setAppointmentId] = useState(null)
  const [appointmentDetails, setAppointmentDetails] = useState(null)
  const [currentPage, setCurrentPage] = useState(1); 
  const [appointmentPerPage] = useState(5); 
  const auth = useAuth()
  const { t, i18n } = useTranslation();

const getAllAppointments = async () => {
    axios.get("https://api.coupetonarbre.live/appointments/employee", {
        headers: {
          'X-XSRF-TOKEN': auth.getXsrfToken()
        }
    })
    .then((response) => {
      setAppointmentList(response.data)
    })
    .catch(error => {
      console.log('Error getting appointments', error);
    })
}

useEffect(() => {
    getAllAppointments();
}, [])

  const getServiceTranslation = (service) => {
    return t(`appointmentsEmployee.table.serviceEnum.${service}`);
  };

  const getStatusTranslation = (status) => {
    return t(`appointmentsEmployee.table.statusEnum.${status}`);
  };

  const formatDate = (dateString) => {
    const lang = i18n.language;

    const date = new Date(dateString);

    if (lang === 'fr') {
      const day = date.getDate();
      const month = date.getMonth() + 1;
      const year = date.getFullYear();

      return `${day < 10 ? '0' + day : day}/${month < 10 ? '0' + month : month}/${year}`;
    } else {
      const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
      return date.toLocaleDateString(undefined, options);
    }
  };

  const formatTime = (timeString) => {
    const lang = i18n.language;

    const timeRegex = /(\d{1,2}):(\d{2})\s*(AM|PM)?/i;

    const match = timeString.match(timeRegex);
  
    if (!match) return timeString;
  
    let hour = parseInt(match[1], 10);
    const minute = match[2];
  
    if (lang === 'fr') {
      if (match[3] && match[3].toUpperCase() === 'PM') {
        hour = (hour % 12) + 12;
      } else if (match[3] && match[3].toUpperCase() === 'AM' && hour === 12) {
        hour = 0;
      }

      return `${hour < 10 ? '0' + hour : hour}:${minute}`;
    } else {
      const period = match[3] ? match[3].toUpperCase() : '';
      return `${hour % 12 || 12}:${minute} ${period}`;
    }
  };

  const getStatusStyle = (status) => {
    switch (status.toLowerCase()) {
      case 'completed':
        return { backgroundColor: 'green' };
      case 'postponed':
        return { backgroundColor: 'yellow' };
      case 'cancelled':
        return { backgroundColor: 'lightcoral' };
      case 'upcoming':
        return { backgroundColor: '#eef0f2' };
      case 'within_24_hours':
        return { backgroundColor: '#d2e3eb' };
      case 'within_one_week':
        return { backgroundColor: '#b1c8d2' };
      default:
        return {};
    }
  };
  const handlePaginationClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const renderPaginationButtons = () => {
    const totalPages = Math.ceil(appointmentList.length / appointmentPerPage);

    const paginationButtons = [];
    for (let i = 1; i <= totalPages; i++) {
      paginationButtons.push(
        <button
          key={i}
          className={`page-link ${currentPage === i ? 'active' : ''}`}
          onClick={() => handlePaginationClick(i)}
        >
          {i}
        </button>
      );
    }
    return paginationButtons;
  };

  // Logic for pagination
  const indexOfLastappointment = currentPage * appointmentPerPage;
  const indexOfFirstappointment = indexOfLastappointment - appointmentPerPage;
  const currentAppointments = appointmentList.slice(indexOfFirstappointment, indexOfLastappointment);
  return (
    <div className='content'>
        <Helmet>
            <title>{t('appointmentsEmployee.pageTitle')}</title>
        </Helmet>
        <div id='nav-container'>
            <EmployeeNavbar/>
        </div>
        <div className='header-container'>
            <h1>{t('appointmentsEmployee.header')}</h1>
        </div>

        <div>
        <table>
          <thead>
              <tr>
                <th>{t('appointmentsEmployee.table.firstName')}</th>
                <th>{t('appointmentsEmployee.table.lastName')}</th>
                <th>{t('appointmentsEmployee.table.time')}</th>
                <th>{t('appointmentsEmployee.table.date')}</th>
                <th>{t('appointmentsEmployee.table.price')}</th>
                <th>{t('appointmentsEmployee.table.service')}</th>
                <th>{t('appointmentsEmployee.table.status')}</th>
              </tr>
            </thead>
            <tbody>
            {currentAppointments.map((appointment) => (
                <React.Fragment key={appointment.appointmentId}>
                <tr>
                  <td>{appointment.clientFirstName}</td>
                  <td>{appointment.clientLastName}</td>
                  <td>{formatTime(appointment.time)}</td>
                  <td>{formatDate(appointment.date)}</td>
                  <td>${appointment.price}</td>
                  <td>{getServiceTranslation(appointment.service)}</td>
                  <td style={getStatusStyle(appointment.status)}>{getStatusTranslation(appointment.status)}</td>
                </tr>
                </React.Fragment>
              ))}
            </tbody>
        </table>
        <div>
          <div className="pagination">
            {renderPaginationButtons()}
          </div>
        </div>
      </div>
      <br/>
      <br/>
      <br/>
    </div>
  )
}
export default AppointmentsEmployee