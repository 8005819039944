import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import axios from 'axios';
import AdminNavbar from '../components/AdminNavbar';
import '../styles/Pagination.css'
import '../styles/FeedbackAdmin.css';
import { useAuth } from '../security/Components/AuthProvider';
import { useTranslation } from 'react-i18next';

import Select from 'react-select';
import '@fortawesome/fontawesome-free/css/all.css';
import { faStar, faStarHalfAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function FeedbackAdmin() {
  const [feedbackList, setFeedbackList] = useState([]);
  const [feedbackId, setFeedbackId] = useState(null);
  const [feedbackDetails, setFeedbackDetails] = useState(null);
  const [showUpdateFeedbackForm, setShowUpdateFeedbackForm] = useState(false);
  const [updatingFeedback, setUpdatingFeedback] = useState(null);
  const [selectedStarRating, setSelectedStarRating] = useState(null); 
  const [currentPage, setCurrentPage] = useState(1); 
  const [feedbackPerPage] = useState(5); 
  const auth = useAuth();
  const { t, i18n } = useTranslation();

  const getAllFeedbacks = async () => {
    axios.get("https://api.coupetonarbre.live/feedbacks/admin", {
      headers: {
        'X-XSRF-TOKEN': auth.getXsrfToken()
      }
    })
    .then((response) => {
      setFeedbackList(response.data)
    })
    .catch(error => {
      console.log('Error getting feedbacks', error);
    })
  }

  useEffect(() => {
    getAllFeedbacks();
  }, [])

  useEffect(() => {
    if (feedbackId !== null) {
      axios.get(`https://api.coupetonarbre.live/feedbacks/${feedbackId}`, {
        headers: {
          'X-XSRF-TOKEN': auth.getXsrfToken()
        }
      })
      .then(response => {
        setFeedbackDetails(response.data);
      })
      .catch(error => {
        console.error('Error fetching feedback details:', error);
      });
    }
  }, [feedbackId]);

  const formatDate = (dateString) => {
    const lang = i18n.language;

    const date = new Date(dateString);
  
    if (lang === 'fr') {
      const day = date.getDate();
      const month = date.getMonth() + 1;
      const year = date.getFullYear();

      return `${day < 10 ? '0' + day : day}/${month < 10 ? '0' + month : month}/${year}`;
    } else {
      const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
      return date.toLocaleDateString(undefined, options);
    }
  };

  const handleFeedbackClick = (id) => {
    if (feedbackId === id) {
      handleCloseDetails();
    } else {
      setFeedbackId(id);
      axios.get(`https://api.coupetonarbre.live/feedbacks/${id}`, {
        headers: {
          'X-XSRF-TOKEN': auth.getXsrfToken()
        }
      })
      .then(response => {
        setFeedbackDetails(response.data);
      })
      .catch(error => {
        console.error('Error fetching feedback details:', error);
      });
    }
  };

  const handleCloseDetails = () => {
    setFeedbackId(null);
    setFeedbackDetails(null);
  };

const renderRatingsColumn = (feedback) => (
  <td>
    <div>
      <span>{feedback.ratings}</span>&nbsp;
      <span className="star-filled">★</span>
    </div>
  </td>
);
const handleStarRatingFilter = (selectedOption) => {
  setSelectedStarRating(selectedOption);
};

const filterFeedbackByRating = (feedback) => {
  if (!selectedStarRating || selectedStarRating.value === "") {
    return true; // If no rating selected or "All" selected, show all feedbacks
  }
  const feedbackRating = parseFloat(feedback.ratings);
  const selectedRating = parseFloat(selectedStarRating.value);

  return feedbackRating === selectedRating;
};

const formatStarRatingOption = (rating) => {
  if (!rating) return "";
  const wholeStars = Math.floor(rating);
  const remainder = rating - wholeStars;
  const stars = [];

  // Add whole stars
  for (let i = 0; i < wholeStars; i++) {
    stars.push(<FontAwesomeIcon key={`whole-star-${i}`} icon={faStar} className="whole-star" />);
  }

  // Add half-star icon if needed
  if (remainder >= 0.25 && remainder < 0.75) {
    stars.push(<FontAwesomeIcon key="half-star" icon={faStarHalfAlt} className="half-star" />);
  }

  return stars;
};

const starRatingOptions = [
  { value: "", label: "All" },
  { value: "1", label: <>{formatStarRatingOption(1)}</> },
  { value: "1.5", label: <>{formatStarRatingOption(1.5)}</> },
  { value: "2", label: <>{formatStarRatingOption(2)}</> },
  { value: "2.5", label: <>{formatStarRatingOption(2.5)}</> },
  { value: "3", label: <>{formatStarRatingOption(3)}</> },
  { value: "3.5", label: <>{formatStarRatingOption(3.5)}</> },
  { value: "4", label: <>{formatStarRatingOption(4)}</> },
  { value: "4.5", label: <>{formatStarRatingOption(4.5)}</> },
  { value: "5", label: <>{formatStarRatingOption(5)}</> },
];

  const handleFeedbackEditClick = (event, feedback) => {
    event.stopPropagation();
    setUpdatingFeedback(feedback);
    setShowUpdateFeedbackForm(true);
  };

  const handleUpdateFeedback = () => {
    axios.put(`https://api.coupetonarbre.live/feedbacks/${feedbackId}/admin`, updatingFeedback)
      .then(response => {
        const updatedFeedbacks = feedbackList.map(fb =>
          fb.feedbackId === updatingFeedback.feedbackId ? response.data : fb
        );
        setFeedbackList(updatedFeedbacks);
        setUpdatingFeedback(null);
        setShowUpdateFeedbackForm(false);
      })
      .catch(error => {
        console.error('Error updating feedback:', error);
      });
  };

  const handleFeedbackFormChange = (event) => {
    setUpdatingFeedback({ ...updatingFeedback, [event.target.name]: event.target.value });
  };

  const formatService = (service) => {
    if (typeof service !== 'string') {
      return "";
    }
    return service
      .replace(/([A-Z])/g, ' $1')
      .replace(/And/g, ' & ')
      .trim();
  };

  const SERVICE_OPTIONS = [
    "HedgeTrimming",
    "TreeTrimming",
    "TreeBranchRemoval",
    "SmallTreeRemoval",
    "HedgeTrimmingAndTreeTrimming",
    "HedgeTrimmingAndTreeBranchRemoval",
    "TreeTrimmingAndTreeBranchRemoval",
    "HedgeTrimmingAndTreeTrimmingAndTreeBranchRemoval",
    "HedgeTrimmingAndSmallTreeRemoval",
    "TreeTrimmingAndSmallTreeRemoval",
    "HedgeTrimmingAndTreeTrimmingAndSmallTreeRemoval",
    "HedgeTrimmingAndTreeBranchRemovalAndSmallTreeRemoval",
    "TreeTrimmingAndTreeBranchRemovalAndSmallTreeRemoval",
    "HedgeTrimmingAndTreeTrimmingAndTreeBranchRemovalAndSmallTreeRemoval"
  ];

  const handleFeedbackDeleteClick = (event, feedbackId) => {
    event.stopPropagation(); // Prevents the onClick event of the parent row from firing

    // Confirm with the user
    const isConfirmed = window.confirm(t('feedbackAdmin.deleteConfirmation'));
    if (!isConfirmed) {
      return;
    }

    axios.delete(`https://api.coupetonarbre.live/feedbacks/${feedbackId}`, {
      headers: {
        'X-XSRF-TOKEN': auth.getXsrfToken()
      }
    })
    .then(() => {
      // Filter out the deleted feedback from the list
      const updatedFeedbackList = feedbackList.filter(fb => fb.feedbackId !== feedbackId);
      setFeedbackList(updatedFeedbackList);
      const totalPages = Math.ceil(updatedFeedbackList.length / feedbackPerPage);
      const newCurrentPage = Math.min(currentPage, totalPages);
      setCurrentPage(newCurrentPage);
    })
    .catch(error => {
      console.error('Error deleting feedback:', error);
    });
  };

  const getServiceTranslation = (service) => {
    return t(`feedbackAdmin.serviceEnum.${service}`);
  };
  
  const handlePaginationClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const renderPaginationButtons = () => {
    const totalPages = Math.ceil(feedbackList.length / feedbackPerPage);

    const paginationButtons = [];
    for (let i = 1; i <= totalPages; i++) {
      paginationButtons.push(
        <button
          key={i}
          className={`page-link ${currentPage === i ? 'active' : ''}`}
          onClick={() => handlePaginationClick(i)}
        >
          {i}
        </button>
      );
    }
    return paginationButtons;
  };

  // Logic for pagination
  const indexOfLastFeedback = currentPage * feedbackPerPage;
  const indexOfFirstFeedback = indexOfLastFeedback - feedbackPerPage;
  const currentFeedbacks = feedbackList.filter(filterFeedbackByRating).slice(indexOfFirstFeedback, indexOfLastFeedback);
  
  return (
    <div className='content'>
      <Helmet>
        <title>{t('feedbackAdmin.pageTitle')}</title>
      </Helmet>
      <div id='nav-container'>
        <AdminNavbar />
      </div>
      <div className='header-container'>
        <h1>{t('feedbackAdmin.header')}</h1>
        <div>
          <label>{t('feedbackAdmin.search')}</label>
          <Select
            options={starRatingOptions}
            onChange={handleStarRatingFilter}
            value={selectedStarRating}
          />
        </div>
      </div>
      <div>
        <table>
          <thead>
            <tr>
              <th>{t('feedbackAdmin.table.title')}</th>
              <th>{t('feedbackAdmin.table.firstName')}</th>
              <th>{t('feedbackAdmin.table.lastName')}</th>
              <th>{t('feedbackAdmin.table.date')}</th>
              <th>{t('feedbackAdmin.table.ratings')}</th>
              <th>{t('feedbackAdmin.table.service')}</th>
              <th>{t('feedbackAdmin.table.actions')}</th>
            </tr>
          </thead>
          <tbody>
            {currentFeedbacks.map((feedback) => (
              <React.Fragment key={feedback.feedbackId}>
                <tr onClick={() => handleFeedbackClick(feedback.feedbackId)}>
                  <td>{feedback.title}</td>
                  <td>{feedback.clientFirstName}</td>
                  <td>{feedback.clientLastName}</td>
                  <td>{formatDate(feedback.date)}</td>
                  {renderRatingsColumn(feedback)}
                  <td>{getServiceTranslation(feedback.service)}</td>
                  <td>
                    <button className='buttons edit-button' onClick={(event) => handleFeedbackEditClick(event, feedback)}>{t('feedbackAdmin.table.editButton')}</button>
                  </td>
                </tr>
                {feedbackId === feedback.feedbackId && feedbackDetails && (
                  <tr>
                    <td colSpan="7">
                      <div className="additional-details">
                        <p><strong>{t('feedbackAdmin.table.details.feedbackID')}</strong> {feedbackDetails.feedbackId}</p>
                        <p><strong>{t('feedbackAdmin.table.details.appointmentID')}</strong> {feedbackDetails.appointmentId}</p>
                        <p><strong>{t('feedbackAdmin.table.details.clientID')}</strong> {feedbackDetails.clientId}</p>
                        <p><strong>{t('feedbackAdmin.table.details.clientName')}</strong> {feedbackDetails.clientFirstName} {feedbackDetails.clientLastName}</p>
                        <p><strong>{t('feedbackAdmin.table.details.rating')}</strong>{renderRatingsColumn(feedback)}</p>
                        <p><strong>{t('feedbackAdmin.table.details.date')}</strong> {formatDate(feedbackDetails.date)}</p>
                        <p><strong>{t('feedbackAdmin.table.details.title')}</strong> {feedbackDetails.title}</p>
                        <p><strong>{t('feedbackAdmin.table.details.comments')}</strong> {feedbackDetails.comments}</p>
                        <p><strong>{t('feedbackAdmin.table.details.service')}</strong> {getServiceTranslation(feedbackDetails.service)}</p>
                        <button className="buttons close-button" onClick={handleCloseDetails}>{t('feedbackAdmin.table.details.closeButton')}</button> 
                        &nbsp;
                        <button className='buttons delete-button' onClick={(event) => handleFeedbackDeleteClick(event, feedback.feedbackId)}>{t('feedbackAdmin.table.details.deleteButton')}</button>
                      </div>
                    </td>
                  </tr>
                )}
              </React.Fragment>
            ))}
          </tbody>
        </table>
        <div>
          <div className="pagination">
            {renderPaginationButtons()}
          </div>
        </div>
      </div>

      {showUpdateFeedbackForm && updatingFeedback && (
        <div className="popup-background">
          <div className="popup-container-feedback">
            <h1>{t('feedbackAdmin.updateForm.header')}</h1>
            <br/>
            <h3>{t('feedbackAdmin.updateForm.title')}</h3>
            <input
              name="title"
              value={updatingFeedback.title}
              onChange={handleFeedbackFormChange}
              placeholder={t('feedbackAdmin.updateForm.title')}
            />
            <br/><br/>
            <h3>{t('feedbackAdmin.updateForm.comments')}</h3>
            <textarea
              name="comments"
              value={updatingFeedback.comments}
              onChange={handleFeedbackFormChange}
              placeholder={t('feedbackAdmin.updateForm.comments')}
            />
            <br/>
            <h3>{t('feedbackAdmin.updateForm.service')}</h3>
            <select
            className='service-select'
              name="service"
              value={updatingFeedback.service}
              onChange={handleFeedbackFormChange}
            >
              {SERVICE_OPTIONS.map((serviceOption) => (
                <option key={serviceOption} value={serviceOption}>
                  {getServiceTranslation(serviceOption)}
                </option>
              ))}
            </select>
            <br/>
            <button onClick={handleUpdateFeedback}>{t('feedbackAdmin.updateForm.submitButton')}</button>
            &nbsp;
            <button onClick={() => setShowUpdateFeedbackForm(false)}>{t('feedbackAdmin.updateForm.cancelButton')}</button>
          </div>
        </div>
      )}
    </div>
  );
}

export default FeedbackAdmin;