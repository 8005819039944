import React, { useState, useEffect } from 'react';
import Helmet from 'react-helmet';
import axios from 'axios';
import AdminNavbar from '../components/AdminNavbar';
import ClientNavbar from '../components/ClientNavbar';
import MainNavbar from '../components/MainNavbar';
import EmployeeNavbar from '../components/EmployeeNavbar';
import {useAuth} from "../security/Components/AuthProvider";
import '../styles/HomePage.css'
import { useTranslation } from 'react-i18next';

import HomeIntro from '../components/HomeIntro';
import HomeAbout from '../components/HomeAbout';
import HomeServices from '../components/HomeServices';


function HomePage() {
  const { t } = useTranslation();

  const auth = useAuth();
  const [hasProfile, setHasProfile] = useState(false);

  const [hasProfileEmployee, setHasProfileEmployee] = useState(false);


  //Check if user is logged in and has profile
  useEffect(() => {
    // @ts-ignore
    if (auth.isAuthenticated &&   auth.userRoles().includes("Client")) {
      checkIfProfileExists().then((r) => {
        console.log(r);
      });
    }

    if (auth.isAuthenticated &&   auth.userRoles().includes("Employee")) {
      checkIfEmployeeProfileExists().then((r) => {
        console.log(r);
      });
    }

    
  }, []);

  const checkIfProfileExists = async () => {
    //check if user exists
    await axios
      .get('https://api.coupetonarbre.live/users/client?simpleCheck=true', {
        headers: {
          // @ts-ignore
          'X-XSRF-TOKEN': auth.getXsrfToken(),
        },
      })
      .then((r) => {
        console.log(r.status);
        if (r.status === 200) {
          setHasProfile(true);
          console.log('Does he have a profile? ' + hasProfile);
          //check if user has profile
          axios
            .get('https://api.coupetonarbre.live/users/client', {
              headers: {
                // @ts-ignore
                'X-XSRF-TOKEN': auth.getXsrfToken(),
              },
            })
            .then((r) => {
              if (r.status === 200) {
                setHasProfile(true);
              }
            })
            .catch((e) => {
              console.log(e);
            });
        } else {
          //redirect to create profile page
          setHasProfile(false);
          window.location.href = 'http://www.coupetonarbre.live/profile';

        }
      })
      .catch((e) => {
        //redirect to create profile page
        console.log(e);
        setHasProfile(false);
        window.location.href = 'http://www.coupetonarbre.live/profile';

        
      });
  };


  const checkIfEmployeeProfileExists = async () => {
    //check if user exists
    await axios
      .get('https://api.coupetonarbre.live/users/employee?simpleCheck=true', {
        headers: {
          // @ts-ignore
          'X-XSRF-TOKEN': auth.getXsrfToken(),
        },
      })
      .then((r) => {
        console.log(r.status);
        if (r.status === 200) {
          setHasProfile(true);
          console.log('Does he have a profile? ' + hasProfileEmployee);
          //check if user has profile
          axios
            .get('https://api.coupetonarbre.live/users/employee', {
              headers: {
                // @ts-ignore
                'X-XSRF-TOKEN': auth.getXsrfToken(),
              },
            })
            .then((r) => {
              if (r.status === 200) {
                setHasProfileEmployee(true);
              }
            })
            .catch((e) => {
              console.log(e);
            });
        } else {
          //redirect to create profile page
          setHasProfileEmployee(false);
          window.location.href = 'http://www.coupetonarbre.live/employee/profile';

        }
      })
      .catch((e) => {
        //redirect to create profile page
        console.log(e);
        setHasProfileEmployee(false);
        window.location.href = 'http://www.coupetonarbre.live/employee/profile';

        
      });
  };


  return (
    <div>
      <Helmet>
        <title>{t('homeIntro.title')}</title>
      </Helmet>

      {
        !auth.isAuthenticated &&
        <div id='nav-container'>
          <MainNavbar />
        </div>
      }

      {
        // @ts-ignore
        auth.userRoles().includes("Admin") &&
        <div id='nav-container'>
          <AdminNavbar />
        </div>
      }

      {
        // @ts-ignore
        auth.userRoles().includes("Client") &&
        <div id='nav-container'>
          <ClientNavbar />
        </div>
      }

{
        // @ts-ignore
        auth.userRoles().includes("Employee") &&
        <div id='nav-container'>
          <EmployeeNavbar />
        </div>
      }
      
      <HomeIntro />
      <HomeAbout />
      <HomeServices />

    </div>
  );
}

export default HomePage;
