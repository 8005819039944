import { Helmet } from 'react-helmet';
import EmployeeNavbar from '../components/EmployeeNavbar';
import axios from 'axios';
import { useAuth } from '../security/Components/AuthProvider';
import Cookies from 'js-cookie';
import React, { useEffect, useState } from 'react';
import swal from 'sweetalert';
import '../styles/Profile.css'; 
import { useTranslation } from 'react-i18next';

function EmployeeProfile() {
  const { t } = useTranslation();
  const auth = useAuth();
  const [hasProfile, setHasProfile] = useState(false);

  useEffect(() => {
    // @ts-ignore
    if (!auth.isAuthenticated) {
      window.location.href = 'http://www.coupetonarbre.live/';
    }

    checkIfProfileExists().then((r) => {
      console.log(r);
    });
  }, []);

  const checkIfProfileExists = async () => {
    await axios
      .get('https://api.coupetonarbre.live/users/employee?simpleCheck=true', {
        headers: {
          // @ts-ignore
          'X-XSRF-TOKEN': auth.getXsrfToken(),
        },
      })
      .then((r) => {
        console.log(r.status);
        if (r.status === 200) {
          setHasProfile(true);
          console.log('Does he have a profile? ' + hasProfile);
          axios
            .get('https://api.coupetonarbre.live/users/employee', {
              headers: {
                // @ts-ignore
                'X-XSRF-TOKEN': auth.getXsrfToken(),
              },
            })
            .then((r) => {
              if (r.status === 200) {
                console.log(r.data);
                document.getElementById('firstNameText').textContent = r.data.firstName;
                document.getElementById('lastNameText').textContent = r.data.lastName;
              }
            })
            .catch((e) => {
              console.log(e);
            });
        } else {
          setHasProfile(false);
          
        }
      })
      .catch((e) => {
        console.log(e);
        setHasProfile(false);
        
      });
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    const data = new FormData(event.currentTarget);
    const body = {
      firstName: data.get('firstName'),
      lastName: data.get('lastName'),
    };

    axios
      .post('https://api.coupetonarbre.live/users/employee', body, {
        headers: {
          // @ts-ignore
          'X-XSRF-TOKEN': auth.getXsrfToken(),
        },
      })
      .then((r) => {
        if (r.status === 201) {
          console.log(r.data);
          Cookies.set('email', r.data.email);
          swal(t('employeeProfile.profileSuccessMessage'), '', 'success');
          setHasProfile(true);
          setTimeout(() => {
            window.location.href = 'http://www.coupetonarbre.live/employee/profile';
          }, 2000);
        }
      })
      .catch((e) => {
        let errors = '';

        if (e.response.data.errors !== undefined) {
          // @ts-ignore
          e.response.data.errors.forEach((error) => {
            errors += error.defaultMessage + '\n';
          });
        }

        swal(t('employeeProfile.profileErrorMessage.title'), t('employeeProfile.profileErrorMessage.body') + errors, 'error').then((r) => {
          // @ts-ignore
          auth.authError(e.response.status);
        });
      });
  };



  const renderFormFields = () => {
    if (hasProfile) {
      return (
        <>
          <div>
            <h1> {t('employeeProfile.header')} </h1>
          </div>
          <div className="profile-client-form">
            <div className="row w-75 m-auto">
              <label>
              {t('employeeProfile.firstName')} <span id="firstNameText" className="info-text"></span>
              </label>
            </div>
            <div className="row w-75 m-auto">
              <label>
              {t('employeeProfile.lastName')} <span id="lastNameText" className="info-text"></span>
              </label>
            </div>
          </div>
        </>
      );
    } else {
      return (
        <>
          <div className='title-info'>
            <h1>{t('employeeProfile.profileCreation.header')}</h1>
            <p>{t('employeeProfile.profileCreation.followUp')}</p>
          </div>
          <br></br>
          <div className="profile-create-form">
            <div className="row w-75 m-auto">
              <label>
              {t('employeeProfile.profileCreation.firstName')}
                <input
                  onKeyDown={(e) => {
                    e.key === 'Enter' && e.preventDefault();
                  }}
                  type="text"
                  name="firstName"
                  className="form-control mb-2 input-field"
                  placeholder={t('employeeProfile.profileCreation.firstNamePlace')}
                  id="firstName"
                />
              </label>
            </div>
            <div className="row w-75 m-auto">
              <label>
              {t('employeeProfile.profileCreation.lastName')}
                <input
                  onKeyDown={(e) => {
                    e.key === 'Enter' && e.preventDefault();
                  }}
                  type="text"
                  name="lastName"
                  className="form-control mb-2 input-field"
                  placeholder={t('employeeProfile.profileCreation.lastNamePlace')}
                  id="lastName"
                />
              </label>
            </div>
            <div className="row w-25 m-auto" style={{ margin: 'auto', width: '50%', marginTop: '20px', marginBottom: '120px' , marginLeft: '190px'}}>
              <button className="save-btn" type="submit">
              {t('employeeProfile.profileCreation.submit')}
              </button>
            </div>
          </div>
        </>
      );
    }
  };

  return (
    <div className='content'>
      <Helmet>
        <title>{t('employeeProfile.pageTitle')}</title>
      </Helmet>
      <div id="nav-container">
        <EmployeeNavbar />
        <div>
          <form onSubmit={handleSubmit}>
            {renderFormFields()}
          </form>
          <br></br>        
          <br></br>
          <br></br>        
          <br></br>
          <br></br>        

        </div>
      </div>
    </div>
  );
}

export default EmployeeProfile;
