import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import axios from 'axios';
import AdminNavbar from '../components/AdminNavbar';
import '../styles/AppointmentsAdmin.css';
import { useTranslation } from 'react-i18next';
import {useAuth} from '../security/Components/AuthProvider';

function InvoicesAdmin() {
    const [invoicesList, setInvoicesList] = useState([])
    const [currentPage, setCurrentPage] = useState(1);
    const [invoicesPerPage] = useState(5);
    const auth = useAuth()
    const [selectedInvoiceId, setSelectedInvoiceId] = useState(null);
    const [invoiceDetails, setInvoiceDetails] = useState(null);
    const { t, i18n } = useTranslation();
    const getAllInvoices = async () => {
        axios.get("https://api.coupetonarbre.live/invoices/admin", {
            headers: {
                // @ts-ignore
                'X-XSRF-TOKEN': auth.getXsrfToken()
            }
        })
        .then(r => {
            setInvoicesList(r.data)
        })
        .catch(e => {
            console.log('Error getting invoices', e)
        })
    }

    useEffect(() => {
        getAllInvoices();
    },[]);

    useEffect(() => {
        if (selectedInvoiceId !== null) {
            axios.get(`https://api.coupetonarbre.live/invoices/${selectedInvoiceId}`, {
                headers: {
                    'X-XSRF-TOKEN': auth.getXsrfToken()
                }
            })
            .then(response => {
                setInvoiceDetails(response.data);
            })
            .catch(error => {
                console.error('Error fetching invoice details:', error);
            });
        }
    }, [selectedInvoiceId, auth]);

    const handleInvoiceClick = (invoiceId) => {
        if (selectedInvoiceId === invoiceId) {
            setSelectedInvoiceId(null);
            setInvoiceDetails(null);
        } else {
            setSelectedInvoiceId(invoiceId);
        }
    };

    const handleCloseDetails = () => {
        setSelectedInvoiceId(null);
        setInvoiceDetails(null);
    };

    const handleViewInvoice = (invoiceId) => {
        axios.get(`https://api.coupetonarbre.live/invoices/${invoiceId}/pdf`, {
            headers: {
                'X-XSRF-TOKEN': auth.getXsrfToken(),
                'Accept': 'application/pdf'
            },
            responseType: 'blob'
        })
        .then(response => {
            const file = new Blob(
              [response.data],
              { type: 'application/pdf' }
            );
            const fileURL = URL.createObjectURL(file);
            window.open(fileURL);
        })
        .catch(error => {
            console.error('Error fetching PDF:', error);
        });
    };


    const formatDate = (dateString) => {
        const lang = i18n.language;

        const date = new Date(dateString);

        if (lang === 'fr') {
          const day = date.getDate();
          const month = date.getMonth() + 1;
          const year = date.getFullYear();

          return `${day < 10 ? '0' + day : day}/${month < 10 ? '0' + month : month}/${year}`;
        } else {
          const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
          return date.toLocaleDateString(undefined, options);
        }
      };

    const getServiceTranslation = (service) => {
        return t(`invoicesAdmin.serviceEnum.${service}`);
      };

    const handleDelete = (invoiceId) => {
        if (typeof invoiceId === 'undefined') {
          console.error('Invoice ID is undefined. Cannot delete.');
          return;
        }
      
        const isConfirmed = window.confirm(t('invoicesAdmin.deleteConfirmation'));
      
        if (!isConfirmed) {
          return;
        }
      
        console.log('Deleting invoice with id:', invoiceId);
      
        axios.delete(`https://api.coupetonarbre.live/invoices/${invoiceId}/admin`, {
          headers: {
            'X-XSRF-TOKEN': auth.getXsrfToken()
          }
        })
        .then(response => {
          const updatedInvoicesList = invoicesList.filter(invoices => invoices.invoiceId !== invoiceId);
          // Calculate the new current page after deletion
          setInvoicesList(updatedInvoicesList);
          const totalPages = Math.ceil(updatedInvoicesList.length / invoicesPerPage);
          const newCurrentPage = Math.min(currentPage, totalPages);
          setCurrentPage(newCurrentPage);
        })
        .catch(error => {
          console.error('Error:', error);
        });
    };

      const handlePaginationClick = (pageNumber) => {
        setCurrentPage(pageNumber);
      };

      const renderPaginationButtons = () => {
        const totalPages = Math.ceil(invoicesList.length / invoicesPerPage);

        const paginationButtons = [];
        for (let i = 1; i <= totalPages; i++) {
          paginationButtons.push(
            <button
              key={i}
              className={`page-link ${currentPage === i ? 'active' : ''}`}
              onClick={() => handlePaginationClick(i)}
            >
              {i}
            </button>
          );
        }
        return paginationButtons;
      };

      // Logic for pagination
      const indexOfLastInvoice = currentPage * invoicesPerPage;
      const indexOfFirstInvoice = indexOfLastInvoice - invoicesPerPage;
      const currentInvoices = invoicesList.slice(indexOfFirstInvoice, indexOfLastInvoice);

    const formatService = (service) => {
    return service.replace(/([a-z])([A-Z])/g, '$1 $2');
    };

    return (
        <div className='content'>
            <Helmet>
                <title>{t('invoicesAdmin.pageTitle')}</title>
            </Helmet>
            <div id='nav-container'>
                <AdminNavbar />
            </div>
            <div className='header-container'>
                <h1>{t('invoicesAdmin.header')}</h1>
            </div>
            <div>
                <table>
                <thead>
                    <tr>
                        <th>{t('invoicesAdmin.table.firstName')}</th>
                        <th>{t('invoicesAdmin.table.lastName')}</th>
                        <th>{t('invoicesAdmin.table.time')}</th>
                        <th>{t('invoicesAdmin.table.date')}</th>
                        <th>{t('invoicesAdmin.table.total')}</th>
                        <th>{t('invoicesAdmin.table.service')}</th>
                    </tr>
                    </thead>
                    <tbody>
                    {currentInvoices.map((invoice) => (
                        <React.Fragment key={invoice.invoiceId}>
                        <tr onClick={() => handleInvoiceClick(invoice.invoiceId)}>
                            <td>{invoice.clientFirstName}</td>
                            <td>{invoice.clientLastName}</td>
                            <td>{invoice.time}</td>
                            <td>{formatDate(invoice.date)}</td>
                            <td>${invoice.total}</td>
                            <td>{getServiceTranslation(invoice.service)}</td>
                            <td>                    
                                <button className='buttons delete-button' onClick={() => handleDelete(invoice.invoiceId)}>{t('invoicesAdmin.table.deleteButton')}</button>
                            </td>
                        </tr>
                        {selectedInvoiceId === invoice.invoiceId && invoiceDetails && (
                                <tr>
                                    <td colSpan="7">
                                        <div className="additional-details">
                                            <p><strong>{t('invoicesAdmin.detailsTable.invoiceId')}</strong> {invoiceDetails.invoiceId}</p>
                                            <p><strong>{t('invoicesAdmin.detailsTable.appointmentId')}:</strong> {invoiceDetails.appointmentId}</p>
                                            <p><strong>{t('invoicesAdmin.detailsTable.clientId')}:</strong> {invoiceDetails.clientId}</p>
                                            <p><strong>{t('invoicesAdmin.detailsTable.clientName')}:</strong> {invoiceDetails.clientFirstName} {invoiceDetails.clientLastName}</p>
                                            <p><strong>{t('invoicesAdmin.detailsTable.time')}:</strong> {invoiceDetails.time}</p>
                                            <p><strong>{t('invoicesAdmin.detailsTable.date')}:</strong> {formatDate(invoiceDetails.date)}</p>
                                            <p><strong>{t('invoicesAdmin.detailsTable.subTotal')}:</strong> ${invoiceDetails.subTotal}</p>
                                            <p><strong>{t('invoicesAdmin.detailsTable.total')}:</strong> ${invoiceDetails.total}</p>
                                            <p><strong>{t('invoicesAdmin.detailsTable.description')}:</strong> {invoiceDetails.description}</p>
                                            <p><strong>{t('invoicesAdmin.detailsTable.address')}:</strong> {invoiceDetails.address}</p>
                                            <p><strong>{t('invoicesAdmin.detailsTable.service')}:</strong> {(getServiceTranslation(invoiceDetails.service))}</p>
                                            <button className='buttons view-button' onClick={() => handleViewInvoice(invoice.invoiceId)}>
                                                {t('invoicesAdmin.viewButton')}
                                            </button>
                                            &nbsp;
                                            <button className="buttons close-button" onClick={handleCloseDetails}>{t('invoicesAdmin.closeButton')}</button>

                                        </div>
                                    </td>
                                </tr>
                            )}
                        </React.Fragment>
                    ))}
                    </tbody>
                </table>
                <div>
          <div className="pagination">
            {renderPaginationButtons()}
          </div>
        </div>
            </div>
        </div>
    )
}

export default InvoicesAdmin;